import React, { useState, useEffect } from "react";
import {
  NumberInput,
  NumberInputField,
  Button,
  Text,
  Tooltip,
  Box,
  BoxProps,
  useStyleConfig,
  Input,
  useMediaQuery,
  // Divider,
} from "@chakra-ui/react";
import { Field } from "react-final-form";
import Icon from "../Icon";
// import CurrencyConverter from "../CurrencyConverter";
import { getNum } from "../../Utils/number";
import { useMyTransactionState } from "../../Components/Transaction";
import { CoinMode } from "../../Types";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { ConnectButton } from "../ConnectButton";
import { useWeb3Context } from "../../Hooks/useWeb3Context";
import { AddIcon, MinusIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";
import CurrencyConverter from "../CurrencyConverter";

type AdjustInputProps = {
  mode?: "deposit" | "withdraw";
  token: string;
  values?: { [key: string]: any };
  showToken?: boolean;
  psm?: boolean;
  showConnect?: boolean;
  showCancel?: boolean;
  showIcons?: boolean;
  disabled?: boolean;
  name: string;
  defaultValue?: number;
  max?: number | string;
  min?: number;
  step?: number;
  precision?: number;
  size?: string | undefined;
  variant?: string;
  fillContainer?: boolean;
  inputWidth?: number;
  iconStatus?: any;
  setIconStatus?: any;
  isYUSDDebt?: any;
  id?: any;
  borrowMode?: "normal" | "lever" | "unlever";
  noCurrencyConvert?: boolean;
  transactionId?: string;
  isDeprecated?: boolean;
  form?: any;
  walletBalance?: number;
  setFarm?: any;
  troveBalance?: number;
  removeX?: boolean;
} & BoxProps;

const selector = ({ trove }: LiquityStoreState) => ({
  trove,
});

/**
 * Completely stateless AdjustInput component to use with React Final Form.
 */
const AdjustInput: React.FC<AdjustInputProps> = ({
  mode,
  token,
  disabled,
  name,
  precision = 2,
  defaultValue,
  max,
  min = 0,
  step,
  size = "md",
  variant,
  fillContainer,
  showToken = false,
  psm = false,
  showCancel = false,
  showConnect = false,
  showIcons = false,
  inputWidth = "auto",
  iconStatus,
  setIconStatus,
  isYUSDDebt = false,
  values,
  id,
  borrowMode = "normal",
  noCurrencyConvert = false,
  transactionId,
  isDeprecated,
  form,
  walletBalance,
  setFarm = undefined,
  troveBalance,
  removeX,
  ...props
}) => {
  const { connected } = useWeb3Context();
  const { trove } = useLiquitySelector(selector);
  const [currencyType, setCurrencyType] = useState<string>("USD");
  const styleProps = useStyleConfig("AdjustInput") as Record<
    string,
    Record<string, string>
  >;
  const {
    input: inputStyle,
    inputGroup,
    decoratorContainer,
    adjustIcons,
  } = styleProps;
  const [currMode, setCurrMode] = useState(mode ? mode : "");

  const [active, setActive] = useState("false");

  const { userCountry, isBlockedCountry } = useProtocolDataContext();

  const toggle = (mode: "deposit" | "withdraw") => {
    const temp: CoinMode = {};
    setCurrMode(mode);
    temp[token] = mode;
    setIconStatus({ ...iconStatus, ...temp });
  };

  const getFormattedValue = (value: string) => {
    if (/^[0-9.]*$/.test(value)) {
      if (max != undefined && value != "") {
        const decimalInput: Decimal = Decimal.from(value);
        const decimalMax: Decimal = Decimal.from(max);
        if (decimalInput.gte(decimalMax)) {
          return decimalMax.toString();
        }
      }

      return value;
    }
    return "";
  };

  const maxCheck = (value: string, input: any) => {
    if (/^[0-9.]*$/.test(value)) {
      if (max != undefined && value != "") {
        try {
          const decimalInput: Decimal = Decimal.from(value);
          const decimalMax: Decimal = Decimal.from(max);
          if (decimalInput.gte(decimalMax)) {
            input.onChange(decimalMax.toString()!);
            return decimalMax.toString();
          }
        } catch (e) {
          return "0";
        }
      }

      return value;
    }
    return "";
  };

  // const handleCollateralChange = (rawInput:number, maxVal:number) => {
  //   return const originalTotalPrice = calculatorState.adjustedCollaterals.reduce(
  //     (underlyingPrices, collateralItem) => underlyingPrices + collateralItem.underlyingPrices,
  //     0
  //   );
  // }

  const getNumberValue = (value: any) => {
    const val = getFormattedValue(value);
    return val.length === 0 ? 0 : parseFloat(val);
  };

  const myTransactionState = useMyTransactionState(
    transactionId ? transactionId : ""
  );

  useEffect(() => {
    if (values !== undefined) {
      values[name + "mode"] = currMode;
    }
  }, [currMode]);

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Box
        {...props}
        py={!isMobile ? "" : "8px"}
        // borderTop={!isMobile ? "" : "1px"}
        // borderColor="border"
        display="flex"
        w="full"
      >
        {showConnect && !connected && (
          <Box display="flex" gap="8px" w="full" justifyContent="flex-end">
            {/* <Tooltip
       openDelay={700}
       hasArrow
       label={
         isYUSDDebt
           ? "Borrow YUSD"
           : isDeprecated
           ? "This collateral has been deprecated. You can only withdraw."
           : borrowMode === "unlever"
           ? "You cannot deposit during unlever mode"
           : undefined
       }
       placement="top"
       bg="surface-highlight"
     >
     </Tooltip> */}
            <Button
              variant="surface"
              leftIcon={<AddIcon boxSize="12px" />}
              disabled={walletBalance === 0}
              // fontSize="15px"
              // fontWeight="bold"
              // display="flex"
              // px="0px"
              // w={!isMobile ? "" : "full"}
              w="full"
              // w={!isMobile ? "" : "full"}
              onClick={
                borrowMode !== "unlever" && !isDeprecated
                  ? () => {
                      handleToggle();
                      toggle("deposit");
                    }
                  : () => undefined
              }
            >
              {isYUSDDebt ? "Borrow YUSD" : "Deposit"}
            </Button>
            {/* <Box>
       <Divider color="border" orientation="vertical" />
     </Box> */}
            <Tooltip
              label={
                isYUSDDebt
                  ? "Pay Back YUSD"
                  : borrowMode === "lever"
                  ? "You cannot withdraw during lever mode"
                  : undefined
              }
              placement="top"
              bg="surface"
            >
              <Button
                variant="surface"
                // w={!isMobile ? "" : "full"}
                w="full"
                leftIcon={<MinusIcon boxSize="12px" />}
                disabled={troveBalance === 0}
                // disabled={trove.status !== "open" && true}
                onClick={
                  borrowMode !== "lever" && trove.status === "open"
                    ? () => {
                        handleToggle();
                        toggle("withdraw");
                      }
                    : () => undefined
                }
              >
                {isYUSDDebt ? "Borrow YUSD" : "Withdraw"}
              </Button>
            </Tooltip>
            <Field
              name={name + "mode"}
              initialValue={iconStatus[token]}
              render={({ input }) => (
                <Input {...input} value={iconStatus[token]} display="none" />
              )}
            />
          </Box>
        )}
        {connected
          ? showIcons &&
            !show && (
              <Box display="flex" gap="8px" w="full" justifyContent="flex-end">
                {/* <Tooltip
            openDelay={700}
            hasArrow
            label={
              isYUSDDebt
                ? "Borrow YUSD"
                : isDeprecated
                ? "This collateral has been deprecated. You can only withdraw."
                : borrowMode === "unlever"
                ? "You cannot deposit during unlever mode"
                : undefined
            }
            placement="top"
            bg="surface-highlight"
          >
          </Tooltip> */}
                <Button
                  variant="surface"
                  // leftIcon={<AddIcon boxSize="12px" />}

                  disabled={
                    (name !== "YUSD" &&
                      (walletBalance === 0 ||
                        (isBlockedCountry && trove.status !== "open"))) ||
                    name === "WBTC" ||
                    name === "qiBTC" ||
                    (isBlockedCountry && name === "YUSD")
                  }
                  // fontSize="15px"
                  // fontWeight="bold"
                  // display="flex"
                  // px="0px"
                  // w={!isMobile ? "" : "full"}
                  w="full"
                  // w={!isMobile ? "" : "full"}
                  onClick={
                    borrowMode !== "unlever" && !isDeprecated
                      ? () => {
                          handleToggle();
                          toggle("deposit");
                        }
                      : () => undefined
                  }
                >
                  {isYUSDDebt
                    ? "Borrow YUSD"
                    : isMobile
                    ? "Deposit"
                    : "Deposit"}
                </Button>
                {/* <Box>
            <Divider color="border" orientation="vertical" />
          </Box> */}
                <Tooltip
                  label={
                    isYUSDDebt
                      ? "Pay Back YUSD"
                      : borrowMode === "lever"
                      ? "You cannot withdraw during lever mode"
                      : undefined
                  }
                  placement="top"
                  bg="surface"
                >
                  <Button
                    variant="surface"
                    // w={!isMobile ? "" : "full"}
                    w="full"
                    // leftIcon={<MinusIcon boxSize="12px" />}
                    disabled={troveBalance === 0}
                    // disabled={trove.status !== "open" && true}
                    onClick={
                      borrowMode !== "lever" && trove.status === "open"
                        ? () => {
                            handleToggle();
                            toggle("withdraw");
                          }
                        : () => undefined
                    }
                  >
                    {isYUSDDebt
                      ? "Repay YUSD"
                      : isMobile
                      ? "Withdraw"
                      : "Withdraw"}
                  </Button>
                </Tooltip>
                <Field
                  name={name + "mode"}
                  initialValue={iconStatus[token]}
                  render={({ input }) => (
                    <Input
                      {...input}
                      value={iconStatus[token]}
                      display="none"
                    />
                  )}
                />
              </Box>
            )
          : showIcons && !show && !showConnect && <ConnectButton />}
        {(show || !showIcons) && (
          <Box display="flex" w="full">
            <Box display="flex" alignItems="center" border="none" w="full">
              <Box {...inputGroup} w="full" border="none">
                <Box display="flex" flexDir="column" w="full">
                  <Field
                    name={name}
                    initialValue={
                      defaultValue ? String(defaultValue) : undefined
                    }
                    render={({ input }) => (
                      <>
                        {/* {isYUSDDebt && currMode === "withdraw" ? (
                        <Text textStyle="subheading" color="text-primary">Amount to repay:</Text>
                      ) : (
                        <Text textStyle="subheading" color="text-primary">Amount to borrow:</Text>
                      )} */}
                        {isYUSDDebt && currMode === "deposit" && (
                          <Box
                            display="flex"
                            w="full"
                            justifyContent="space-between"
                          >
                            <Text textStyle="subheading">Borrow YUSD</Text>
                            <Text
                              display="flex"
                              textStyle="text_xs"
                              color="text-secondary"
                              fontWeight="500"
                              noOfLines={1}
                              cursor="pointer"
                              _hover={{ color: "primary-default" }}
                              onClick={() => {
                                input.onChange(max!);
                              }}
                            >
                              {`Max: ${troveBalance}`}
                            </Text>
                          </Box>
                        )}
                        {isYUSDDebt && currMode === "withdraw" && (
                          <Box
                            display="flex"
                            w="full"
                            justifyContent="space-between"
                          >
                            <Text textStyle="subheading">Repay YUSD</Text>
                            <Text
                              display="flex"
                              textStyle="text_xs"
                              color="text-secondary"
                              fontWeight="500"
                              noOfLines={1}
                              cursor="pointer"
                              _hover={{ color: "primary-default" }}
                              onClick={() => {
                                input.onChange(max!);
                              }}
                            >
                              {`Wallet: ${walletBalance}`}
                            </Text>
                          </Box>
                        )}

                        {walletBalance !== undefined && !isYUSDDebt ? (
                          <Box
                            {...decoratorContainer}
                            display="flex"
                            w="full"
                            justifyContent={psm ? "flex-end" : "space-between"}
                          >
                            {psm ? (
                              <></>
                            ) : (
                              <Text
                                display="flex"
                                textStyle="text_xs"
                                fontWeight="500"
                                noOfLines={1}
                                cursor="pointer"
                                color={
                                  currMode === "deposit"
                                    ? "green.400"
                                    : currMode === "withdraw"
                                    ? "red.400"
                                    : "white"
                                }
                              >
                                {troveBalance !== undefined &&
                                currMode === "withdraw"
                                  ? "Withdraw"
                                  : "Deposit"}
                              </Text>
                            )}

                            <Text
                              display="flex"
                              textStyle="text_xs"
                              color="text-secondary"
                              fontWeight="500"
                              noOfLines={1}
                              cursor="pointer"
                              _hover={{ color: "primary-default" }}
                              onClick={() => {
                                input.onChange(max!);
                              }}
                            >
                              {
                                // `Balance: ${
                                troveBalance !== undefined &&
                                currMode === "withdraw"
                                  ? `Max: ${getNum(troveBalance)} `
                                  : `Max: ${getNum(walletBalance)} `
                                // }`
                              }
                            </Text>
                          </Box>
                        ) : null}
                        <Box
                          bg=""
                          // _hover={{ bg: "surface-highlight" }}
                          display="flex"
                          // border="1px"
                          // borderColor="border"
                          rounded="8px"
                          alignItems="center"
                        >
                          <Box display="flex" flexDir="column" w="full">
                            <Box
                              display="flex"
                              alignItems="center"
                              // px="8px"
                              w="full"
                              justifyContent="space-between"
                            >
                              {myTransactionState.type === "confirmed"
                                ? input.onChange(undefined)
                                : null}
                              <NumberInput
                                {...input}
                                {...inputStyle}
                                // minW={inputWidth}
                                errorBorderColor="transparent"
                                // color={
                                //   currMode === "deposit"
                                //     ? "#26B571"
                                //     : currMode === "withdraw"
                                //     ? "#DC444D"
                                //     : "white"
                                // }
                                color="text-primary"
                                min={min}
                                // px="8px"
                                keepWithinRange={true}
                                clampValueOnBlur={true}
                                value={
                                  values === undefined
                                    ? maxCheck(input.value, input)
                                    : values[name] == undefined
                                    ? defaultValue !== undefined
                                      ? defaultValue
                                      : maxCheck(input.value, input)
                                    : maxCheck(values[name], input)
                                }
                                defaultValue={defaultValue}
                                inputMode="numeric"
                                // isDisabled={disabled ? true : false}
                                // isReadOnly={disabled ? true : false}
                              >
                                <Box display="flex" alignItems="center">
                                  <NumberInputField
                                    placeholder="0"
                                    _placeholder={{ color: "text-secondary" }}
                                    border="none"
                                    color="text-primary"
                                    letterSpacing="tight"
                                    // h="56px"
                                    h={psm ? "32px" : "56px"}
                                    pl="0px"
                                    fontSize="24px"
                                    autoFocus={true}
                                    mx="0px"
                                    px="0px"
                                    ml="0px"
                                    _focus={{ border: "none" }}
                                  />

                                  {showCancel && (
                                    <Box
                                      cursor="pointer"
                                      display="flex"
                                      onClick={() => {
                                        form.change(name, undefined);
                                        handleToggle();
                                        setFarm !== undefined && setFarm(false);
                                      }}
                                    >
                                      <SmallCloseIcon
                                        h="16px"
                                        w="16px"
                                        color="text-secondary"
                                        _hover={{ color: "red.400" }}
                                      />
                                      {/* <Text
                                fontSize="12px"
                                color="red.400"
                                fontWeight="500"
                                onClick={() => {
                                  form.change(name, undefined);
                                  handleToggle();
                                  setFarm !== undefined && setFarm(false);
                                }}
                              >
                                Cancel
                              </Text> */}
                                    </Box>
                                  )}
                                </Box>

                                <Box justifyContent="space-between">
                                  {psm ? (
                                    <CurrencyConverter
                                      value={
                                        values == undefined
                                          ? getNumberValue(input.value)
                                          : values[name] == undefined
                                          ? defaultValue != undefined
                                            ? defaultValue
                                            : getNumberValue(input.value)
                                          : values[name]
                                      }
                                      token={token}
                                      currency={currencyType}
                                      fontWeight="500"
                                      fontSize="13px"
                                      // w={16}
                                      // onClick={() => {
                                      //   token !== "YUSD" &&
                                      //   currencyType === "USD"
                                      //     ? setCurrencyType("RAV")
                                      //     : setCurrencyType("USD");
                                      // }}
                                      style={{
                                        cursor: "pointer",
                                        color: "text-secondary",
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </NumberInput>

                              {/* TOKEN / ASSET CONTAINER */}

                              {showToken && (
                                <Box display="flex" alignItems="center">
                                  <Icon iconName={token} h="24px" w="24px" />
                                  <Text
                                    ml="4px"
                                    fontSize="14px"
                                    fontWeight="500"
                                  >
                                    {token}
                                  </Text>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {/* {isBlockedCountry && (
        <Box>
          <Text>
            You are located in {userCountry}, which is blocked by YETI Finance.
          </Text>
        </Box>
      )} */}
    </>
  );
};

export default AdjustInput;
