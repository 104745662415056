export const paths = [
  { path: "/", label: "Dashboard" },
  { path: "/farm", label: "Farm" },
  { path: "/veYeti", label: "Stake" },
  // { path: "/stats", label: "Stats" },
  { path: "/info", label: "Info" },
  // { path: "/calculator", label: "Calculator" },
  // { path: "/strategies", label: "Strategies" },
  // { path: "/redemption", label: "Redeem" },
];
