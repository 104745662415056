import React, { useEffect, useRef, useState } from "react";
import {
  //   Center,
  Flex,
  Text,
  Box,
  useDisclosure,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  LineController,
} from "chart.js";
import { getNum } from "../../../../Utils/number";
import { Chart } from "react-chartjs-2";
import { Loader } from "../../../../Components";
import { useLiquity } from "../../../../Hooks/LiquityContext";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client/core";

// export type TokenGraphProps = {
//   token: string;
// }

type CollateralChartProps = {
  usdValue: number;
  currVcValue: number;
};

const CollateralChart: React.FC<CollateralChartProps> = ({
  usdValue,
  currVcValue,
  ...props
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin,
    Filler
  );

  const {
    isOpen: isFeeGraphOpen,
    onOpen: onFeeGraphOpen,
    onClose: onFeeGraphClose,
  } = useDisclosure();

  const {
    isOpen: isAPYGraphOpen,
    onOpen: onAPYGraphOpen,
    onClose: onAPYGraphClose,
  } = useDisclosure();

  const onFeeGraphClick = async () => {
    onFeeGraphOpen();
  };

  const onAPYGraphClick = async () => {
    onAPYGraphOpen();
  };

  const [portfolio, setPortfolio] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [dataRange, setDataRange] = useState<string>("1W");

  const [gridSetting, setGridSetting] = useState<any>({
    unit: "hour",
    displayFormats: { hour: "MMM DD hA" },
    stepSize: 12,
  });

  const [balance, setBalance] = useState<number>(0);

  const { account } = useLiquity();

  const getData = async (dataType: string) => {
    const totalValues: any[] = [];
    const dates = [];
    const valueQuery = `
          query {
            troveStatuses(where: {borrower: "${account}"}) {
              totalValues
              timestamps
            }
          }
          `;
    const client = new ApolloClient({
      uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti_troves",
      cache: new InMemoryCache(),
    });

    // console.log("query", valueQuery);

    const result = await client
      .query({
        query: gql(valueQuery),
      })
      .then((data: any) => data)
      .catch((err: any) => {
        console.log("Error fetching history data: ", err);
        return [];
      });

    if (result.length == 0) {
      console.log("no result");
      return [];
    }

    const data = result.data.troveStatuses;

    if (data.length == 0) {
      const emptyData = {
        labels: [],
      };
      setPortfolio(emptyData);
      setLoading(false);
      return;
    }

    const timestamps = data[0]["timestamps"];
    const values = data[0]["totalValues"];
    // setBalance(format(Number(values[values.length - 1])));

    let length = 0;
    const now = new Date().getTime() / 1000;
    let startTime = 0;
    switch (dataRange) {
      case "1D":
        length = 60 * 60;
        startTime = now - 60 * 60 * 24;
        setGridSetting({ unit: "hour", stepSize: 2 });
        break;
      case "1W":
        length = 60 * 60 * 3;
        startTime = now - 60 * 60 * 24 * 7;
        setGridSetting({
          unit: "hour",
          displayFormats: { hour: "MMM DD hA" },
          stepSize: 12,
        });
        break;
      case "1M":
        length = 60 * 60 * 24;
        startTime = now - 60 * 60 * 24 * 30;
        setGridSetting({});
        break;
      case "1Y":
        length = 60 * 60 * 24 * 7;
        startTime = now - 60 * 60 * 24 * 365;
        setGridSetting({
          unit: "day",
          displayFormats: { hour: "MMM DD" },
          stepSize: 7,
        });
        break;
    }

    // console.log("data", timestamps, values);

    let prev = 0;
    for (let i = 0; i < timestamps.length; i++) {
      const cur = timestamps[i];
      if (cur > startTime && cur - prev > length) {
        dates.push(new Date(cur * 1000));
        totalValues.push(values[i]);
        prev = cur;
      }
    }
    dates.push(new Date(timestamps[timestamps.length - 1] * 1000));
    totalValues.push(values[values.length - 1]);

    const apyData = {
      labels: dates,
      datasets: [
        // {
        //     label: 'min',
        //     data: minValues,
        //     color: 'red'
        // },
        // {
        //     label: 'max',
        //     data: maxValues,
        //     color: 'green'
        // },
        {
          data: totalValues,
          fill: "origin",
          pointRadius: 0,
          borderWidth: 3,
          borderColor: "#5F81F9",
          backgroundColor: "rgba(95,129,249,0.05)",
          // backgroundColor: "rgba(95,129,249,0.0)",
          tension: 0.2,
        },
        // {
        //   label: 'ICR',
        //   data: icrs,
        // }
      ],
    };
    // const port = {
    //   ...apyData,
    //   datasets: apyData.datasets.map((dataset: any) => ({
    //     ...dataset,
    //   })),
    // };

    setPortfolio(apyData);

    setLoading(false);
  };

  const bal2 = usdValue;

  const useMouse = () => {
    sethoverNum(bal2);
  };

  // const hoverHandler = () => {
  //   setHovered(true);
  // };

  function switchHoverNumber() {
    return (event: React.MouseEvent) => {
      // console.log("hover out detected");
      // sethoverNum(bal2);
      setHovered(false);
    };
  }

  const [hovered, setHovered] = useState(false);
  const [hoverNumb, sethoverNum] = useState<number | undefined>();

  const balanceHovered = Number(hoverNumb);

  // useEffect(() => {
  //   sethoverNum(undefined);
  // });

  // useEffect(() => {
  //   if (hoverNumb === undefined ) {
  //     sethoverNum("32");
  //   }
  // });

  // console.log('modal data', data)

  useEffect(() => {
    getData(dataRange);
  }, [dataRange]);

  // const customTooltip = {
  //   id: "customTooltip",
  //   afterDraw(
  //     chart: {
  //       tooltip: any;
  //       ctx: any;
  //     },
  //     args: any,
  //     options: any
  //   ) {
  //     if (chart.tooltip._active[0]) {
  //       const { ctx } = chart;

  //       ctx.save();
  //       // console.log(chart.tooltip._active[0]);
  //       const activePoint = chart.tooltip._active[0];
  //       const datapoint = activePoint.index;
  //       const datasetIndex = activePoint.datasetIndex;
  //       // console.log(datasetIndex);

  //       // console.log(chart.getDatasetMeta(datasetIndex));

  //       ctx.beginPath();
  //       (ctx.lineWidth = 5), (ctx.strokeStyle = "white");
  //       ctx.moveTo(100, 0);
  //       ctx.lineTo(100, 100);
  //       ctx.stroke();
  //       const a = 12.56;
  //       // console.log(a);
  //     }
  //   },
  // };

  const footer = () => {
    return "Hi";
  };

  const options: any = {
    grid: {
      show: false,
    },
    responsive: true,
    // scaleShowVerticalLines: true,
    // showVerticalLines: true,
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      xAxes: {
        display: true,
        autoSkip: false,
        ticks: {
          color: "#8A93A6",
          display: true,
        },
        type: "time",
        title: {
          display: false,
          color: "",
          text: "",
        },

        time: gridSetting,
        gridLines: {
          display: false,
          drawBorder: false,
        },

        grid: {
          display: false,
          z: -1,
          color: "#393C48",
        },
      },
      yAxes: {
        grid: {
          display: true,
          drawBorder: false,
          z: -1,
          color: "#393C48",
        },

        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          color: "#8A93A6",
          display: true,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    // pointStyle: "line",
    plugins: {
      // customTooltip,
      tooltip: {
        // enabled: false,
        // callbacks: {
        //   label: function (tooltipItem: any, data: any) {
        //     return "$" + getNum(format(Number(tooltipItem["raw"])), 2);
        //   },
        // },
        titleFont: {
          size: 15,
        },
        callbacks: {
          // footer: function () {
          //   return balanceHovered;
          //   // return null;
          // },
          // title: function () {
          //   return balanceHovered;
          // },
          title: (context: any) => {
            const date = context[0].label;
            // console.log(context[0].parsed.x);
            const options: any = {
              day: "numeric",
              month: "long",
              hour: "numeric",
              minute: "numeric",
            };
            const strings = date.toLocaleString("en-US", options).split(" ");
            // console.log("parsed", strings);
            // return context[0].label;
            return (
              strings[1] +
              " " +
              strings[2] +
              " " +
              strings[3] +
              " " +
              strings[4].slice(0, 5)
            );
            //format date
          },
          label: function () {
            return null;
          },
          afterFooter: function (chart: any) {
            // console.log(chart[0].dataIndex);
            // console.log(chart[0].datasetIndex);
            // console.log(chart[0]);
            sethoverNum(chart[0].parsed.y);
          },
        },
        backgroundColor: "#141724",
        padding: "8",
        bodyAlign: "center",
        titleAlign: "center",
        yAlign: "bottom",
      },
      legend: {
        display: false,
        labels: {
          color: "white",
        },
      },
    },
    onHover: function (ctx: any) {
      // console.log(ctx.chart._active[0]);
      if (ctx.chart._active[0] === undefined) {
        // console.log("hovered in detected");
        setHovered(true);
      }
    },
    // zoom: {
    //   zoom: {
    //     wheel: {
    //       enabled: true,
    //     },
    //     pinch: {
    //       enabled: true,
    //     },
    //     mode: 'xy',
    //     overScaleMode: 'y'
    //   },
    //   pan: {
    //     enabled: true,
    //     mode: 'xy',
    //   }
    // }
  };

  const chartRef = useRef<ChartJS>(null);

  function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          //   borderWidth="1px"
          fontSize="12.5px"
          fontWeight="700"
          letterSpacing="tight"
          // rounded="6px"
          color="text-secondary"
          _checked={{
            // bg: "surface-highlight",
            // py: "4px",
            // borderWidth: "1px",
            // borderColor: "border",
            color: "white",
            // px: "8px",
          }}
          _focus={{}}
          // _hover={{
          //   bg: "surface-hover",
          // }}
          px="6px"
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  const graphOptions = ["1D", "1W", "1M", "1Y"];

  const handleRadiochange = (value: any) => {
    setDataRange(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "1W",
    onChange: handleRadiochange,
  });

  const group = getRootProps();

  return (
    <>
      <Box bg="#272937" p="24px" rounded="12px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {/* {`$${getNum(currVcValue, 2)}`} */}
          <Box>
            <Text color="#8A93A6" fontSize="15px" fontWeight="600" mb="8px">
              Collateral balance
            </Text>
            {hovered ? (
              <>
                <Text color="#DFE3E9" textStyle="display_sm" fontWeight="700">
                  ${getNum(balanceHovered, 2)}
                </Text>
              </>
            ) : (
              <Text color="#DFE3E9" textStyle="display_sm" fontWeight="700">
                ${getNum(usdValue, 2)}
              </Text>
            )}
          </Box>
          {/* <Text>{dataRange}</Text> */}
          <Box display="flex" alignItems="center" mt="8px" {...group} gap="2px">
            {graphOptions.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard key={value} {...radio}>
                  {value}
                </RadioCard>
              );
            })}
          </Box>
        </Box>
        {loading ? (
          <Flex flexDirection="column" alignItems="center">
            <Loader />
            <Text color="#8A93A6" textStyle="title4">
              {" "}
              Loading
            </Text>
          </Flex>
        ) : portfolio.labels.length == 0 ? (
          <>
            <Box
              pt="16px"
              h="180px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              color="#8A93A6"
            >
              <Text color="#8A93A6" mt={5} mb={5}>
                Nothing deposited yet.
              </Text>
              Deposit assets below to start borrowing.
            </Box>
          </>
        ) : (
          <Box pt="16px" h="250px" w="full" onMouseOut={switchHoverNumber()}>
            <Chart
              ref={chartRef}
              type="line"
              data={portfolio}
              options={options}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CollateralChart;
