import React from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  VEStakeCard,
  VEStakeSummary,
  VEEmissions,
} from "../../PageComponents/VEStake";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format } from "../../Utils/number";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";

// import { calculateHealth, calculateHealthColor, calculateHealthStableTrove } from "../../PageComponents/Borrow/Trove";

export type StakeProps = {
  disconnected?: boolean;
};

const selector = ({ veYETIStaked }: LiquityStoreState) => ({
  veYETIStaked,
});

const Stake: React.FC<StakeProps> = ({ disconnected = false }) => {
  const { isBlockedCountry, userCountry } = useProtocolDataContext();
  const { veYETIStaked } = useLiquitySelector(selector);
  const yetiEarned: number = format(veYETIStaked.yetiEarned);
  const totalStaked: number = format(veYETIStaked.totalUserYeti);
  return (
    <>
      <Box
        w="full"
        h="343px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />

      <Box>
        <Text color="#DFE3E9" textStyle="display_md">
          Stake
        </Text>
        {/* <Text color="#8A93A6">
          Starting October 27th, staking will be unavailable to citizens of the
          United States.
          <a
            href="https://blog.yetifinance.co/new-yeti-features-and-roadmap-update-b242a28e9dcb"
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ color: "#5F81F9", marginLeft: "6px" }}>
              Read more here
            </span>
          </a>
        </Text> */}
      </Box>
      {/* <Text
          color="white"
          textStyle="display_sm"
          // borderBottom="1px"
        >
          Stake
        </Text> */}
      {/* <Text
          textStyle="text_md"
          color="text-secondary"
          textAlign={["center", "left"]}
          fontWeight="normal"
          pb="32px"
        >
          Stake YETI to earn veYETI. Apply veYETI to activate utility.
        </Text> */}
      <Box
        display={{ lg: "flex" }}
        justifyContent="center"
        gap="16px"
        mt="48px"
      >
        <VEStakeCard disconnected={disconnected} />{" "}
        {(yetiEarned !== 0 || totalStaked !== 0) && (
          <>
            <Box display="flex" w="full" gap="16px" flexDir="column">
              <VEEmissions />
              <VEStakeSummary />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Stake;
