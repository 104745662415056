import { Box, Hide, Text } from "@chakra-ui/react";
import { RedeemCard, TroveList } from "../../PageComponents/Redemption";
import React from "react";

export type RedemptionProps = {
  disconnected?: boolean;
};

const Redemption: React.FC<RedemptionProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        h="327px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text color="#DFE3E9" textStyle="display_sm">
        Redeem YUSD
      </Text>
      {/* <Text
            textStyle="text_md"
            color="#8A93A6"
            textAlign={["center", "left"]}
            fontWeight="normal"
            letterSpacing="tight"
            mt="8px"
          >
            YUSD can be swapped for equal value in underlying collateral at any
            time.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://techdocs.yeti.finance/how-does-yeti-finance-work/redemptions-and-yusd-price-stability#what-are-redemptions"
              }
              style={{ outline: "none", color: "#6079FF" }}
            >
              {" "}
              Learn More
            </a>
          </Text> */}
      <Box
        display="flex"
        flexDir={{ base: "column-reverse", md: "row" }}
        gap="24px"
        mt="48px"
      >
        <Hide below="md">
          <TroveList />
        </Hide>
        <RedeemCard />
      </Box>
    </>
  );
};

export default Redemption;
