import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import StatColumn from "../StatColumn";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format, getNum } from "../../../Utils/number";
import { tokenDataMappingA } from "../../../TokenData";

const selector = ({
  yusdInStabilityPool,
  YUSDPrice,
  prices,
  farm,
  total,
  decimals,
  boostedFarm,
  recoveryRatios,
  PSMYUSDContractDebt,
}: LiquityStoreState) => ({
  yusdInStabilityPool,
  YUSDPrice,
  prices,
  farm,
  total,
  decimals,
  boostedFarm,
  recoveryRatios,
  PSMYUSDContractDebt,
});

const SystemSummary: React.FC = () => {
  const {
    yusdInStabilityPool,
    YUSDPrice,
    prices,
    farm,
    total,
    decimals,
    boostedFarm,
    recoveryRatios,
    PSMYUSDContractDebt,
  } = useLiquitySelector(selector);

  let totalSystemVC = 0;
  let totalSystemUSD = 0;
  let totalStableUSD = 0;

  Object.keys(total.collaterals).map((address) => {
    const amountUSD = format(
      total.collaterals[address]
        .mul(10 ** (18 - format(decimals[address])))
        .mul(prices[address])
        .div(1e18)
    );
    totalSystemVC += amountUSD * format(recoveryRatios[address]);
    totalSystemUSD += amountUSD;
    if (
      tokenDataMappingA[address] !== undefined &&
      tokenDataMappingA[address].isStable
    ) {
      totalStableUSD += amountUSD;
    }
  });
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  // TODO
  const LPPrice = format(YUSDPrice);
  const TVL =
    totalSystemUSD +
    format(farm.totalLPStaked.add(boostedFarm.totalLPStaked)) * LPPrice +
    format(yusdInStabilityPool) +
    format(PSMYUSDContractDebt);

  const [CurvePoolData, setCurvePoolData] = useState({
    liquidity: {
      value: 0,
      usd: 0,
    },
  });

  const [PLPPoolData, setPLPPoolData] = useState({
    USDC: {
      Deposits: {
        value: 0,
        usd: 0,
      },
    },
    YUSD: {
      Deposits: {
        value: 0,
        usd: 0,
      },
    },
  });

  useEffect(() => {
    const curvePoolUrl = "https://api.yeti.finance/v1/CurvePool";
    const plpPoolUrl = "https://api.yeti.finance/v1/PLPPool";
    const fetchData = async () => {
      try {
        const curveResponse = await fetch(curvePoolUrl, {
          method: "GET",
          mode: "cors",
        });
        const plpResponse = await fetch(plpPoolUrl, {
          method: "GET",
          mode: "cors",
        });
        setCurvePoolData(await curveResponse.json());
        setPLPPoolData(await plpResponse.json());
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box display="flex" flexDir={{ base: "column", md: "row" }} gap="24px">
      <Box
        display="flex"
        flexDir="column"
        layerStyle="card"
        p="24px"
        gap="16px"
        w="full"
      >
        <StatColumn
          iconName="Bank"
          amount={`$${getNum(TVL, 4)}`}
          color={"green.500"}
          description="Total Value Locked"
          large={true}
        />

        <Box
          display="flex"
          flexDir={{ base: "column", lg: "row" }}
          gap={{ base: "8px", lg: "32px" }}
        >
          <StatColumn
            iconName="MoneyStack"
            amount={`$${getNum(format(PSMYUSDContractDebt), 4)}`}
            description="PSM USDC Minted"
          />

          <StatColumn
            iconName="Bank"
            amount={`$${getNum(CurvePoolData.liquidity.usd, 4)}`}
            link={"https://avax.curve.fi/factory/69"}
            description="Curve YUSD Pool"
          />
          <StatColumn
            iconName="Bank"
            amount={`$${getNum(
              PLPPoolData.USDC.Deposits.usd + PLPPoolData.YUSD.Deposits.usd,
              4
            )}`}
            link={"https://app.platypus.finance/pool?pool_group=alt"}
            description="Platypus YUSD/USDC Pool"
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDir="column"
        layerStyle="card"
        p="24px"
        gap="16px"
        w="full"
      >
        <StatColumn
          iconName="MoneyStack"
          amount={`${(totalSystemRatio * 100).toFixed(3)}%`}
          description="System Collateral Ratio"
          textSize={"14"}
          large={true}
        />
        <Box
          display="flex"
          flexDir={{ base: "column", lg: "row" }}
          gap={{ base: "8px", lg: "32px" }}
        >
          <StatColumn
            iconName="MoneyStack"
            amount={`$${getNum(totalSystemUSD, 4)}`}
            description="Total Collateral"
          />

          <StatColumn
            iconName="MoneyStack"
            amount={`$${getNum(format(yusdInStabilityPool), 4)}`}
            description="YUSD Stability Pool"
          />
          <StatColumn
            iconName="Bank"
            amount={`${getNum((totalStableUSD / totalSystemUSD) * 100, 3)}%`}
            description="System Stablecoin %"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SystemSummary;
