import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Box,
  Button,
  Flex,
  Text,
  Stack,
  Center,
  Divider,
} from "@chakra-ui/react";
import Checkbox from "../Checkbox";
import { useLiquity } from "../../Hooks/LiquityContext";
import { Link } from "react-router-dom";

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  infographSrc?: string;
  mode?: string;
};

const Popup: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  header,
  infographSrc = "",
  mode = "",
}) => {
  const [showInfograph, setShowInfograph] = useState(false);
  const [understandDisclaimer, setUnderstandDisclaimer] = useState(false);
  const [understandDisclaimerError, setUnderstandDisclaimerError] =
    useState(false);
  const { account } = useLiquity();
  const onSubmit = (): void => {
    if (mode == "") {
      if (!understandDisclaimer) {
        setUnderstandDisclaimerError(true);
      } else {
        localStorage.setItem(
          account + "agreedToYetiFinanceDisclaimerMainnet",
          "agreed"
        );
        onClose();
      }
    } else if (mode == "borrow") {
      localStorage.setItem(account + "agreedToYetiBorrowInfograph", "agreed");
      onClose();
    } else if (mode == "veYETI") {
      localStorage.setItem(account + "agreedToYetiveYETIInfograph", "agreed");
      onClose();
    } else if (mode == "farm") {
      localStorage.setItem(account + "agreedToYetiFarmInfograph", "agreed");
      onClose();
    }
  };

  const onSubmit2 = (): void => {
    if (!understandDisclaimer) {
      setUnderstandDisclaimerError(true);
    } else {
      localStorage.setItem(
        account + "agreedToYetiFinanceDisclaimerMainnet",
        "agreed"
      );
      setShowInfograph(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(1px)" />
      <ModalContent bg="surface" color="text-primary" textStyle="text_sm">
        <ModalHeader fontSize="2xl" pb={2}>
          {localStorage.getItem(
            account + "agreedToYetiFinanceDisclaimerMainnet"
          ) == undefined
            ? ""
            : header}
        </ModalHeader>
        <ModalBody>
          <Stack spacing={3}>
            {localStorage.getItem(
              account + "agreedToYetiFinanceDisclaimerMainnet"
            ) == undefined && (
              <>
                <Text fontWeight="bold">Welcome.</Text>
                <Text>
                  Please read our{" "}
                  <Link to="/terms">
                    {" "}
                    <span style={{ color: "#5F81F9", fontWeight: "600" }}>
                      {" "}
                      Terms of Service Agreement{" "}
                    </span>{" "}
                  </Link>
                  which explains the terms and conditions by which you may
                  access and use the website and any services offer through it
                  that may be legally accessible to you. You must read this
                  agreement carefully and agree to it before proceeding.
                </Text>
                <Text>
                  By accessing or using the Interface, you signify that you have
                  read, understand, and agree to be bound by these terms of
                  service in its entirety. If you do not agree, you are not
                  authorized to access or use the website.
                </Text>
                <Text>
                  Before borrowing, staking, or liquidity providing you should
                  fully review our{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://techdocs.yeti.finance/"}
                    style={{ outline: "none", textDecoration: "underline" }}
                  >
                    technical documentation
                  </a>{" "}
                  to understand how the Yeti Finance protocol works.
                </Text>
              </>
            )}
            {mode == "borrow" &&
            localStorage.getItem(
              account + "agreedToYetiFinanceDisclaimerMainnet"
            ) != undefined ? (
              <Text>
                Deposit Collateral like wrapped AVAX, Trader Joe LP Tokens, and
                Benqi qiTokens into your trove. Get out our stablecoin YUSD, all
                while earning yield on your collateral!
              </Text>
            ) : mode == "veYETI" &&
              localStorage.getItem(
                account + "agreedToYetiFinanceDisclaimerMainnet"
              ) != undefined ? (
              <Text>
                veYETI will be used to boost{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://techdocs.yeti.finance/earning-with-yeti-finance/avax-yusd-lp-farming"
                  }
                  style={{ outline: "none", textDecoration: "underline" }}
                >
                  liquidity provider
                </a>{" "}
                rewards, boost{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://techdocs.yeti.finance/how-does-yeti-finance-work/stability-pool-and-liquidations"
                  }
                  style={{ outline: "none", textDecoration: "underline" }}
                >
                  stability pool
                </a>{" "}
                rewards, or reduce fees.
              </Text>
            ) : mode == "farm" &&
              localStorage.getItem(
                account + "agreedToYetiFinanceDisclaimerMainnet"
              ) != undefined ? (
              <Text>
                Stake YUSD in the Stability Pool to get YETI rewards, as well as
                a portion of liquidation rewards!
              </Text>
            ) : (
              <></>
            )}
          </Stack>
          <Center mt={5}>
            {mode != "" &&
              localStorage.getItem(
                account + "agreedToYetiFinanceDisclaimerMainnet"
              ) != undefined && (
                <img
                  src={infographSrc}
                  alt="Yeti Finance"
                  style={{ textAlign: "center" }}
                />
              )}
          </Center>
          {mode == "farm" &&
            localStorage.getItem(
              account + "agreedToYetiFinanceDisclaimerMainnet"
            ) != undefined && (
              <>
                <Divider mb={8} />
                <Stack spacing={3}>
                  <Text mb={5}>
                    Pair YUSD with native USDC and USDT on Curve, and deposit
                    your LP tokens to earn YETI!{" "}
                  </Text>
                </Stack>
                <img
                  src="/img/farm.png"
                  alt="Yeti Finance"
                  style={{ textAlign: "center" }}
                />
              </>
            )}
        </ModalBody>
        <ModalFooter justifyContent={"center"}>
          <Box display="flex" alignItems="center" flexDir="column" gap="8px">
            {localStorage.getItem(
              account + "agreedToYetiFinanceDisclaimerMainnet"
            ) == undefined && (
              <Flex mb={1} mt={2}>
                <Checkbox
                  isChecked={understandDisclaimer}
                  onChange={() =>
                    setUnderstandDisclaimer(!understandDisclaimer)
                  }
                  error={understandDisclaimerError}
                  label="By checking this box, I have reviewed and understand the terms of service - and agreed to the stated terms and conditions. "
                  popup={true}
                />
              </Flex>
            )}
            {mode != "" &&
            localStorage.getItem(
              account + "agreedToYetiFinanceDisclaimerMainnet"
            ) == undefined ? (
              <Button mr={3} onClick={onSubmit2}>
                Proceed
              </Button>
            ) : (
              <Button variant="surface" mr={3} onClick={onSubmit}>
                Proceed
              </Button>
            )}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Popup;
