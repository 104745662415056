import React, { useState, useEffect } from "react";
import { Box, Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { AdjustInput } from "../../../Components";
import Tooltip from "../../../Components/Tooltip";
import ConfirmRedeemModal from "../ConfirmRedeemModal";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { getNum, format } from "../../../Utils/number";
import { validateRedeem } from "../../../Utils/validation";
import { Form } from "react-final-form";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { ConnectButton } from "../../../Components/ConnectButton";

import { useLiquity } from "../../../Hooks/LiquityContext";

export type RedeemCardProps = Record<string, never>;

const selector = ({ yusdBalance }: LiquityStoreState) => ({
  yusdBalance,
});

const RedeemCard: React.FC<RedeemCardProps> = () => {
  const { liquity, account } = useLiquity();

  const { connected } = useWeb3Context();

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const { yusdBalance } = useLiquitySelector(selector);

  const [redeemFee, setRedeemFee] = useState<number>(0);

  const [collsToReceiveUpdated, setCollsToReceiveUpdated] =
    useState<boolean>(false);

  useEffect(() => {
    const getSetRedeemFee = async () => {
      setRedeemFee(format(await liquity.getRedemptionFeeRate()));
    };

    getSetRedeemFee();
  }, []);

  const toast = useToast();

  const validate = (value: number) => {
    validateRedeem(toast, format(yusdBalance), 0, value, onConfirmOpen);
    // validateRedeem(toast, 100000000, 0, value, onConfirmOpen);
  };

  return (
    <>
      <Box layerStyle="card" w={{ base: "full", md: "450px" }} h="264px">
        <Form
          onSubmit={() => undefined}
          render={({ values, form }) => (
            <>
              {isConfirmOpen && (
                <ConfirmRedeemModal
                  isOpen={isConfirmOpen}
                  onClose={onConfirmClose}
                  amount={values.yusdRedeemInput || "0"}
                  values={values}
                  redeemRate={Decimal.from(redeemFee)}
                  updated={collsToReceiveUpdated}
                  setUpdated={setCollsToReceiveUpdated}
                />
              )}
              <Box display="flex" flexDirection="column">
                <Box
                  px="24px"
                  py="16px"
                  display="flex"
                  alignItems="center"
                  gap="6px"
                  borderBottom="1px"
                  borderColor="border"
                >
                  <Text textStyle="card_header" display="flex">
                    Redeem YUSD
                  </Text>
                  <Tooltip display="flex" bg="red.100" color="text-secondary">
                    You will redeem as much YUSD as you can from the riskiest
                    troves first. This ensures YUSD will have a hard peg floor
                    of close to $1.
                  </Tooltip>
                </Box>
                <Box display="flex" px="24px" py="16px" flexDir="column">
                  {/* <Box
                      display="flex"
                      justifyContent="space-between"
                      textStyle="number_base"
                      pb="8px"
                    >
                      <Text color="text-secondary">Wallet Balance</Text>
                      <Text color="text-primary">{format(yusdBalance)}</Text>
                    </Box> */}
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    py="16px"
                    pt="0px"
                  >
                    <Text textStyle="text_xs" color="text-secondary">
                      Redemption Fee:{" "}
                    </Text>
                    <Text textStyle="text_xs" color="text-secondary">
                      {getNum(redeemFee * 100, 3)}%
                    </Text>
                  </Box>
                  <AdjustInput
                    max={format(yusdBalance) * (1 - redeemFee - 0.0005)}
                    name="yusdRedeemInput"
                    token="YUSD"
                    showToken
                    transactionId="redeemYUSD"
                    form={form}
                    walletBalance={format(yusdBalance)}
                  />
                </Box>
                {/* <Divider mt={8} mb={4} colorScheme="gray" borderColor="#373B4E" height="0px" borderWidth="2px"/> */}
                {/* <Text textStyle="subtitle3" align="left" mt={4}>Receive:</Text> */}
                <Box display="flex" px="24px">
                  {connected ? (
                    <Button
                      w="full"
                      variant="newPrimary"
                      onClick={() => validate(values.yusdRedeemInput)}
                    >
                      Redeem
                    </Button>
                  ) : (
                    <ConnectButton w="full" />
                  )}
                </Box>
              </Box>

              {/* <Flex mt={4} justify="flex-end">
                  
                  <Spacer />
                  
                </Flex> */}
            </>
          )}
        />
      </Box>
    </>
  );
};

export default RedeemCard;
