import React, { useEffect, useState } from "react";
import { Box, Hide, Text, useDisclosure } from "@chakra-ui/react";
// import { SearchIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
// import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { tokenDataMappingA, tags, Item } from "../../TokenData";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { WalletSelector } from "../../Components/WalletConnector";
import { CoinAmount, TokenTable, Icon } from "../../Components";
import { format, getNum } from "../../Utils/number";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";
import { SystemSummary } from "../../PageComponents/Dashboard";

export type BorrowProps = {
  disconnected?: boolean;
};

const selector = ({
  tokenBalances,
  vaultDepositInfo,
  prices,
  total,
  decimals,
  safetyRatios,
  recoveryRatios,
  interestRates,
  DollarCaps,
}: LiquityStoreState) => ({
  tokenBalances,
  vaultDepositInfo,
  prices,
  total,
  decimals,
  safetyRatios,
  recoveryRatios,
  interestRates,
  DollarCaps,
});

export interface CollateralAPYs {
  [key: string]: any;
}

interface TagProps {
  key: string;
  label: string;
}

// const tagComponents = tags.map((tag) => (
//   <Tag key={tag.value} label={tag.label} />
// ));

const Info: React.FC<BorrowProps> = ({ disconnected = false }) => {
  const {
    tokenBalances,
    vaultDepositInfo,
    prices,
    total,
    decimals,
    safetyRatios,
    recoveryRatios,
    interestRates,
    DollarCaps,
  } = useLiquitySelector(selector);
  const { APYs } = useProtocolDataContext();

  const vaultTokenBalances: any = {};
  const allVaultTokenBalances = vaultDepositInfo.vaultTokenBalances;
  for (let i = 0; i < Object.keys(allVaultTokenBalances).length; i++) {
    const token = Object.keys(allVaultTokenBalances)[i];
    if (tokenDataMappingA[token]) {
      vaultTokenBalances[token] = allVaultTokenBalances[token];
    } else {
      console.log("token not in tokenData", token);
    }
  }

  const vaultDeposits = vaultDepositInfo.vaultDeposits;
  const currVaults = vaultDepositInfo.currVaults;
  const vaultTokenTotalSupply = vaultDepositInfo.vaultTokenTotalSupply;

  const {
    isOpen: isSelectorOpen,
    onOpen: onSelectorOpen,
    onClose: onSelectorClose,
  } = useDisclosure();

  const [showZeroBalance, setShowZeroBalance] = useState(true);
  // const [value, setValue] = React.useState<string>("");

  const [pickerTags, setPickerTags] = React.useState(tags);
  const [selectedTags, setSelectedTags] = React.useState<Item[]>([]);

  const [sortedBy, setSortedBy] = useState<
    "TVL" | "WalletBalanceValue" | "APY" | "DepositValue" | "LTV" | "Interest"
  >("APY");

  const handleSelectedTagsChange = (_selectedTags?: Item[]) => {
    if (_selectedTags) {
      setSelectedTags(_selectedTags);
    }
  };

  const Tag = (props: TagProps) => {
    const [isSelected, selected] = useState(false);
    return (
      <Box
        px="8px"
        py="2px"
        bg={isSelected ? "primary-default" : "surface-highlight"}
        color={isSelected ? "#DFE3E9" : "text-primary"}
        shadow="sm"
        rounded="full"
        fontSize="14px"
        cursor="pointer"
        onClick={() => {
          // setSelectedTags()
          // setSelectedTags(selectedTags);
          selected(!isSelected);
          console.log(props.label);
        }}
      >
        {props.label}
      </Box>
    );
  };

  const handleCreateItem = (item: Item) => {
    setPickerTags((curr) => [...curr, item]);
    setSelectedTags((curr) => [...curr, item]);
  };

  const sortVaultsBy = (
    by:
      | "TVL"
      | "WalletBalanceValue"
      | "APY"
      | "DepositValue"
      | "LTV"
      | "Interest"
  ): string[] => {
    const compareFn = (a: string, b: string): number => {
      switch (by) {
        case "TVL":
          return (
            Number(vaultTokenTotalSupply[b].mul(prices[b]).toString()) -
            Number(vaultTokenTotalSupply[a].mul(prices[a]).toString())
          );

        case "WalletBalanceValue":
          return (
            Number(
              tokenBalances[
                tokenDataMappingA[a].underlying
              ].toStringWithDecimals(tokenDataMappingA[a].underlyingDecimals)
            ) -
            Number(
              tokenBalances[
                tokenDataMappingA[b].underlying
              ].toStringWithDecimals(tokenDataMappingA[b].underlyingDecimals)
            )
          );
        case "APY":
          return (
            Number(APYs[tokenDataMappingA[b].token]) -
            Number(APYs[tokenDataMappingA[a].token])
          );
        case "LTV":
          return (
            Number(safetyRatios[tokenDataMappingA[b].token]) -
            Number(safetyRatios[tokenDataMappingA[a].token])
          );

        case "Interest":
          return (
            Number(interestRates[tokenDataMappingA[b].token]) -
            Number(interestRates[tokenDataMappingA[a].token])
          );

        case "DepositValue":
          return (
            Number(vaultTokenBalances[b].mul(prices[b]).toString()) -
            Number(vaultTokenBalances[a].mul(prices[a]).toString())
          );
      }
    };

    const vaultTokens =
      selectedTags.length === 0
        ? Object.keys(vaultTokenBalances).sort(compareFn)
        : Object.keys(vaultTokenBalances)
            .filter((address) => {
              return selectedTags.every((tag) =>
                tokenDataMappingA[address].tags.includes(tag.value)
              );
            })
            .sort(compareFn);

    return vaultTokens;
  };

  const [sortedVaults, setSortedVaults] = useState<string[]>(
    sortVaultsBy(sortedBy)
  );

  const [reverseOrder, setReverseOrder] = useState<boolean>(false);

  useEffect(() => {
    setReverseOrder(false);
    setSortedVaults(sortVaultsBy(sortedBy));
  }, [sortedBy, setReverseOrder]);

  useEffect(() => {
    setSortedVaults(sortVaultsBy(sortedBy));
  }, [selectedTags]);

  const wrappedTokens = [
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
  ];

  const vaultsToShow = showZeroBalance
    ? sortedVaults.concat(wrappedTokens)
    : sortedVaults
        .concat(wrappedTokens)
        .filter(
          (address) =>
            tokenBalances[tokenDataMappingA[address].underlying].nonZero
        );

  for (let i = 0; i < wrappedTokens.length; i++) {
    const token = wrappedTokens[i];
    const supply = total.collaterals[token];
    vaultTokenTotalSupply[token] = supply;
    vaultDeposits[token] = Decimal.ZERO;
  }

  let totalSystemVC = 0;
  let totalSystemUSD = 0;
  let totalStableUSD = 0;

  Object.keys(total.collaterals).map((address) => {
    const amountUSD = format(
      total.collaterals[address]
        .mul(10 ** (18 - format(decimals[address])))
        .mul(prices[address])
        .div(1e18)
    );
    totalSystemVC += amountUSD * format(recoveryRatios[address]);
    totalSystemUSD += amountUSD;
    if (
      tokenDataMappingA[address] !== undefined &&
      tokenDataMappingA[address].isStable
    ) {
      totalStableUSD += amountUSD;
    }
  });
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  return (
    <>
      <Box
        h="343px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Box display="flex" justifyContent="space-between">
        <Text color="#DFE3E9" textStyle="display_md">
          Protocol Overview
        </Text>
        {/* <Box display="flex" flexDir="column" alignItems="flex-end">
          <Text fontSize="15px" color="#8A93A6">
            Vaults TVL
          </Text>
          <Text color="#DFE3E9" textStyle="display_xs">
            ${getNum(totalSystemUSD, 4)}
          </Text>
        </Box> */}
      </Box>
      {isSelectorOpen && (
        <WalletSelector
          isSelectorOpen={isSelectorOpen}
          onSelectClose={onSelectorClose}
        />
      )}
      <Box mt="48px" />
      <Box flexDir="column" display="flex" w="full">
        <SystemSummary />
        <Box layerStyle="card" p="0px" mt="24px">
          <>
            <Box
              py="16px"
              px="24px"
              display="flex"
              justifyContent="space-between"
              borderBottom="1px"
              borderColor="border"
            >
              <Text textStyle="card_header" display="flex">
                Collateral Assets
              </Text>
              {/* <Box display="flex">
                <Checkbox
                  onChange={(e) => {
                    setShowZeroBalance(e.target.checked);
                  }}
                  size="md"
                  style={{ outline: "none" }}
                  defaultChecked={true}
                />
                <Text textStyle="text_sm" color="text-secondary" ml={3}>
                  Show vaults with 0 wallet balance
                </Text>
              </Box> */}
            </Box>
            {/* <Box display="flex" ml="10">
              <CUIAutoComplete
                label="Add Filters"
                placeholder="Type a Filter"
                items={pickerTags}
                // onCreateItem={handleCreateItem}
                selectedItems={selectedTags}
                onSelectedItemsChange={(changes) =>
                  handleSelectedTagsChange(changes.selectedItems)
                }
                listStyleProps={{ bg: "grey.100", pt: "4" }}
                tagStyleProps={{ bg: "black.100", pt: "4" }}
                disableCreateItem={true}
              />
            </Box> */}

            {/* <Box mx="24px" mb="8px" mt="16px">
              <Box display="flex" fontSize="14px" gap="16px">
                {tags.map((tag) => (
                  <Tag key={tag.value} label={tag.label} />
                ))}
              </Box>
            </Box> */}

            <Box layerStyle="tableSub" px="24px">
              <Box display="flex">
                <Box display="flex">
                  <Text>Name</Text>
                </Box>
              </Box>
              <Box display="flex">
                <Hide below="1050px">
                  <Box display="flex" justifyContent="flex-end">
                    <Text
                      cursor="pointer"
                      _hover={{ color: "primary-default" }}
                      _active={{ color: "text-primary" }}
                      onClick={() => {
                        if (sortedBy === "LTV") {
                          setSortedVaults(sortedVaults.reverse());
                          setReverseOrder(!reverseOrder);
                        } else {
                          setSortedBy("LTV");
                        }
                      }}
                    >
                      Remaining Capacity
                      {sortedBy === "LTV" ? (
                        !reverseOrder ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )
                      ) : null}
                    </Text>
                  </Box>
                </Hide>
                <Hide below="900px">
                  <Box display="flex" justifyContent="flex-end" w="140px">
                    <Text
                      cursor="pointer"
                      _hover={{ color: "primary-default" }}
                      _active={{ color: "text-primary" }}
                      onClick={() => {
                        if (sortedBy === "LTV") {
                          setSortedVaults(sortedVaults.reverse());
                          setReverseOrder(!reverseOrder);
                        } else {
                          setSortedBy("LTV");
                        }
                      }}
                    >
                      Max LTV
                      {sortedBy === "LTV" ? (
                        !reverseOrder ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )
                      ) : null}
                    </Text>
                  </Box>
                </Hide>
                <Hide below="sm">
                  <Box display="flex" justifyContent="flex-end" w="140px">
                    <Text
                      cursor="pointer"
                      _hover={{ color: "primary-default" }}
                      _active={{ color: "text-primary" }}
                      onClick={() => {
                        if (sortedBy === "APY") {
                          setSortedVaults(sortedVaults.reverse());
                          setReverseOrder(!reverseOrder);
                        } else {
                          setSortedBy("APY");
                        }
                      }}
                    >
                      Rewards
                      {sortedBy === "APY" ? (
                        !reverseOrder ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )
                      ) : null}
                    </Text>
                  </Box>
                </Hide>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  w={{ sm: "140px", md: "180px" }}
                >
                  <Text
                    cursor="pointer"
                    _hover={{ color: "primary-default" }}
                    _active={{ color: "text-primary" }}
                    onClick={() => {
                      if (sortedBy === "TVL") {
                        setSortedVaults(sortedVaults.reverse());
                        setReverseOrder(!reverseOrder);
                      } else {
                        setSortedBy("TVL");
                      }
                    }}
                  >
                    TVL
                    {sortedBy === "TVL" ? (
                      !reverseOrder ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronUpIcon />
                      )
                    ) : null}
                  </Text>
                </Box>
                {/* <Hide below="lg">
                  <Box display="flex" justifyContent="flex-end" w="160px">
                    <Text
                      cursor="pointer"
                      _hover={{ color: "primary-default" }}
                      _active={{ color: "text-primary" }}
                      onClick={() => {
                        if (sortedBy === "DepositValue") {
                          setSortedVaults(sortedVaults.reverse());
                          setReverseOrder(!reverseOrder);
                        } else {
                          setSortedBy("DepositValue");
                        }
                      }}
                    >
                      Trove deposit
                      {sortedBy === "DepositValue" ? (
                        !reverseOrder ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )
                      ) : null}
                    </Text>
                  </Box>
                </Hide> */}
                <Hide below="md">
                  <Box display="flex" justifyContent="flex-end" w="160px">
                    <Text
                      cursor="pointer"
                      _hover={{ color: "primary-default" }}
                      _active={{ color: "text-primary" }}
                      onClick={() => {
                        if (sortedBy === "WalletBalanceValue") {
                          setSortedVaults(sortedVaults.reverse());
                          setReverseOrder(!reverseOrder);
                        } else {
                          setSortedBy("WalletBalanceValue");
                        }
                      }}
                    >
                      Wallet Balance
                      {sortedBy === "WalletBalanceValue" ? (
                        !reverseOrder ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronUpIcon />
                        )
                      ) : null}
                    </Text>
                  </Box>
                </Hide>

                {/* <Box w="200px" /> */}
              </Box>
            </Box>

            <TokenTable borrow>
              <>
                {vaultsToShow.map((address) => (
                  <Link
                    to={"/vault/" + tokenDataMappingA[address].token}
                    key={address}
                  >
                    <Box
                      layerStyle="tableRow"
                      px="24px"
                      key={address}
                      _hover={{ bg: "surface-hover" }}
                      animation="ease-out"
                      color="text-primary"
                      transitionDuration="200ms"
                      transition="ease-out"
                      alignItems="center"
                      display="flex"
                      h="72px"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        w="full"
                        alignItems="center"
                        h="full"
                      >
                        <Box
                          alignItems="center"
                          display="flex"
                          // w="220px"
                          cursor="pointer"
                          overflow="hidden"
                        >
                          <Icon
                            iconName={tokenDataMappingA[address].token}
                            h="40px"
                            w="40px"
                          />
                          <Box
                            display="flex"
                            flexDir="column"
                            alignItems="flex-start"
                            ml="12px"
                          >
                            <Text textStyle="assetName" noOfLines={1}>
                              {tokenDataMappingA[address].name}
                            </Text>
                            <Text textStyle="asset">
                              {tokenDataMappingA[address].token}
                            </Text>
                          </Box>
                          {/* {tokenDataMappingA[address].token === "GLP" && (
                            <Hide below="md">
                              <Text
                                fontSize="10px"
                                px="8px"
                                py="3px"
                                bg="primary-default"
                                color="white"
                                rounded="full"
                                ml="16px"
                              >
                                NEW
                              </Text>
                            </Hide>
                          )} */}
                        </Box>

                        <Box display="flex">
                          <Box display="flex" alignItems="center">
                            <Hide below="1050px">
                              <Box display="flex" justifyContent="flex-end">
                                <Text>
                                  {/* ${
                                    getNum(
                                  (Number(DollarCaps[address].mul(prices[address])) -
                                  Number(total.collaterals[address].mul(prices[address])))
                                    )
                                  } */}
                                  $
                                  {getNum(
                                    Number(DollarCaps[address]) -
                                      Number(
                                        total.collaterals[address].mul(
                                          prices[address]
                                        )
                                      )
                                  )}
                                </Text>
                              </Box>
                            </Hide>

                            <Hide below="900px">
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                w="140px"
                              >
                                <Text>
                                  {(
                                    (Number(safetyRatios[address]) / 1.1) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </Text>
                              </Box>
                            </Hide>
                            <Hide below="sm">
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                w={{ base: "80px", md: "140px" }}
                              >
                                <Text>
                                  {(
                                    APYs[tokenDataMappingA[address].token] * 100
                                  ).toFixed(2)}
                                  %{" "}
                                </Text>
                              </Box>
                            </Hide>

                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              w={{ sm: "140px", md: "180px" }}
                            >
                              <Text>
                                $
                                {getNum(
                                  Number(
                                    total.collaterals[address]
                                      .mul(prices[address])
                                      .toString()
                                  )
                                )}
                              </Text>
                            </Box>
                            <Hide below="md">
                              <Box
                                w="160px"
                                display="flex"
                                justifyContent="flex-end"
                                // pr="114px"
                              >
                                <Box
                                  display="flex"
                                  flexDir="column"
                                  alignItems="flex-end"
                                >
                                  <Text
                                    alignItems="flex-end"
                                    whiteSpace="nowrap"
                                    color={
                                      Number(
                                        tokenBalances[
                                          tokenDataMappingA[address].underlying
                                        ].toStringWithDecimals(
                                          tokenDataMappingA[address]
                                            .underlyingDecimals
                                        )
                                      ) > 0
                                        ? "text-primary"
                                        : "text-secondary"
                                    }
                                  >
                                    {getNum(
                                      Number(
                                        tokenBalances[
                                          tokenDataMappingA[address].underlying
                                        ].toStringWithDecimals(
                                          tokenDataMappingA[address]
                                            .underlyingDecimals
                                        )
                                      )
                                    )}
                                  </Text>
                                  <CoinAmount
                                    token={tokenDataMappingA[address].token}
                                    noGetNum={true}
                                    // noCurrencyConvert={true}
                                    // firstNumber={true}
                                    amount={Number(
                                      tokenBalances[
                                        tokenDataMappingA[address].underlying
                                      ].toStringWithDecimals(
                                        tokenDataMappingA[address]
                                          .underlyingDecimals
                                      )
                                    )}
                                    // noCurrencyConvert={true}
                                    fontSize="13px"
                                    color="text-secondary"
                                    fontWeight="500"
                                  />
                                </Box>
                              </Box>
                            </Hide>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                ))}
              </>
            </TokenTable>
          </>
        </Box>
      </Box>
    </>
  );
};

export default Info;
