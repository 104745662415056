import { BoxProps, ChakraProvider } from "@chakra-ui/react";
import React from "react";
// import { Helmet } from "react-helmet";
import theme from "../../../Theme";
import { TokenInfoProvider } from "../../../AppState/TokenInfo";

import "@fontsource/sora/800.css";
import "@fontsource/sora/700.css";
import "@fontsource/sora/600.css";
import "@fontsource/sora/500.css";
import "@fontsource/sora/400.css";
import "@fontsource/sora/300.css";

// import "@fontsource/mulish/900.css";
// import "@fontsource/mulish/800.css";
// import "@fontsource/mulish/700.css";
// import "@fontsource/mulish/600.css";
// import "@fontsource/mulish/500.css";
// import "@fontsource/mulish/400.css";
// import "@fontsource/mulish/300.css";
// import "@fontsource/mulish/200.css";

// import "@fontsource/urbanist/900.css";
// import "@fontsource/urbanist/800.css";
// import "@fontsource/urbanist/700.css";
// import "@fontsource/urbanist/600.css";
// import "@fontsource/urbanist/500.css";
// import "@fontsource/urbanist/400.css";
// import "@fontsource/urbanist/300.css";
// import "@fontsource/urbanist/200.css";

// import "@fontsource/roboto/900.css";
// import "@fontsource/roboto/700.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/300.css";

import "@fontsource/inter/900.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/300.css";

// import "@fontsource/red-hat-display/900.css";
// import "@fontsource/red-hat-display/800.css";
// import "@fontsource/red-hat-display/700.css";
// import "@fontsource/red-hat-display/600.css";
// import "@fontsource/red-hat-display/500.css";
// import "@fontsource/red-hat-display/400.css";
// import "@fontsource/red-hat-display/300.css";

// import "@fontsource/nunito/900.css";
// import "@fontsource/nunito/800.css";
// import "@fontsource/nunito/700.css";
// import "@fontsource/nunito/600.css";
// import "@fontsource/nunito/500.css";
// import "@fontsource/nunito/400.css";
// import "@fontsource/nunito/300.css";
// import "@fontsource/nunito/200.css";

type RootWrapperProps = BoxProps;
const RootWrapper: React.FC<RootWrapperProps> = ({ children, ...props }) => (
  <ChakraProvider theme={theme}>
    <TokenInfoProvider>
      {/* <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
      </Helmet> */}
      {children}
    </TokenInfoProvider>
  </ChakraProvider>
);

export default RootWrapper;
