import React from "react";
import { Text, Box, BoxProps, Flex, Spacer } from "@chakra-ui/react";
import { Icon, CoinAmount } from "../../../Components";
// import CurrencyConverter from "../../../Components/CurrencyConverter";
import Tooltip from "../../../Components/Tooltip/Tooltip";

type CollateralItemProps = {
  token: string;
  amount: number;
  yusdFromLever?: number;
  changeWithoutLever?: number;
  leverage?: number;
  currency?: string;
  ratio: number;
  fee?: number;
  feePercentage?: number;
  currencyType?: string;
} & BoxProps;

const CollateralItem: React.FC<CollateralItemProps> = ({
  token,
  amount,
  yusdFromLever = 0,
  changeWithoutLever = 0,
  leverage = 0,
  currency,
  ratio,
  fee,
  feePercentage = 0,
  currencyType,
  ...props
}) => {
  return (
    <Box {...props} display="flex" w="full">
      <Box
        display="flex"
        justifyContent="space-between"
        w="full"
        alignItems="center "
      >
        <Box display="flex" alignItems="center">
          <Icon iconName={token} h="18px" w="18px" alignItems="center" />
          <Text textStyle="number_base" color="text-primary" ml="4px">
            {token}
          </Text>
        </Box>
        <Box display="flex" flexDir="column" alignItems="flex-end">
          <CoinAmount
            token={token}
            amount={amount}
            safetyRatio={ratio}
            currency={currencyType == undefined ? "VC" : currencyType}
          />
          <CoinAmount
            noGetNum={true}
            token={token}
            amount={amount}
            safetyRatio={ratio}
            currency={currencyType == undefined ? "VC" : currencyType}
          />
        </Box>
      </Box>
      {leverage != 0 ? (
        <Flex mt={2.5} ml={2.5}>
          <Text textStyle="body2" color="#949494">
            {"Estimated Leverage: "}{" "}
            {
              <Tooltip>
                {"Estimated Leverage on this asset as determined earlier. "}
              </Tooltip>
            }
          </Text>

          <Spacer />
          <Text color="#949494" textStyle="body2" fontWeight="bold">
            {Number(leverage).toFixed(2)}x
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      {leverage == 0 && yusdFromLever > 0 ? (
        <Flex mt={2.5} ml={2.5}>
          <Text textStyle="body2" color="#949494">
            {"Deleveraged Amount: "}{" "}
            {
              <Tooltip>
                {"Collateral amount that is autosold into YUSD to reduce debt"}
              </Tooltip>
            }
          </Text>

          <Spacer />
          <Text color="#949494" textStyle="body2" fontWeight="bold">
            {Number(yusdFromLever).toFixed(2)} YUSD
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      {leverage != 0 && changeWithoutLever && changeWithoutLever != 0 ? (
        <Flex mt={2.5} ml={2.5}>
          <Text textStyle="body2" color="#949494">
            {"Deposited Amount from Wallet: "}{" "}
            {<Tooltip>{"Amount pre leverage of this asset."}</Tooltip>}
          </Text>

          <Spacer />
          <Text color="#949494" textStyle="body2" fontWeight="bold">
            {Number(changeWithoutLever).toFixed(3) + " " + token}
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      {fee !== undefined && (
        <></>
        // <Flex mt={2.5} ml={2.5}>
        //   <Text textStyle="text_sm" fontSize="15px" color="text-secondary">
        //     {"Deposit fee"}{" "}
        //     {
        //       <Tooltip>
        //         {
        //           "The sum of variable deposit fees for all collateral deposits."
        //         }
        //       </Tooltip>
        //     }
        //   </Text>

        //   <Spacer />
        //   <Box display="flex" flexDir="column" alignItems="flex-end">
        //     <Text textStyle="text_sm" fontSize="15px" color="text-secondary">
        //       {(feePercentage * 100).toFixed(3)}%
        //     </Text>
        //     <Text textStyle="text_sm" fontSize="15px" color="text-secondary">
        //       <CurrencyConverter token={token} value={fee} currency={"YUSD"} />
        //     </Text>
        //   </Box>
        // </Flex>
      )}
    </Box>
  );
};

export default CollateralItem;
