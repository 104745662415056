import { createIcon } from "@chakra-ui/react";

const aBTCb = createIcon({
  displayName: "aBTC.b",
  viewBox: "0 0 500 500",
  path: (
    <>
      <g>
        <path
          d="M492.468 310.477C459.078 444.407 323.429 525.915 189.484 492.516C55.5934 459.126 -25.9144 323.469 7.49188 189.548C40.8669 55.6023 176.515 -25.9133 310.422 7.47731C444.359 40.8679 525.859 176.54 492.468 310.477Z"
          fill="#B24691"
        />
        <path
          d="M360.164 214.383C365.141 181.117 339.813 163.234 305.18 151.305L316.414 106.242L288.984 99.4063L278.047 143.281C270.836 141.484 263.43 139.789 256.07 138.109L267.086 93.9453L239.672 87.1094L228.43 132.156C222.461 130.797 216.602 129.453 210.914 128.039L210.945 127.898L173.117 118.453L165.82 147.75C165.82 147.75 186.172 152.414 185.742 152.703C196.852 155.477 198.859 162.828 198.523 168.656L185.727 219.992C186.492 220.188 187.484 220.469 188.578 220.906C187.664 220.68 186.687 220.43 185.68 220.188L167.742 292.102C166.383 295.477 162.938 300.539 155.172 298.617C155.445 299.016 135.234 293.641 135.234 293.641L121.617 325.039L157.313 333.938C163.953 335.602 170.461 337.344 176.867 338.984L165.516 384.562L192.914 391.398L204.156 346.305C211.641 348.336 218.906 350.211 226.016 351.977L214.812 396.859L242.242 403.695L253.594 358.203C300.367 367.055 335.539 363.484 350.344 321.18C362.273 287.117 349.75 267.469 325.141 254.656C343.063 250.523 356.562 238.734 360.164 214.383V214.383ZM297.492 302.266C289.016 336.328 231.664 317.914 213.07 313.297L228.133 252.914C246.727 257.555 306.352 266.742 297.492 302.266ZM305.977 213.891C298.242 244.875 250.508 229.133 235.023 225.273L248.68 170.508C264.164 174.367 314.031 181.57 305.977 213.891V213.891Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_22">
          <rect width="500" height="500" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});

export default aBTCb;
