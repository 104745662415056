import React, { useState } from "react";
import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { TokenTable } from "../../../Components";

import { getNum } from "../../../Utils/number";
import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";

export type TroveListProps = Record<string, never>;

const TroveList: React.FC<TroveListProps> = () => {
  const { sortedTrovesData } = useProtocolDataContext();

  const numberOfTroves = sortedTrovesData.length;

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [idx, setIdx] = useState<number>(0);

  const onClickLeft = () => {
    if (idx > 0) {
      setIdx(idx - 1);
    }
  };

  const onClickRight = () => {
    if (idx < numberOfTroves / 10 - 1) {
      setIdx(idx + 1);
    }
  };

  return (
    <>
      <Box layerStyle="card" w="full">
        <Box px="24px" py="16px">
          <Text textStyle="card_header">Sorted Troves</Text>
        </Box>
        <Box
          display="flex"
          textStyle="subheading"
          pb="16px"
          color="text-secondary"
          pt="16px"
          justifyContent="space-between"
          alignItems="center"
          borderTop="1px"
          borderColor="border"
          px="24px"
        >
          <Box display="flex">
            <Box display="flex">
              <Text>Trove Owner</Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box pr="86px" display="flex" justifyContent="flex-end">
              <Text>Outstanding Debt</Text>
            </Box>
            <Box pr="96px" display="flex" justifyContent="flex-end">
              <Text>AICR</Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Text>ICR</Text>
            </Box>
          </Box>
          {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
        </Box>
        {/* <Flex flex={2}> */}
        {/* </Flex> */}
        {
          <TokenTable
          // headers={["Trove Owner", "", "Outstanding Debt", "AICR", "ICR"]}
          // tooltips={[
          //   "",
          //   "",
          //   "Trove's debt - 200",
          //   "A trove's Adjusted Individual Collateral Ratio or AICR is a ratio between collateral and debt giving additional weight to stablecoins.",
          //   "",
          // ]}
          // width={5}
          // display={["none", "block"]}
          >
            {sortedTrovesData
              .slice(idx * 10, idx * 10 + 10)
              .map((currTroveData) => (
                <a
                  key={currTroveData.owner}
                  href={`https://snowtrace.io/address/${currTroveData.owner}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    display="flex"
                    // textStyle="number_base"
                    color="text-primary"
                    fontSize="14px"
                    fontWeight="500"
                    justifyContent="space-between"
                    h="56px"
                    alignItems="center"
                    borderTop="1px"
                    borderColor="border"
                    px="24px"
                    _hover={{ bg: "surface-hover" }}
                  >
                    <Box display="flex" maxW="300px">
                      <a>
                        <Text noOfLines={1}>{currTroveData.owner}</Text>
                      </a>
                    </Box>
                    <Box display="flex">
                      {[...new Array(1)].map((_) => (
                        <Box
                          key={_}
                          py={3}
                          borderColor="border"
                          fontSize={14}
                        />
                      ))}
                      <Box pr="80px" display="flex">
                        {getNum(currTroveData.outstandingDebt, 3)}
                      </Box>
                      <Box pr="80px" display="flex">
                        {getNum(currTroveData.aicr, 3)}
                      </Box>

                      <Box display="flex">{getNum(currTroveData.icr, 3)}</Box>
                    </Box>
                  </Box>
                </a>
              ))}
          </TokenTable>
        }
        <Box
          display="flex"
          py="16px"
          px="24px"
          justifyContent="space-between"
          fontSize="14px"
          fontWeight="500"
          borderTop="1px"
          borderColor="border"
          color="text-primary"
        >
          <Text color="text-secondary">
            Showing {(idx + 1) * 10 - 9}-
            {sortedTrovesData.length < idx * 10 + 10
              ? sortedTrovesData.length
              : idx * 10 + 10}{" "}
            of {sortedTrovesData.length} results
          </Text>
          <Box display="flex" gap="8px">
            <a onClick={onClickLeft}>
              {" "}
              <Text
                _hover={{ color: "primary-default" }}
                cursor="pointer"
                bg="surface-hover"
                py="3px"
                px="6px"
                borderRadius="6px"
                borderColor="gray.600"
                shadow="sm"
              >
                Previous
              </Text>{" "}
            </a>
            <a
              onClick={onClickRight}
              // disabled={sortedTrovesData.length <= idx * 10}
            >
              {" "}
              <Text
                _hover={{ color: "primary-default" }}
                cursor="pointer"
                bg="surface-hover"
                py="3px"
                px="6px"
                borderRadius="6px"
                borderColor="gray.600"
                shadow="sm"
              >
                Next
              </Text>{" "}
            </a>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TroveList;
