import React from "react";

import {
  Flex,
  Checkbox as ChakraCheckBox,
  Text,
  BoxProps,
  Box,
} from "@chakra-ui/react";

export type CheckboxProps = {
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  label: string;
  popup?: boolean;
} & BoxProps;

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  onChange,
  error,
  label,
  popup = false,
  ...props
}) => {
  return (
    <Flex direction="column" {...props}>
      {popup ? (
        <Box display="flex" flexDir="column" alignItems="center">
          <Box display="flex">
            <ChakraCheckBox
              size="lg"
              isChecked={isChecked}
              onChange={onChange}
            />
            <Text ml={5} textStyle="body3" color="text-secondary">
              {label}
            </Text>
          </Box>
          {error && (
            <Text mt={3} textStyle="body3" color="red.400">
              You must check this box to continue.
            </Text>
          )}
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-start">
            <ChakraCheckBox
              size="md"
              isChecked={isChecked}
              onChange={onChange}
              display="flex"
            />
            <Text ml="8px" fontSize="13px" color="text-secondary">
              {label}
            </Text>
          </Box>
          {error && (
            <Text mt={3} textStyle="body3" color="red.400">
              You must check this box to continue.
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default Checkbox;
