import React, { useReducer, useState } from "react";
import { AbstractConnector } from "@web3-react/abstract-connector";

import {
  injectedConnector,
  walletConnectConnector,
  coinbaseConnector,
} from "../connectors/injectedConnector";

import {
  Box,
  Button,
  Divider,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import Icon from "./Icon";
import { useWeb3Context } from "../Hooks/useWeb3Context";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useLiquity } from "../Hooks/LiquityContext";

interface MaybeHasMetaMask {
  ethereum?: {
    isMetaMask?: boolean;
  };
}

type ConnectionState =
  | { type: "inactive" }
  | {
      type:
        | "activating"
        | "active"
        | "rejectedByUser"
        | "alreadyPending"
        | "failed";
      connector: AbstractConnector;
    };

type ConnectionAction =
  | { type: "startActivating"; connector: AbstractConnector }
  | { type: "fail"; error: Error }
  | { type: "finishActivating" | "retry" | "cancel" | "deactivate" };

export const connectionReducer: React.Reducer<
  ConnectionState,
  ConnectionAction
> = (state, action) => {
  switch (action.type) {
    case "startActivating":
      return {
        type: "activating",
        connector: action.connector,
      };
    case "finishActivating":
      return {
        type: "active",
        connector:
          state.type === "inactive" ? injectedConnector : state.connector,
      };
    case "fail":
      if (state.type !== "inactive") {
        return {
          type: action.error.message.match(/user rejected/i)
            ? "rejectedByUser"
            : action.error.message.match(/already pending/i)
            ? "alreadyPending"
            : "failed",
          connector: state.connector,
        };
      }
      break;
    case "retry":
      if (state.type !== "inactive") {
        return {
          type: "activating",
          connector: state.connector,
        };
      }
      break;
    case "cancel":
      return {
        type: "inactive",
      };
    case "deactivate":
      return {
        type: "inactive",
      };
  }

  console.warn("Ignoring connectionReducer action:");

  return state;
};

const detectMetaMask = () =>
  (window as MaybeHasMetaMask).ethereum?.isMetaMask ?? false;

type WalletConnectorProps = {
  loader?: React.ReactNode;
};

// const WalletConnector: React.FC<WalletConnectorProps> = ({
//   children,
//   loader,
// }) => {
//   const { activate, deactivate, active, error, connector } =
//     useWeb3React<unknown>();
//   const triedAuthorizedConnection = useAuthorizedConnection();
//   const [connectionState, dispatch] = useReducer(connectionReducer, {
//     type: "inactive",
//   });
//   const isMetaMask = detectMetaMask();

//   useEffect(() => {
//     if (error) {
//       dispatch({ type: "fail", error });
//       deactivate();
//     }
//   }, [error, deactivate]);

//   useEffect(() => {
//     if (active) {
//       dispatch({ type: "finishActivating" });
//     } else {
//       dispatch({ type: "deactivate" });
//     }
//   }, [active]);

//   if (!triedAuthorizedConnection) {
//     return <>{loader}</>;
//   }

//   if (connectionState.type === "active") {
//     return <>{children}</>;
//   }

//   return (
//     <></>
//   );
// };

export const WalletSelector: React.FC<{
  isSelectorOpen: boolean;
  onSelectClose: () => void;
  mode?: string;
}> = ({ isSelectorOpen, onSelectClose, mode, ...props }) => {
  const { connectWallet } = useWeb3Context();
  const [connectionState, dispatch] = useReducer(connectionReducer, {
    type: "inactive",
  });

  const isMetaMask = detectMetaMask();

  const [understandDisclaimer, setUnderstandDisclaimer] = useState(false);
  const [understandDisclaimerError, setUnderstandDisclaimerError] =
    useState(false);
  const { account } = useLiquity();
  const onSubmit = (): void => {
    if (mode == "") {
      if (!understandDisclaimer) {
        setUnderstandDisclaimerError(true);
      } else {
        localStorage.setItem(
          account + "agreedToYetiFinanceDisclaimerMainnet",
          "agreed"
        );
        onSelectClose();
      }
    } else if (mode == "borrow") {
      localStorage.setItem(account + "agreedToYetiBorrowInfograph", "agreed");
      onSelectClose();
    } else if (mode == "veYETI") {
      localStorage.setItem(account + "agreedToYetiveYETIInfograph", "agreed");
      onSelectClose();
    } else if (mode == "farm") {
      localStorage.setItem(account + "agreedToYetiFarmInfograph", "agreed");
      onSelectClose();
    }
  };

  return (
    <Modal isOpen={isSelectorOpen} onClose={onSelectClose} size="sm" isCentered>
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalContent bg="surface" p="16px">
        <Box
          display="flex"
          w="full"
          justifyContent="space-between"
          alignItems="center"
          mt="16px"
          mb="32px"
          px="16px"
        >
          <Box display="flex" flexDir="column">
            <Text
              fontSize="24px"
              fontWeight="600"
              color="text-primary"
              letterSpacing="tight"
              fontFamily="Sora"
            >
              Connect wallet
            </Text>
          </Box>
          {/* <ModalCloseButton /> */}
          <CloseIcon
            color="text-primary"
            boxSize="14px"
            onClick={onSelectClose}
            cursor="pointer"
            _hover={{ bg: "surface-hover" }}
          />
        </Box>
        <Box mb="8px">
          <Box
            bg="surface"
            display="flex"
            flexDir="column"
            // p="24px"
            rounded="8px"
            shadow="shadow_sm"
            color="text-primary"
            alignItems="center"
          >
            <Button
              justifyContent="space-between"
              px="16px"
              rightIcon={<ArrowForwardIcon h="24px" w="24px" />}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: injectedConnector,
                });
                connectWallet(injectedConnector);
              }}
              // variant="surface"
              bg="surface"
              _hover={{ bg: "surface-hover" }}
              width="full"
              height="60px"
              rounded="14px"
              {...props}
            >
              {isMetaMask ? (
                <>
                  <Box display="flex" alignItems="center">
                    <Icon iconName="MetaMaskIcon" h={5} w={5} />
                    <Text ml="12px">MetaMask</Text>
                  </Box>
                </>
              ) : (
                <>
                  {/* <Icon name="plug" size="lg" /> */}
                  <Text ml="12px">Connect wallet</Text>
                </>
              )}
            </Button>
            <Button
              justifyContent="space-between"
              px="16px"
              rightIcon={<ArrowForwardIcon h="24px" w="24px" />}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: walletConnectConnector,
                });
                connectWallet(walletConnectConnector);
              }}
              bg="surface"
              _hover={{ bg: "surface-hover" }}
              height="60px"
              rounded="14px"
              {...props}
              width="full"
              mt="8px"
            >
              <>
                <Box display="flex" alignItems="center">
                  <Icon iconName="WalletConnect" h={5} w={5} />
                  <Text ml="16px">WalletConnect</Text>
                </Box>
              </>
            </Button>

            <Button
              justifyContent="space-between"
              px="16px"
              rightIcon={<ArrowForwardIcon h="24px" w="24px" />}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: coinbaseConnector,
                });
                connectWallet(coinbaseConnector);
              }}
              bg="surface"
              _hover={{ bg: "surface-hover" }}
              {...props}
              width="full"
              height="60px"
              rounded="14px"
              mt="8px"
            >
              <>
                <Box display="flex" alignItems="center">
                  <Icon iconName="Coinbase" h={5} w={5} rounded="full" />
                  <Text ml={2}>Coinbase Wallet</Text>
                </Box>
              </>
            </Button>
          </Box>
          <Divider color="border" mt="16px" />
          <Box display="flex" flexDir="column" gap="16px" mt="24px">
            <Text textAlign="center" fontSize="13px" color="text-secondary">
              <span>By connecting, I agree to the</span>
              <Link onClick={onSelectClose} to="/terms">
                <span
                  style={{
                    color: "#5F81F9",
                    marginLeft: "4px",
                    fontWeight: "600",
                    marginRight: "4px",
                  }}
                >
                  Terms of Use
                </span>
              </Link>
            </Text>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};
