import React, { useState, useEffect } from "react";
import {
  Box,
  BoxProps,
  Text,
  // Tr,
  Td,
  Button,
  // Divider,
  useDisclosure,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Checkbox,
  PopoverArrow,
  Spinner,
  Hide,
  // Hide,
  // Progress,
} from "@chakra-ui/react";
import { Icon, TokenTable, AdjustInput, CoinAmount } from "../../../Components";
// import AddCollateralTypeModal from "../AddCollateralTypeModal";
import LeverUpModal from "../LeverUpModal";
import { CoinMode, CoinShow, Collateral, TokenData } from "../../../Types";
// import tokenData from "../../../TokenData";
import { format, getNum } from "../../../Utils/number";
import { TroveMappings, Decimal } from "@yeti/lib-base";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import {
  getTroveUnderlyingValueWithLever,
  // getTroveVaultValueWithLever,
} from "../AdjustTrove/AdjustTrove.utils";
// import CollateralChart from "../AdjustTrove/CollateralChart";
import Tooltip from "../../../Components/Tooltip";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";
import { Link } from "react-router-dom";
import { BlockedCard } from "../../../Components/BlockedCard/BlockedCard";

type AdjustCollateralProps = {
  values: { [key: string]: any };
  borrowMode: "normal" | "lever" | "unlever";
  leverSave: "saved" | "unsaved";
  setLeverSave: React.Dispatch<React.SetStateAction<"saved" | "unsaved">>;
  collateral: TokenData[];
  form: any;
  currVcValue: number;
  troveBalance: number;
  depositFees: TroveMappings;
  mode: CoinMode;
  currUSDValue: number;
  setMode: React.Dispatch<React.SetStateAction<CoinMode>>;
} & BoxProps;

export interface CollateralAPYs {
  [key: string]: any;
}

const selector = ({
  trove,
  prices,
  tokenBalances,
  safetyRatios,
  decimals,
  underlyingPerReceiptRatios,
  receiptPerUnderlyingRatios,
  total,
  interestRates,
}: LiquityStoreState) => ({
  trove,
  prices,
  tokenBalances,
  safetyRatios,
  decimals,
  underlyingPerReceiptRatios,
  receiptPerUnderlyingRatios,
  total,
  interestRates,
});

const AdjustCollateral: React.FC<AdjustCollateralProps> = ({
  values,
  collateral,
  form,
  borrowMode,
  setLeverSave,
  leverSave,
  depositFees,
  currVcValue,
  troveBalance,
  mode,
  currUSDValue,
  setMode,
  ...props
}) => {
  const {
    trove,
    prices,
    tokenBalances,
    safetyRatios,
    decimals,
    underlyingPerReceiptRatios,
    receiptPerUnderlyingRatios,
    total,
    interestRates,
  } = useLiquitySelector(selector);
  const {
    isOpen: isAddCollateralTypeOpen,
    onOpen: onAddCollateralTypeOpen,
    onClose: onAddCollateralTypeClose,
  } = useDisclosure();
  const {
    isOpen: isLeverUpOpen,
    onOpen: onLeverUpOpen,
    onClose: onLeverUpClose,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [leveredToken, setLeveredToken] = useState<Collateral>(
    {} as Collateral
  );

  // Coin Display Config State
  const coinShow: CoinShow = {};
  collateral.forEach((coin) => {
    if (coin.troveBalance === 0) {
      coinShow[coin.token] = false;
    } else {
      coinShow[coin.token] = true;
    }
  });
  const [show, setShow] = useState<CoinShow>(coinShow);

  const { connected } = useWeb3Context();

  const [modalMode, setModalMode] = useState<"deposit" | "withdraw">("deposit");

  const [showZeroBalance, setShowZeroBalance] = useState(true);

  const [hidden, setHidden] = useState(true);

  const { userCountry, isBlockedCountry } = useProtocolDataContext();

  // let filteredCollater: Collateral[] = availableCollateral.filter((coin) =>
  //   borrowMode != "unlever" ? true : coin.troveBalance != 0
  // );

  // filteredCollater = filteredCollater.filter((coin) =>
  //   showZeroBalance ? true : coin.walletBalance !== 0
  // );

  // Filtered collateral:  (aave style add):
  // let currentCollateral = collateral.filter((coin) => show[coin.token]);

  // All collateral list:
  let currentCollateral = collateral;
  // console.log(interestRates);
  currentCollateral = currentCollateral.filter((coin) =>
    showZeroBalance ? true : coin.walletBalance !== 0 || coin.troveBalance !== 0
  );

  //   currentCollateral = currentCollateral.filter((coin) =>
  //     showZeroBalance ? true : coin.troveBalance !== 0
  // );

  useEffect(() => {
    collateral.forEach((coin) => {
      if (coin.troveBalance === 0) {
        coinShow[coin.token] = false;
      } else {
        coinShow[coin.token] = true;
      }
    });
    currentCollateral = collateral.filter((coin) => show[coin.token]);
  }, [show]);
  const openLeverUp = (token: Collateral) => {
    setLeveredToken(token);
    onLeverUpOpen();
    setLeverSave("unsaved");
  };

  const checker = (values: { [key: string]: any }) => {
    Object.keys(values).map((key) => {
      if (!key.includes("mode")) {
        const value = values[key];
        try {
          Decimal.from(values[key]);
          values[key] = value;
        } catch (err) {
          delete values[key];
        }
      }
    });

    return values;
  };
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const { APYs } = useProtocolDataContext();

  const showLeverModal = (item: Collateral) => {
    return (
      <Td pt={8} pb={2} pl={2}>
        {borrowMode === "normal" ? (
          <Button variant="orange" isDisabled>
            Lever Up
          </Button>
        ) : borrowMode === "lever" &&
          item.walletBalance === 0 &&
          isNaN(values[item.token + "leverage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)} isDisabled>
            Lever Up
          </Button>
        ) : borrowMode === "lever" &&
          item.walletBalance !== 0 &&
          isNaN(values[item.token + "slippage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            Lever Up
          </Button>
        ) : borrowMode === "unlever" &&
          item.troveBalance !== 0 &&
          isNaN(values[item.token + "slippage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            Deleverage
          </Button>
        ) : !isNaN(values[item.token + "leverage"]) &&
          values[item.token + "leverage"] != 1 ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            {values[item.token + "leverage"]}x Leverage
          </Button>
        ) : borrowMode === "lever" &&
          !isNaN(values[item.token + "slippage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            No Leverage
          </Button>
        ) : (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            Deleveraged
          </Button>
        )}
      </Td>
    );
  };

  // useEffect(() => {
  //   for (var i = 0; i < collateral.length; i++) {
  //     const coin = collateral[i];
  //     if (borrowMode === "unlever") {
  //       coins[coin.token] = "withdraw";
  //     } else if (borrowMode === "lever") {
  //       coins[coin.token] = "deposit";
  //     }
  //   }
  //   setMode(coins);
  // }, [ borrowMode]);

  // console.log("ratioMapping", underlyingPerReceiptRatios)
  // console.log("collateral", collateral)
  const newFormat = (x: Decimal | number) => {
    if (x) {
      return Math.min(parseFloat(x.toString()), 0.01);
    }
    return 0;
  };

  const getMax = (item: TokenData) => {
    return mode[item.token] === "deposit"
      ? tokenBalances[
          !item.isVault || item.isGated ? item.address : item.underlying
        ].toStringWithDecimals(item.underlyingDecimals)
      : trove.collaterals[item.address] === undefined &&
        mode[item.token] === "withdraw"
      ? (0).toString()
      : format(
          trove.collaterals[item.address]
            .mul(10 ** (18 - item.underlyingDecimals))
            .div(receiptPerUnderlyingRatios[item.address])
        );
  };

  const {
    isOpen: isConfirmDOpen,
    onOpen: onConfirmDOpen,
    onClose: onConfirmDClose,
  } = useDisclosure();

  return (
    <>
      <LeverUpModal
        isOpen={isLeverUpOpen}
        onClose={onLeverUpClose}
        collateral={leveredToken}
        type={borrowMode}
        values={values}
        setLeverSave={setLeverSave}
        depositFees={depositFees}
      />

      {/* <Box display="flex" flexDir="column" pt="16px" mb="24px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        ></Box>
        <Text textStyle="subheading" color="green.300">
          {" "}
          Maximum Borrow: {currVcValue / 1.1}
        </Text>
        <Box alignItems="center" pt="12px">
          <Progress
            value={(troveBalance / (currVcValue / 1.1)) * 100}
            // size="sm"
            // colorScheme={calculateHealthColor()}
            colorScheme="gray"
            height="4px"
            color="red.500"
            // colorScheme="gray"
            bg="surface-highlight"
            rounded="full"
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              textStyle="subheading"
              color="text-secondary"
              fontWeight="normal"
            >
              Borrow limit used:
              {((troveBalance / (currVcValue / 1.1)) * 100).toFixed(2)}%
            </Text>
          </Box>
        </Box>
      </Box> */}

      {/* <Box>
        <Text textStyle="subheading_display" color="text-primary">
          Supply Balance
        </Text>
        <Text textStyle="number_lg">
          {`$${getNum(currUSDValue * +String(1), 2)}`}
        </Text>
      </Box> */}

      {/* <Box>
        <CollateralChart />
      </Box> */}

      <Box
        // borderWidth="1px"
        // borderColor="border"
        // rounded="16px"
        // overflow="hidden"
        // p="24px"
        // mt="24px"
        layerStyle="card"
        bg="background"

        // borderTopLeftRadius="0px"
        // borderTopRightRadius="0px"
        // borderTop="1px"

        // w="full"
        // {...props}
        // // mb={8}
        // bg="surface"
        // borderTopRadius="0px"
        // borderBottomRadius="8px"
        // borderWidth="1px"
        // borderColor="border"
        // p="24px"
        // display="flex"
        // flexDir="column"
        // mb="16px"
        // shadow="shadow_sm"
      >
        {/* <Text textStyle="title4" color="white" px={6}>
          {trove.status === "open" ? "Adjust" : "Add"} Collateral
        </Text> */}
        <>
          {/* <Box display="flex">
            <Text textStyle="card_header" mb={4}>
              Your Collateral
            </Text>
          </Box> */}

          {/* <Box>
            <Text textStyle="subheading_display" color="text-primary">Supply Balance</Text>
            <Text textStyle="number_lg">
            {`$${getNum(currUSDValue * +String(1), 2)}`}
            </Text>
            </Box> */}

          {/* <Box>
            <Text>Collateral</Text>
            </Box> */}

          {/* <Box layerStyle="tableHead">
              <Text>Supply</Text>
            </Box> */}

          <Box
            layerStyle="tableHead"
            bg="surface"
            mt={isMobile ? "24px" : ""}
            borderTopRadius={isMobile ? "12px" : ""}
          >
            <Text>Collateral</Text>
            <Box display="flex">
              <Checkbox
                onChange={(e) => {
                  setShowZeroBalance(e.target.checked);
                }}
                size="md"
                style={{ outline: "none" }}
                defaultChecked={true}
              />
              <Text textStyle="text_sm" color="text-secondary" ml={3}>
                Show assets with 0 balance
              </Text>
            </Box>
          </Box>

          {isBlockedCountry && (
            <Box bg="surface" px="24px" pb="8px">
              <BlockedCard />
            </Box>
          )}

          <Box
            layerStyle="tableSub"
            justifyContent="space-between"
            bg="surface"
          >
            <Box display="flex">
              <Box display="flex">
                <Text>Asset (Hover for info)</Text>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              {/* <Box w="128px">
                <Text>APY</Text>
              </Box> */}
              <Hide below="sm">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mr={isMobile ? "24px" : "60px"}
                >
                  <Text>Rewards</Text>
                </Box>
              </Hide>
              <Box
                w="120px"
                display="flex"
                justifyContent={isMobile ? "flex-end" : ""}
              >
                <Text>Your Deposit</Text>
              </Box>

              {isMobile ? <></> : <Box w="200px" />}

              {/* <Box display="flex" w="240px">
                <Text>Action</Text>
              </Box> */}
            </Box>
            {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
          </Box>
          <TokenTable
            // headers={
            //   borrowMode !== "normal"
            //     ? [
            //         "Asset",
            //         "Safety Ratio",
            //         "Deposit Fee",
            //         "APY",
            //         "Amount",
            //         "Actions",
            //         "Lever Up",
            //       ]
            //     : [
            //         "Asset",
            //         "Safety Ratio",
            //         "Deposit Fee",
            //         "APY",
            //         "Amount",
            //         "Actions",
            //       ]
            // }
            // tooltips={
            //   borrowMode !== "normal"
            //     ? [
            //         "Name",
            //         "Weighting for risk adjusted value",
            //         "Deposit fees on deposited collaterals value are added to your YUSD debt amount.",
            //         "Estimated Annual Percentage Yield, including auto-compounding fees. Currently updated daily for AAVE tokens. (live update coming in the next 2 days)",
            //         "Final amount of the collateral after adjustments",
            //         "Deposit to add collateral to your trove. Withdraw to remove.",
            //         "Lever Up",
            //       ]
            //     : [
            //         "Name",
            //         "Weighting for risk adjusted value",
            //         "Deposit fees on deposited collaterals value are added to your YUSD debt amount.",
            //         "Estimated Annual Percentage Yield, including auto-compounding fees. Currently updated daily for AAVE tokens. (live update coming in the next 2 days)",
            //         "Final amount of the collateral after adjustments",
            //         "Deposit to add collateral to your trove. Withdraw to remove.",
            //       ]
            // }
            // width={borrowMode !== "normal" ? 7 : 6}
            borrow
          >
            <>
              {currentCollateral
                // .filter((token) => {
                //   if (borrowMode === "unlever") {
                //     if (token.troveBalance === 0) {
                //       return false;
                //     } else {
                //       return true;
                //     }
                //   }
                //   return true;
                // })
                .map((item) => (
                  // Table Row Container
                  <Box
                    layerStyle="tableRow"
                    h=""
                    key={item.token}
                    display="flex"
                    flexDir={isMobile ? "column" : "row"}
                    bg="surface"
                    mt={isMobile ? "8px" : ""}
                    rounded={isMobile ? "12px" : ""}
                    shadow={isMobile ? "sm" : ""}
                    // onClick={handleOpen}
                  >
                    {/* Asset container */}
                    <Box
                      display="flex"
                      alignItems="center"
                      h="80px"
                      w="full"
                      justifyContent="space-between"
                    >
                      <Popover
                        autoFocus={false}
                        closeOnEsc={true}
                        trigger="hover"
                        placement="top"
                        openDelay={0}
                        closeDelay={0}
                      >
                        <PopoverTrigger>
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={"/vault/" + [item.token]}
                            key={item.token}
                          >
                            <Box
                              _hover={{ bg: "surface-hover" }}
                              // onMouseEnter={() => setHover(true)}
                              // onMouseLeave={() => setHover(false)}
                              alignItems="center"
                              display="flex"
                              // w="210px"
                              w={item.token === "GLP" ? "100px" : "210px"}
                              cursor="pointer"
                              // _hover={{bg: "surface-hover" }}
                              // py="8px"
                              // borderRadius="16px"
                              overflow="hidden"
                              h="full"
                            >
                              <Icon
                                iconName={item.token}
                                boxSize={{
                                  base: "40px",
                                  sm: "40px",
                                  md: "40px",
                                }}
                              />
                              <Box
                                display="flex"
                                flexDir="column"
                                alignItems="flex-start"
                                ml="12px"
                                w="full"
                              >
                                <Text noOfLines={1} textStyle="assetName">
                                  {item.name}
                                </Text>
                                <Text textStyle="asset">{item.token}</Text>
                              </Box>
                            </Box>
                          </Link>
                        </PopoverTrigger>
                        <PopoverContent
                          border="1px"
                          borderColor="border"
                          borderRadius="12px"
                        >
                          <PopoverArrow />
                          <PopoverBody
                            layerStyle="card"
                            textStyle="number_base"
                            borderWidth="0px"
                            p="16px"
                            border="none"
                          >
                            <Box display="flex">
                              <Text fontSize="16px" fontWeight="700">
                                {item.name}
                              </Text>
                            </Box>
                            <Box
                              display="flex"
                              pt="16px"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Text>Rewards</Text>
                              <Text>
                                {(APYs[item.token] * 100).toFixed(3)}%{" "}
                                {item.feeTooltip !== "" && (
                                  <Tooltip>{item.feeTooltip}</Tooltip>
                                )}
                              </Text>
                            </Box>
                            <Box
                              display="flex"
                              pt="16px"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Text>Safety Ratio</Text>
                              <Text>
                                {format(safetyRatios[item.address]).toFixed(3)}{" "}
                              </Text>
                            </Box>

                            <Box
                              pt="16px"
                              display="flex"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Text>Deposit Fees</Text>
                              <Box display="flex">
                                {format(depositFees[item.address]) * 100 > 0 ? (
                                  <Text>
                                    {(
                                      format(depositFees[item.address]) * 100
                                    ).toFixed(3)}
                                    %{" "}
                                  </Text>
                                ) : (
                                  <Spinner size="xs" color="text-primary" />
                                )}
                              </Box>
                            </Box>
                            <Box
                              pt="16px"
                              display="flex"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Text>Interest Rate</Text>
                              <Box display="flex">
                                {getNum(
                                  format(interestRates[item.address]) *
                                    365 *
                                    100,
                                  3
                                )}{" "}
                                %
                              </Box>
                            </Box>

                            <Box
                              pt="16px"
                              display="flex"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Text>Wallet Balance</Text>
                              <Text>{getNum(item.walletBalance)}</Text>
                            </Box>
                            <Box
                              pt="16px"
                              display="flex"
                              w="full"
                              justifyContent="space-between"
                            >
                              <Text>Total Deposited</Text>
                              <Text>
                                $
                                {getNum(
                                  format(
                                    total.collaterals[item.address].mul(
                                      prices[item.address]
                                    )
                                  ),
                                  2
                                )}
                              </Text>
                            </Box>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>

                      {item.token === "GLP" ? (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={"/vault/" + [item.token]}
                          key={item.token}
                        >
                          <Box
                            bg="surface-highlight"
                            px="8px"
                            py="4px"
                            onClick={onOpen}
                            fontSize="13px"
                            display="flex"
                            alignItems="center"
                            gap="4px"
                            rounded="full"
                            cursor="pointer"
                            _hover={{ bg: "primary-default" }}
                          >
                            <Text color="text-primary">Mint GLP</Text>
                            <Tooltip color="text-secondary">
                              You must first deposit into the Yeti GLP vault
                              before you can deposit into your trove
                            </Tooltip>
                          </Box>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* apy container */}

                      {/* <Box display={isMobile ? "" : "flex"}> */}
                      <Hide below="sm">
                        <Box display="flex" justifyContent="flex-end">
                          <Text
                            fontSize={isMobile ? "12px" : ""}
                            bg={isMobile ? "surface-highlight" : ""}
                            px={isMobile ? "4px" : ""}
                            rounded="full"
                          >
                            {(APYs[item.token] * 100).toFixed(2)}%{" "}
                            {item.feeTooltip !== "" && (
                              <Tooltip>{item.feeTooltip}</Tooltip>
                            )}
                          </Text>
                        </Box>
                      </Hide>

                      {/* Your deposit values */}
                      <Box
                        w="120px"
                        display="flex"
                        justifyContent="flex-end"
                        pr={isMobile ? "0px" : "48px"}
                      >
                        <Box
                          display="flex"
                          flexDir="column"
                          alignItems="flex-end"
                        >
                          <Text alignItems="flex-end" whiteSpace="nowrap">
                            {getNum(
                              getTroveUnderlyingValueWithLever(
                                mode[item.token],
                                item,
                                values,
                                borrowMode,
                                underlyingPerReceiptRatios[item.address]
                              )
                            )}
                          </Text>
                          <CoinAmount
                            token={item.token}
                            noGetNum={true}
                            // noCurrencyConvert={true}
                            // firstNumber={true}
                            amount={getTroveUnderlyingValueWithLever(
                              mode[item.token],
                              item,
                              values,
                              borrowMode,
                              underlyingPerReceiptRatios[item.address]
                            )}
                            // noCurrencyConvert={true}
                            color="text-secondary"
                            fontWeight="500"
                          />
                        </Box>
                      </Box>
                      {/* </Box> */}
                    </Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      // w="194px"
                      w={isMobile ? "full" : "300px"}
                      justifyContent="flex-end"
                      h="80px"
                    >
                      {/* {item.token === "GLP" ? ( */}
                      <AdjustInput
                        name={item.token}
                        iconStatus={mode}
                        setIconStatus={setMode}
                        token={item.token}
                        id="testId"
                        max={getMax(item)}
                        showConnect
                        showCancel
                        min={0}
                        precision={5}
                        // inputWidth={32}
                        size="sm"
                        showIcons
                        values={checker(values)}
                        borrowMode={borrowMode}
                        isDeprecated={
                          item.isDeprecated != undefined
                            ? item.isDeprecated
                            : false
                        }
                        form={form}
                        walletBalance={item.walletBalance}
                        troveBalance={getTroveUnderlyingValueWithLever(
                          mode[item.token],
                          item,
                          values,
                          borrowMode,
                          underlyingPerReceiptRatios[item.address]
                        )}
                      />
                    </Box>
                    {/* </Box> */}
                    {borrowMode !== "normal" ? showLeverModal(item) : <></>}
                  </Box>
                ))}
            </>
            {/* <Tr key="total-usd">
              <Td py={3} borderColor="#313545">
                <Button
                  colorScheme="brand"
                  variant="newPrimary"
                  _active={{ bg: "transparent" }}
                  mt={2}
                  onClick={form.reset}
                  fontSize={14}
                >
                  Clear All
                </Button>
              </Td>
              <Td py={3}  />
              <Td py={3}  />
              <Td py={3} />
              <Td py={3}>
                <Text fontSize={14} textAlign="right">
                  USD Value:
                </Text>
              </Td>
              <Td py={3} borderColor="#313545" fontSize={14}>
                $
                {getNum(
                  currentCollateral
                    .map(
                      (item) =>
                        getTroveVaultValueWithLever(
                          mode[item.token],
                          item,
                          values,
                          borrowMode,
                          receiptPerUnderlyingRatios[item.address]
                        ) * format(prices[item.address])
                    )
                    .reduce((a, b) => a + b, 0)
                )}
              </Td>
            </Tr> */}
          </TokenTable>
        </>
      </Box>
      {/* <Divider color="border" /> */}
      {/* <AddCollateralTypeModal
        isOpen={isAddCollateralTypeOpen}
        onClose={onAddCollateralTypeClose}
        show={show}
        setShow={setShow}
        availableCollateral={availableCollateral}
        borrowMode={borrowMode}
      /> */}
    </>
  );
};

export default AdjustCollateral;
