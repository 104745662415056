import React, { ReactNode, useState } from "react";
import {
  Text,
  Box,
  Img,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorMode,
  Divider,
  chakra,
  VisuallyHidden,
  useClipboard,
  Tooltip as ChakraTooltip,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
// import { ChevronDownIcon } from "@chakra-ui/icons";
import { useWeb3React } from "@web3-react/core";
import makeBlockie from "ethereum-blockies-base64";

import { useLiquity } from "../../../Hooks/LiquityContext";
import { formatWalletAddress } from "./../../../Utils/string";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  CopyIcon,
  ExternalLinkIcon,
  MoonIcon,
  SunIcon,
} from "@chakra-ui/icons";
import { ConnectButton } from "../../ConnectButton";
import CoinAmount from "../../CoinAmount";
import { format, formatWithDecimals } from "../../../Utils/number";
import { useLiquitySelector } from "@yeti/lib-react";
import { LiquityStoreState } from "@yeti/lib-base";
import Icon from "../../Icon";
// import Link from "../Link";

const selector = ({
  yusdBalance,
  yetiBalance,
  veYETIStaked,
}: LiquityStoreState) => ({
  yusdBalance,
  yetiBalance,
  veYETIStaked,
});

type UserDetailsProps = {
  onChange: any;
  walletConnected: boolean;
};

const SocialButton = ({
  children,
  label,
  href,
  target,
}: {
  children: ReactNode;
  label: string;
  href: string;
  target: string;
}) => {
  return (
    <chakra.button
      bg="#272937"
      rounded="full"
      color="#8A93A6"
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target={target}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "#343647",
        color: "#DFE3E9",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const UserDetails: React.FC<UserDetailsProps> = ({
  onChange,
  walletConnected,
}) => {
  const { deactivate, connector } = useWeb3React<unknown>();
  const {
    liquity: {
      connection: { addresses },
    },
  } = useLiquity();
  const { account } = useLiquity();

  const { yusdBalance, yetiBalance, veYETIStaked } =
    useLiquitySelector(selector);

  const totalVeYeti: number = formatWithDecimals(veYETIStaked.veYETITotal, 36);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [copyValue, setCopyValue] = useState(account);
  const { hasCopied, onCopy } = useClipboard(copyValue);

  const addToken = (params: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const func = window.ethereum.request;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    func({ method: "wallet_watchAsset", params }) //@ts-ignore
      .then(() => setLog([...log, "Success, Token added!"]))
      .catch((error: Error) => setLog([...log, `Error: ${error.message}`]));
  };

  const [log, setLog] = useState<string[]>([]);

  const addYusdToken = () => {
    addToken({
      type: "ERC20",
      options: {
        address: addresses["yusdToken"],
        symbol: "YUSD",
        decimals: 18,
        image: "https://i.ibb.co/DCL8fhg/yusd-Token.png",
      },
    });
  };

  return (
    <Box zIndex="40" display="flex" alignItems="center" gap="8px">
      {/* *** WALLET COMPONENT *** */}
      {connector != undefined && walletConnected ? (
        <>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Box
                cursor="pointer"
                bg="#272937"
                px="6px"
                py="6px"
                rounded="8px"
                _hover={{ bg: "#343647 " }}
                alignItems="center"
                zIndex="50"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  transition="ease-out"
                  transitionDuration="200ms"
                >
                  <Box display="flex" alignItems="center">
                    <Img
                      src={makeBlockie(account)}
                      h="24px"
                      w="24px"
                      rounded="full"
                    />
                    <Text
                      fontSize="14px"
                      fontWeight="600"
                      ml="8px"
                      color="#EAEDF0"
                      _hover={{ color: "#EAEDF0" }}
                      transitionDuration="200ms"
                    >
                      {formatWalletAddress(account, 5, 4, 12)}
                    </Text>
                  </Box>
                  <ChevronDownIcon color="#8A93A6" />
                </Box>
              </Box>
            </PopoverTrigger>

            <PopoverContent
              w="280px"
              gap="8px"
              borderColor="#33374D"
              _focus={{ boxShadow: "none !important" }}
              p="8px"
              bg="#272937"
              shadow="lg"
            >
              {/* HEADER */}
              <Box display="flex" alignItems="center" gap="8px">
                <Img
                  src={makeBlockie(account)}
                  h="32px"
                  w="32px"
                  rounded="full"
                />
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  // bg={current ? "primary-default" : "surface"}
                  color="#EAEDF0"
                  _hover={{ color: "#EAEDF0" }}
                  transitionDuration="200ms"
                >
                  {formatWalletAddress(account, 9, 4, 16)}
                </Text>
              </Box>
              <Divider orientation="horizontal" color="#33374D" />

              {/* *** ASSET BALANCES SECTION *** */}
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  w="full"
                  color="#8A93A6"
                  px="6px"
                  mt="8px"
                >
                  <Text textStyle="subheading">Token</Text>
                  <Text textStyle="subheading">Balance</Text>
                </Box>
                <ChakraTooltip
                  color="#DFE3E9"
                  label={"Add YUSD token to Wallet"}
                  placement="left"
                  bg="#434658"
                >
                  <Box
                    p="6px"
                    rounded="8px"
                    cursor="pointer"
                    _hover={{ bg: "#343647" }}
                    display="flex"
                    justifyContent="space-between"
                    w="full"
                    alignItems="center"
                    onClick={addYusdToken}
                  >
                    <Box
                      display="flex"
                      textStyle="number_base"
                      color="#EAEDF0"
                      gap="6px"
                      alignItems="center"
                    >
                      <Icon iconName={"YUSD"} h="24px" w="24px" />
                      YUSD
                    </Box>
                    <CoinAmount
                      color="#EAEDF0"
                      token={"YUSD"}
                      amount={format(yusdBalance)}
                    />
                  </Box>
                </ChakraTooltip>
                <ChakraTooltip
                  color="#DFE3E9"
                  label={"Add YETI token to wallet"}
                  placement="left"
                  bg="#434658"
                >
                  <Box
                    p="6px"
                    rounded="8px"
                    cursor="pointer"
                    _hover={{ bg: "#343647" }}
                    display="flex"
                    justifyContent="space-between"
                    w="full"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      textStyle="number_base"
                      color="#EAEDF0"
                      gap="6px"
                      alignItems="center"
                    >
                      <Icon iconName={"YETI"} h="24px" w="24px" />
                      YETI
                    </Box>
                    <CoinAmount
                      color="#EAEDF0"
                      token={"YETI"}
                      amount={format(yetiBalance)}
                    />
                  </Box>
                </ChakraTooltip>
                <ChakraTooltip
                  color="#DFE3E9"
                  label={"Add veYETI token to wallet"}
                  placement="left"
                  bg="#434658"
                >
                  <Box
                    p="6px"
                    rounded="8px"
                    cursor="pointer"
                    _hover={{ bg: "#343647" }}
                    display="flex"
                    justifyContent="space-between"
                    w="full"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      textStyle="number_base"
                      color="#EAEDF0"
                      gap="6px"
                      alignItems="center"
                    >
                      <Icon iconName={"veYETI"} h="24px" w="24px" />
                      veYETI
                    </Box>
                    {/* <CoinAmount
                      color="#EAEDF0"
                      token={"veYETI"}
                      amount={format(totalVeYeti)}
                    /> */}
                  </Box>
                </ChakraTooltip>
              </Box>
              <Divider orientation="horizontal" color="#33374D" />
              <Box
                display="flex"
                w="full"
                justifyContent="space-between"
                fontSize="14px"
                color="#8A93A6"
                _hover={{ bg: "#343647", color: "#DFE3E9" }}
                alignItems="center"
                cursor="pointer"
                transitionDuration="200ms"
                p="6px"
                rounded="6px"
                onClick={toggleColorMode}
              >
                <Text>
                  {colorMode === "light" ? "Dark theme" : "Light theme"}
                </Text>
                {colorMode === "light" ? (
                  <MoonIcon boxSize="16px" />
                ) : (
                  <SunIcon boxSize="16px" />
                )}
              </Box>
              <a
                href={`https://snowtrace.io/address/${account}`}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  fontSize="14px"
                  color="#8A93A6"
                  _hover={{ bg: "#343647", color: "#DFE3E9" }}
                  alignItems="center"
                  cursor="pointer"
                  transitionDuration="200ms"
                  p="6px"
                  rounded="6px"
                >
                  <Text>View on explorer</Text>
                  <ExternalLinkIcon boxSize="14px" color="#8A93A6" />
                </Box>
              </a>
              <Box
                onClick={onCopy}
                display="flex"
                w="full"
                justifyContent="space-between"
                fontSize="14px"
                color="#8A93A6"
                _hover={{ bg: "#343647", color: "#DFE3E9" }}
                alignItems="center"
                cursor="pointer"
                transitionDuration="200ms"
                p="6px"
                rounded="6px"
              >
                <Text>{hasCopied ? "Copied" : "Copy address"}</Text>
                {hasCopied ? (
                  <CheckIcon boxSize="14px" color="green.400" />
                ) : (
                  <CopyIcon boxSize="14px" color="#8A93A6" />
                )}
              </Box>
              <Link to="/profile">
                <Box
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  fontSize="14px"
                  color="#8A93A6"
                  _hover={{ bg: "#343647", color: "#DFE3E9" }}
                  alignItems="center"
                  cursor="pointer"
                  transitionDuration="200ms"
                  p="6px"
                  rounded="6px"
                >
                  <Text>Transactions</Text>
                  <ChevronRightIcon boxSize="18px" color="#8A93A6" />
                </Box>
              </Link>
              <Divider orientation="horizontal" color="#33374D" />
              <Box
                display="flex"
                w="full"
                justifyContent="space-between"
                color="#8A93A6"
                _hover={{ bg: "#343647", color: "red.400" }}
                alignItems="center"
                cursor="pointer"
                transitionDuration="200ms"
                p="6px"
                rounded="6px"
                fontSize="14px"
                onClick={deactivate}
              >
                Disconnect
                <CloseIcon boxSize="10px" />
              </Box>
            </PopoverContent>
          </Popover>
        </>
      ) : (
        <ConnectButton />
      )}
    </Box>
  );
};

export default UserDetails;
