import { Box, Text } from "@chakra-ui/react";
import React from "react";
import PSMCard from "../../PageComponents/PSM/PSMCard";

export type PSMProps = {
  disconnected?: boolean;
};

const PSM: React.FC<PSMProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        h="358px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text color="#DFE3E9" textStyle="display_sm" textAlign="center">
        PSM
      </Text>
      <Text pt="8px" textAlign="center" color="text-secondary">
        Swap 1:1 between USDC and YUSD
      </Text>
      <Box display="flex" mt="48px" justifyContent="center">
        <PSMCard />
      </Box>
    </>
  );
};

export default PSM;
