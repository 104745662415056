import React, { useState, useEffect } from "react";
import {
  Box,
  BoxProps,
  Text,
  useMediaQuery,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
} from "@chakra-ui/react";
import { VC_explanation } from "../../../Utils/constants";
import { motion } from "framer-motion";
import {
  calculateHealth,
  calculateHealthStableTrove,
} from "./AdjustBorrowAmount.utils";
// import Icon from "../../../Components/Icon";
import TokenTable from "../../../Components/TokenTable";
import AdjustInput from "../../../Components/AdjustInput";
import { getNum, format, addString } from "../../../Utils/number";
import { AdjustedTokenData, CoinMode } from "../../../Types";
import { LiquityStoreState, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { Collateral } from "../../../Types";
import {
  calculateTotalYUSDFromLever,
  calculateVcValue,
} from "../AdjustTrove/AdjustTrove.utils";
import ConfirmCloseTroveModal from "../ConfirmCloseTroveModal";
// import { ConnectButton } from "../../../Components/WalletConnector";
import Tooltip from "../../../Components/Tooltip";
import CollateralItem from "../CollateralItem";
import { getChangedCollateral } from "../ConfirmChangesModal/ConfirmChangesModal.utils";
import { CoinAmount } from "../../../Components";
import { Icon } from "../../../Components";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { ConnectButton } from "../../../Components/ConnectButton";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
// import { BorrowSummary } from "../../Dashboard";
// import { calculateHealthColor } from "../Trove/Trove.utils";

type AdjustBorrowAmountProps = {
  values: { [key: string]: any };
  collateral: Collateral[];
  validateFunc: any;
  borrowFee: string;
  borrowMode: "normal" | "lever" | "unlever";
  leverSave: "saved" | "unsaved";
  depositFees: TroveMappings;
  mode: CoinMode;
  setMode: React.Dispatch<React.SetStateAction<CoinMode>>;
  adjustedCollateral: AdjustedTokenData[];
  vcValue: number;
  form: any;
  troveBalance: number;
  disconnected?: boolean;
  setBorrowMode: any;
  currVcValue: number;
  currUSDValue: number;
  stableVC: number;
} & BoxProps;

const selector = ({
  yusdBalance,
  trove,
  icr,
  underlyingPrices,
  borrowingRate,
  decimals,
  safetyRatios,
  underlyingPerReceiptRatios,
  receiptPerUnderlyingRatios,
  vcValue,
  pendingYUSDInterest,
  YUSDPrice,
}: LiquityStoreState) => ({
  yusdBalance,
  trove,
  icr,
  underlyingPrices,
  borrowingRate,
  decimals,
  safetyRatios,
  underlyingPerReceiptRatios,
  receiptPerUnderlyingRatios,
  vcValue,
  pendingYUSDInterest,
  YUSDPrice,
});

const dataSelector = useLiquitySelector;

const AdjustBorrowAmount: React.FC<AdjustBorrowAmountProps> = ({
  values,
  collateral,
  validateFunc,
  borrowFee,
  borrowMode,
  leverSave,
  depositFees,
  disconnected,
  currUSDValue,
  adjustedCollateral,
  mode,
  stableVC,
  setMode,
  currVcValue,
  form,
  troveBalance,
  ...props
}) => {
  const {
    yusdBalance,
    underlyingPrices,
    borrowingRate,
    decimals,
    safetyRatios,
    underlyingPerReceiptRatios,
    receiptPerUnderlyingRatios,
    pendingYUSDInterest,
    YUSDPrice,
  } = useLiquitySelector(selector);
  const [changes, setChanges] = useState<boolean>(false);

  const { trove, icr, vcValue } = dataSelector(selector);

  const healthRatio = format(icr) * 100;

  const troveHealth =
    stableVC * 1.1 > troveBalance && stableVC / format(vcValue) > 0.99
      ? calculateHealthStableTrove(healthRatio)
      : calculateHealth(healthRatio);

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const walletBalance = +yusdBalance;

  // console.log("111", troveBalance)
  const borrowMessage = "Final amount of borrowed YUSD after adjustments.";

  const {
    isOpen: isCloseTroveOpen,
    onOpen: onCloseTroveOpen,
    onClose: onCloseTroveClose,
  } = useDisclosure();

  // const calculateMaxDebt = () => {
  //   let tempTroveBalancePost = 0;
  //   if (mode["YUSD"] === "deposit" && values["YUSD"] > 0) {
  //     let YUSDBorrowFee = values["YUSD"] * parseFloat(borrowingRate.toString());
  //     tempTroveBalancePost = tempTroveBalancePost + YUSDBorrowFee;
  //   }
  //   let x = getFeesCollateral(getAffectedCollateral(values), underlyingPrices, depositFees, values);
  //   tempTroveBalancePost = tempTroveBalancePost + x;
  //   // If first time borrow add 200 to this value
  //   if (trove && trove.status !== "open") {
  //     borrowMessage = borrowMessage.concat(
  //       " 200 YUSD is added here for Gas compensation in the case of liquidations. " +
  //         "It will be returned when the trove is closed."
  //     );
  //     tempTroveBalancePost = addString(tempTroveBalancePost, "200");
  //   }
  //   const tempTotalYUSDFromLever = calculateTotalYUSDFromLever(
  //     getAffectedCollateral(values),
  //     underlyingPrices,
  //     values
  //   );
  //   if (tempTotalYUSDFromLever && borrowMode === "lever") {
  //     let YUSDBorrowFee = +String(tempTotalYUSDFromLever) * parseFloat(borrowingRate.toString());
  //     // console.log('YUSDBorrowFee', YUSDBorrowFee)
  //     tempTroveBalancePost = tempTroveBalancePost + YUSDBorrowFee;
  //   }
  //   setTotalYUSDFromLever(tempTotalYUSDFromLever);
  //   if (borrowMode === "lever") {
  //     tempTroveBalancePost = addString(tempTroveBalancePost, tempTotalYUSDFromLever.toString());
  //   } else if (borrowMode === "unlever") {
  //     tempTroveBalancePost = tempTroveBalancePost;
  //     // console.log("tempTroveBalancePost", tempTroveBalancePost)
  //   }
  //   console.log('1', (((vcValue * 100) / 110)))
  //   console.log('2', tempTroveBalancePost)
  //   console.log('3', adjustValue(mode["YUSD"], troveBalance, values["YUSD"]))
  //   console.log('parseFloat(borrowingRate.toString()))', (((vcValue) / 1.1 * (1- parseFloat(borrowingRate.toString())))))

  //   tempTroveBalancePost = ((((vcValue) / 1.1)) - adjustValue(mode["YUSD"], troveBalance, values["YUSD"]) - adjustValue(mode["YUSD"], troveBalance, values["YUSD"]) * parseFloat(borrowingRate.toString()))/(1+parseFloat(borrowingRate.toString()))

  //   console.log('tempTroveBalancePost', tempTroveBalancePost)
  //   // console.log('troveBalance', troveBalance)
  // };

  const [totalYUSDFromLever, setTotalYUSDFromLever] = useState(
    calculateTotalYUSDFromLever(
      adjustedCollateral,
      underlyingPrices,
      values,
      safetyRatios
    )
  );

  // useEffect(() => {
  //   if (borrowMode !== "unlever") {
  //     coins["YUSD"] = "deposit";
  //   } else {
  //     coins["YUSD"] = "withdraw";
  //   }
  //   setMode(coins);
  // }, [borrowMode]);
  useEffect(() => {
    let changed = false;
    Object.keys(values).map((collateral) => {
      if (!collateral.includes("mode") && values[collateral] != 0) {
        setChanges(true);
        changed = true;
      }
    });
    if (!changed) {
      setChanges(false);
    }
  }, [values, leverSave]);

  const getMaxBorrow = () => {
    const [vcValue] = calculateVcValue(
      "lever",
      adjustedCollateral,
      underlyingPrices,
      values,
      safetyRatios,
      receiptPerUnderlyingRatios
    );
    const maxAmount = vcValue / 1.1 - troveBalance; // TODO : Rounding error?
    if (maxAmount < 0) {
      return 0;
    }
    return maxAmount;
  };

  // console.log("VC VALUE:", vcValue);
  // console.log("vcValue", vcValue)
  // console.log("troveBalance", troveBalance)

  const [sliderValue, setSliderValue] = useState(0);
  // console.log('sliderValue', sliderValue)
  const tableHeaderLeverage =
    borrowMode === "unlever"
      ? "YUSD From Deleverage"
      : "New Borrow Amount from Leverage";
  const tableTooltipLeverage =
    borrowMode === "unlever"
      ? "Total YUSD Received in your wallet by auto-selling collateral from your trove. This can be used automatically to repay your debt in the box to the right"
      : "Total YUSD being borrowed from Leverage. For each collateral based on the leverage, a certain amount of YUSD is taken out as debt in total";

  const changedCollateral = getChangedCollateral(
    collateral,
    values,
    depositFees,
    underlyingPerReceiptRatios
  );

  const depositedCollateral = changedCollateral.filter(
    (collateral) => collateral.mode === "deposit"
  );
  const withdrawnCollateral = changedCollateral.filter(
    (collateral) => collateral.mode === "withdraw"
  );

  const getVC = (amount: number | undefined, safetyRatio: number) => {
    if (amount) {
      return amount * safetyRatio;
    }
    return 0;
  };

  let newBorrowAmount = addString(0, values["YUSD"]);
  // console.log('values confirm', values)
  if (newBorrowAmount < 0) {
    newBorrowAmount = 0;
  }

  const [showResults, setShowResults] = React.useState(false);

  const Results = () => <Box>Some Results</Box>;

  const yusdBorrowRate = +borrowingRate;

  // const [maxSliderValue, setMaxSliderValue] = React.useState(5);
  // const [showTooltip, setShowTooltip] = React.useState(false);

  // const [sliderValue2, setSliderValue2] = useState(50);

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  const borrowFees =
    values["YUSDmode"] === "deposit" ? newBorrowAmount * yusdBorrowRate : 0;

  // const getMaxBorrow = () => {
  //   const [vcValue] = calculateVcValue(
  //     "lever",
  //     adjustedCollateral,
  //     underlyingPrices,
  //     values,
  //     safetyRatios,
  //     receiptPerUnderlyingRatios
  //   );
  //   const maxAmount = vcValue / 1.1 - troveBalance; // TODO : Rounding error?
  //   if (maxAmount < 0) {
  //     return 0;
  //   }
  //   return maxAmount;
  // };

  const collateralRatio = (currVcValue * 100) / troveBalance;

  const progressValue = 100 - 110 / collateralRatio;

  const maxBorrow = currVcValue / 1.1 - troveBalance;

  const calcMaxBorrow = () => {
    const maxBor = currVcValue / 1.1 - troveBalance;
    if (maxBor < 0) {
      return 0;
    }
    return maxBor;
  };

  const maxBor = calcMaxBorrow();

  const safeMaxBorrow = currVcValue / 1.2 - troveBalance;

  // const [count, setCount] = useState(maxBorrow);

  // useEffect(() => {
  //   console.log("Count is now: ", maxBorrow);
  // }, [maxBorrow]);

  // const handleClick = () => {
  //   setCount(maxBorrow);
  // };

  const calculateHealthColor = (troveHealth: number): string => {
    // const health = calculateHealth(troveHealth);
    if (troveHealth < 30) {
      return "red";
    } else if (troveHealth < 65) {
      return "yellow";
    } else {
      return "green";
    }
  };

  const { connected } = useWeb3Context();

  // console.log(troveHealth);
  console.log(collateralRatio);
  // console.log(progressValue);

  return (
    <>
      <Box
        // w="380px"
        {...props}
        // layerStyle="card"
        // borderBottomRightRadius="0px"
        // borderBottomLeftRadius="0px"
        position="sticky"
        top="100px"
        // w="360px"
        w={{ sm: "full", md: "360px" }}
        // p="24px"
        textStyle="number_base"
        color="text-primary"
      >
        {showResults ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
          >
            <Box
              layerStyle="card"
              zIndex="40"
              h="500px"
              alignItems="center"
              justifyContent="center"
              p="24px"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <ArrowBackIcon
                  color="text-primary"
                  _hover={{ color: "primary-default" }}
                  w="24px"
                  cursor="pointer"
                  h="24px"
                  left="24px"
                  position="absolute"
                  onClick={() => setShowResults(false)}
                />
                <Text textStyle="card_header">Order Summary</Text>
              </Box>
            </Box>
          </motion.div>
        ) : (
          <Box>
            <Box layerStyle="card">
              <Box layerStyle="tableHead">
                <Text textStyle="card_header">Borrow Summary</Text>

                <Menu>
                  <MenuButton>
                    <Box
                      display="flex"
                      gap="4px"
                      w="24px"
                      h="24px"
                      justifyContent="center"
                      alignItems="center"
                      rounded="full"
                      _hover={{ bg: "surface-highlight" }}
                      transitionDuration="200ms"
                      cursor="pointer"
                    >
                      <Box h="4px" w="4px" bg="text-primary" rounded="full" />
                      <Box h="4px" w="4px" bg="text-primary" rounded="full" />
                      <Box h="4px" w="4px" bg="text-primary" rounded="full" />
                    </Box>
                  </MenuButton>
                  <MenuList
                    bg="surface"
                    py="4px"
                    px="4px"
                    borderWidth="none"
                    display="flex"
                  >
                    <MenuItem px="4px" display="flex">
                      <Button
                        fontSize="13px"
                        rounded="14px"
                        color="red.400"
                        fontWeight="600"
                        disabled={trove.status !== "open" && true}
                        cursor="pointer"
                        variant="text"
                        onClick={onCloseTroveOpen}
                      >
                        Close Trove
                      </Button>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              <Box
                // layerStyle="tableRow"
                px="16px"
                borderTop="0px"
                display="flex"
                flexDir="column"
                h="full"
              >
                {/* SLIDER
                <Box pt={6} pb={2} display="flex" w="300px">
                  <Slider
                    aria-label="slider-ex-6"
                    onChange={(val) => setSliderValue2(val)}
                    max={currVcValue / 1.1 - troveBalance + borrowFees}
                  >
                    <SliderMark
                      value={sliderValue2}
                      textAlign="center"
                      bg="blue.500"
                      color="white"
                      mt="-10"
                      ml="-5"
                      w="12"
                    >
                      {sliderValue2} =
                      {sliderValue2 /
                        (currVcValue / 1.1 - troveBalance + borrowFees)}
                    </SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box> */}

                {/* <Box display="flex" flexDir="column" pt="16px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      textStyle="subheading"
                      color="text-secondary"
                      fontWeight="normal"
                    >
                      Borrow limit used
                    </Text>
                    <Text>
                      {(
                        (troveBalance / (getMaxBorrow() + troveBalance)) *
                        100
                      ).toFixed(2)}
                      %
                    </Text>
                  </Box>
                  <Box alignItems="center" pt="12px">
                    <Progress
                      value={
                        (troveBalance / (getMaxBorrow() + troveBalance)) * 100
                      }
                      colorScheme={calculateHealthColor(troveHealth)}
                      bg="surface-highlight"
                    />
                    <Text textStyle="card_text">
                      Borrow Limit:
                      {(getMaxBorrow() + troveBalance).toFixed(2)}
                    </Text>
                  </Box>
                  <Text
                    textStyle="text_xs"
                    color="text-secondary"
                    fontWeight="normal"
                    pt="4px"
                  >
                    110% MIN
                  </Text>
                </Box> */}

                {/* <Text textStyle="card_header">Position Summary</Text> */}

                <Box
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Box
                    display="flex"
                    flexDir="column"
                    // alignItems="center"
                    // bg="surface-highlight"
                    p="8px"
                    rounded="8px"
                    w="full"
                  >
                    <Box display="flex" gap="8px" alignItems="center">
                      <Text textStyle="subheading" color="text-secondary">
                        Risk Adjusted Value <Tooltip>{VC_explanation}</Tooltip>
                      </Text>
                      {/* </Text>
                    <Text textStyle="subheading" color="text-secondary">
                      Risk Adjusted <Tooltip>{VC_explanation}</Tooltip>
                    </Text> */}
                    </Box>
                    <Text
                      fontSize="20px"
                      fontWeight="semibold"
                      letterSpacing="tighter"
                    >
                      {/* {`$${getNum(currUSDValue * +String(1), 2)}`} */}
                      {`$${getNum(currVcValue, 2)}`}
                    </Text>
                  </Box>

                  {/* <Divider my="16px" color="border" /> */}

                  <Box
                    display="flex"
                    flexDir="column"
                    // bg="surface-highlight"
                    p="8px"
                    rounded="8px"
                    w="full"
                    // alignItems="center"
                  >
                    <Text textStyle="subheading" color="text-secondary">
                      Borrow Balance
                    </Text>

                    <Box display="flex" flexDir="column">
                      <Box display="flex" alignItems="center" gap="6px">
                        {connected ? (
                          <>
                            <Icon iconName="YUSD" width="18px" height="24px" />
                            {troveBalance === 200 ? (
                              <Text
                                fontSize="20px"
                                fontWeight="semibold"
                                letterSpacing="tighter"
                              >
                                0.00
                              </Text>
                            ) : (
                              <Text
                                fontSize="20px"
                                fontWeight="semibold"
                                letterSpacing="tighter"
                              >
                                {getNum(troveBalance)}
                              </Text>
                            )}
                          </>
                        ) : (
                          <>
                            <Text
                              fontSize="20px"
                              fontWeight="semibold"
                              letterSpacing="tighter"
                            >
                              0.00
                            </Text>
                          </>
                        )}
                        {/* <Text textStyle="text_sm" color="text-secondary" ml={1.5}>
                        ≈ ${(troveBalance * +String(YUSDPrice)).toFixed(3)}
                      </Text> */}
                      </Box>
                      {/* {connected ? (
                      <Text fontSize="14px" color="text-secondary">
                        {getNum(maxBorrow)} Available
                      </Text>
                    ) : (
                      <Text fontSize="14px" color="text-secondary">
                        0.00 Available
                      </Text>
                    )} */}
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDir="column" mt="24px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="13px" color="text-secondary">
                      Collateral Ratio (110% Min){" "}
                      <Tooltip>
                        Ratio of risk adjusted value in trove to debt
                      </Tooltip>
                    </Text>
                    <Text textStyle="card_text">
                      {isNaN((currVcValue * 100) / troveBalance)
                        ? "0%"
                        : `${((currVcValue * 100) / troveBalance).toFixed(3)}%`}
                    </Text>
                  </Box>
                  <Box alignItems="center" pt="6px">
                    <Progress
                      value={collateralRatio}
                      size="sm"
                      h="6px"
                      rounded="full"
                      w="100%"
                      // colorScheme={calculateHealthColor(troveHealth)}
                      colorScheme={(() => {
                        if (collateralRatio < 111) {
                          return "red";
                        } else if (collateralRatio < 113) {
                          return "yellow";
                        } else {
                          return "green";
                        }
                      })()}
                      // colorScheme={troveHealth < 70 ? "red" : "green"}
                      bg="surface-highlight"
                    />
                  </Box>
                  {/* <Text
                    textStyle="text_xs"
                    color="text-secondary"
                    fontWeight="normal"
                    pt="4px"
                  >
                    110% MIN
                  </Text> */}
                </Box>

                {/* <Box
                  mt="4px"
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="12.5px" color="text-secondary">
                    Available to Borrow
                  </Text>
                  {connected ? (
                    <Text fontSize="12.5px" color="text-secondary">
                      {getNum(maxBorrow)}
                    </Text>
                  ) : (
                    <Text fontSize="12.5px" color="text-secondary">
                      0.00
                    </Text>
                  )}
                </Box> */}
                {/* <Box
                  mt="4px"
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="12.5px" color="text-secondary">
                    Borrow Capacity
                  </Text>
                  <Text fontSize="12.5px" color="text-secondary">
                    ${getNum(currVcValue / 1.1)}
                  </Text>
                </Box> */}
                {/* <Divider my="16px" color="border" /> */}
                <Box
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="24px"
                >
                  <Text textStyle="number_base" color="text-primary">
                    Interest Rate
                  </Text>
                  <Text textStyle="number_base" color="text-primary">
                    0.5%
                  </Text>
                </Box>
                <Box
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="12.5px" color="text-secondary">
                    Interest accrued
                  </Text>
                  <Text fontSize="12.5px" color="text-secondary">
                    $ {getNum(format(pendingYUSDInterest), 3)}
                  </Text>
                </Box>
                {/* <Box
                  display="flex"
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="16px"
                >
                  <Text textStyle="number_base" color="text-primary">
                    YUSD Price
                  </Text>
                  <Text textStyle="number_base" color="text-primary">
                    {` ≈ $${getNum(+String(YUSDPrice), 3)}`}
                  </Text>
                </Box> */}

                {/* <Text>{borrowFees}</Text> */}

                {/* <Divider color="border" /> */}

                <>
                  {/* <Box
                    display="flex"
                    pt="24px"
                    w="full"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box display="flex" alignItems="center">
                      <Text textStyle="number_base" color="text-primary">
                        Collateral Ratio
                      </Text>
                      <Tooltip>
                        Ratio of Risk Adjusted Value to YUSD Debt. You must
                        maintain a minimum collateral ratio of 110% or you risk
                        liquidation.
                      </Tooltip>
                    </Box>
                    <Box display="flex" flexDir="column" alignItems="flex-end">
                      <Text textStyle="number_bold">
                        {isNaN((currVcValue * 100) / troveBalance)
                          ? "0%"
                          : `${((currVcValue * 100) / troveBalance).toFixed(
                              2
                            )}%`}
                      </Text>
                    </Box>
                  </Box>
                  <Box mt="24px">
                    <BorrowSummary />
                  </Box> */}

                  <Box
                    display="flex"
                    w="full"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontSize="12.5px" color="text-secondary">
                      Borrow Fee:
                    </Text>
                    <Text fontSize="12.5px" color="text-secondary">
                      {borrowFee}%
                    </Text>
                  </Box>

                  {/* <Box display="flex" flexDir="column" pt="24px" w="full">
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center" gap="2px">
                        <Text
                          fontSize="12px"
                          fontWeight="500"
                          color="text-secondary"
                        >
                          Liquidation risk
                        </Text>
                        <Tooltip>
                          Liquidation risk is the ratio of your Borrow Balance
                          to your Borrow Cap. At 100%, your Position may be
                          liquidated. Keep your Borrow risk below 100% to avoid
                          liquidations.
                        </Tooltip>
                      </Box>
                      <Text
                        fontSize="12px"
                        fontWeight="500"
                        color="text-primary"
                      >
                        {((troveBalance / (currVcValue / 1.1)) * 100).toFixed(
                          2
                        )}
                        %
                      </Text>
                    </Box>
                    <Box display="flex" flexDir="column" w="full" mt="2px">
                      <Progress
                        value={(troveBalance / (currVcValue / 1.1)) * 100}
                        // size="sm"
                        // colorScheme={calculateHealthColor()}
                        colorScheme="messenger"
                        height="4px"
                        color="red.500"
                        // colorScheme="gray"
                        bg="surface-highlight"
                        rounded="full"
                      />
                    </Box>
                  </Box> */}
                </>
              </Box>

              <TokenTable>
                <Box display="flex" w="full" justifyContent="center" p="32px">
                  <Box display="flex" alignItems="center" w="full">
                    {/* <Box
                display="flex"
                alignItems="center"
                h="80px"
                w="full"
                justifyContent="space-between"
              > */}
                    {/* <Box display="flex">
                  <Flex align="center">
                    <Icon iconName="YUSD" h="32px" w="32px" />
                    <Text ml={3}>YUSD</Text>
                  </Flex>
                </Box> */}

                    {/* <Box display="flex">{getNum(troveBalance)}</Box> */}

                    {/* <Hide below="md"> */}
                    {/* <Box border="none" display="flex">
                  <Text color="text-primary">{getNum(walletBalance)}</Text>
                </Box> */}
                    {/* </Hide> */}
                    {/* </Box> */}

                    <Box
                      display="flex"
                      flexDir="column"
                      w="full"
                      h="56px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {/* <Text
                    fontSize={14}
                    color={mode["YUSD"] === "withdraw" ? "#DC444D" : "#34CE85"}
                    mr={2}
                  >
                    {mode["YUSD"] === "withdraw" ? "Repay" : "Borrow"}
                  </Text> */}
                      {/* <Text color="white" textStyle="inherit">
                {getNum(walletBalance)}
              </Text> */}
                      <AdjustInput
                        name="YUSD"
                        iconStatus={mode}
                        setIconStatus={setMode}
                        token="YUSD"
                        showCancel
                        // max={
                        //   mode["YUSD"] === "deposit" ? maxBorrow : walletBalance
                        // }
                        max={
                          mode["YUSD"] === "deposit" ? undefined : walletBalance
                        }
                        // max={undefined}
                        min={0}
                        walletBalance={walletBalance}
                        values={values}
                        // walletBalance= { mode["YUSD"] === "deposit" ? troveBalance : walletBalance }
                        troveBalance={maxBor}
                        // troveBalance= { mode["YUSD"] === "deposit" ? maxBorrow : walletBalance }
                        precision={5}
                        inputWidth={32}
                        // size="sm"
                        showToken
                        showIcons
                        isYUSDDebt={true}
                        borrowMode={borrowMode}
                        form={form}
                      />
                    </Box>

                    {/* <Slider defaultValue={0} min={0} max={(((vcValue * 100) / 110))} step={1} w="275px" onChange={(val) => setSliderValue(val)} mt={4} ml={20}>
                <SliderMark
                  textStyle="subtitle2"
                  value={sliderValue}
                  fontWeight="bold"
                  textAlign='center'
                  color='white'
                  mt='-9'
                  ml='-5'
                  w='12'
                  >
                    
                  </SliderMark>
                  <SliderTrack bg='#4B97FF'>
                    <SliderFilledTrack bg='#227DF7' />
                  </SliderTrack>
                  <SliderThumb boxSize={3}>
                  </SliderThumb>
                </Slider> */}
                  </Box>
                  {/* <Tr key="total-usd">
            <Td py={2} borderColor="#313545" />
            <Td py={2} borderColor="#313545" />
            <Td py={2} borderColor="#313545" />
            <Td py={4} borderColor="#313545">
              <Text fontSize={14} textAlign="left">
                YUSD Borrow Fee: {borrowFee}%
              </Text>
              <Text fontSize={14} textAlign="left">
                YUSD Borrow Fee: {borrowFee}%
              </Text>
            </Td>
          </Tr> */}
                </Box>
              </TokenTable>
            </Box>

            {isMobile ? (
              <Box
                position="fixed"
                bottom="0px"
                left="0px"
                right="0px"
                mx="16px"
                mb="16px"
                zIndex="900"
              >
                {disconnected ? (
                  <ConnectButton w="100%" />
                ) : (
                  <>
                    {withdrawnCollateral.length === 0 &&
                    depositedCollateral.length === 0 &&
                    newBorrowAmount === 0 ? (
                      <></>
                    ) : (
                      <Button
                        position="fixed"
                        bottom="24px"
                        left="16px"
                        right="16px"
                        zIndex="900"
                        variant="newPrimary"
                        disabled={
                          format(vcValue) === currVcValue &&
                          true &&
                          newBorrowAmount === 0
                        }
                        onClick={() =>
                          validateFunc(
                            values,
                            ((currVcValue * 100) / troveBalance).toFixed(3),
                            troveBalance
                          )
                        }
                      >
                        Review Order
                      </Button>
                    )}
                  </>
                )}
              </Box>
            ) : (
              <Box layerStyle="card" mt="16px" px="16px">
                {newBorrowAmount !== 0 ||
                depositedCollateral.length !== 0 ||
                withdrawnCollateral.length !== 0 ? (
                  <Box
                    pt="16px"
                    display="flex"
                    justifyContent="space-between"
                    textStyle="subheading"
                    color="text-secondary"
                    pb="8px"
                  >
                    <Box display="flex">
                      <Text w="110px">Order</Text>
                      <Text>Asset</Text>
                    </Box>
                    <Text>Amount</Text>
                  </Box>
                ) : (
                  <></>
                )}
                {newBorrowAmount !== 0 && (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      mt="4px"
                      justifyContent="space-between"
                      pt="6px"
                      borderTop="1px"
                      borderColor="border"
                    >
                      <Box w="160px" display="flex">
                        <Text
                          px="6px"
                          py="1px"
                          color="text-primary"
                          fontSize="12px"
                          fontWeight="500"
                          letterSpacing="tight"
                          rounded="4px"
                          bg="primary-default"
                        >
                          {borrowMode !== "normal"
                            ? `${
                                borrowMode === "lever"
                                  ? "Additional Borrowed Amount:"
                                  : "Total Debt Repaid:"
                              } `
                            : mode["YUSD"] === "withdraw"
                            ? "Repay"
                            : "Borrow"}
                        </Text>
                        <Box ml="58px" display="flex" alignItems="center">
                          <Icon iconName={"YUSD"} h="18px" w="18px" />
                          <Text ml="4px">YUSD</Text>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <CoinAmount
                          token="YUSD"
                          amount={newBorrowAmount}
                          noCurrencyConvert={true}
                        />
                        <CoinAmount
                          token="YUSD"
                          noGetNum={true}
                          // noCurrencyConvert={true}
                          // firstNumber={true}
                          amount={newBorrowAmount}
                          // noCurrencyConvert={true}
                          fontSize="13px"
                          color="text-secondary"
                          fontWeight="500"
                        />
                      </Box>
                    </Box>
                  </>
                )}

                {depositedCollateral.length !== 0 && (
                  //    <Box
                  //    pt="16px"
                  //    display="flex"
                  //    justifyContent="space-between"
                  //    textStyle="subheading"
                  //    color="text-secondary"
                  //  >
                  //    <Box display="flex">
                  //      <Text w="160px">Asset</Text>
                  //      <Text>Order</Text>
                  //    </Box>
                  //    <Text>Amount</Text>
                  //  </Box>
                  <>
                    {/* <Text textStyle="subheading" color="green.400">
                      Deposit
                    </Text> */}
                    <Box
                    // overflowY={
                    //   changedCollateral.length > 2 ? "scroll" : undefined
                    // }
                    // maxHeight="20rem"
                    // sx={yeti.scrollbarDashboard}
                    >
                      {depositedCollateral.map(
                        ({
                          address,
                          token,
                          change,
                          fee,
                          feePercentage,
                          yusdFromLever,
                          changeWithoutLever,
                          leverage,
                        }) => (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              mt="4px"
                              justifyContent="space-between"
                              pt="6px"
                              borderTop="1px"
                              borderColor="border"
                            >
                              {/* <SmallAddIcon
                            color="green.400"
                            display="flex" 
                            mr="4px"
                          /> */}
                              {/* <Box bg="green.400" px="8px" rounded="4px" mr="8px">
                            <Text fontSize="12px" fontWeight="700" color="white">Supply</Text>
                          </Box> */}
                              <Box w="160px" display="flex">
                                <Text
                                  px="6px"
                                  py="1px"
                                  color="text-primary"
                                  fontSize="12px"
                                  fontWeight="500"
                                  letterSpacing="tight"
                                  rounded="4px"
                                  bg="success"
                                >
                                  Deposit
                                </Text>
                              </Box>
                              <CollateralItem
                                token={token}
                                amount={change}
                                yusdFromLever={yusdFromLever}
                                changeWithoutLever={changeWithoutLever}
                                leverage={leverage}
                                ratio={format(safetyRatios[address])}
                                fee={getVC(fee, format(safetyRatios[address]))} // assumes fee is = percentage fee * dollar value of collateral
                                feePercentage={feePercentage}
                                // pr={changedCollateral.length > 2 ? 1.5 : 0}
                                key={token}
                              />
                            </Box>
                            {/* <Box justifyContent="flex-end" display="flex">
                              <CoinAmount
                                token={token}
                                noGetNum={true}
                                // noCurrencyConvert={true}
                                // firstNumber={true}
                                amount={change}
                                // noCurrencyConvert={true}
                                fontSize="13px"
                                color="text-secondary"
                                fontWeight="500"
                              />
                            </Box> */}
                          </>
                        )
                      )}
                    </Box>
                    {/* <Divider color="border" mt={5} /> */}
                  </>
                )}

                {withdrawnCollateral.length !== 0 && (
                  <>
                    {withdrawnCollateral.map(
                      ({ address, token, change, yusdFromLever }) => (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="4px"
                            justifyContent="space-between"
                            pt="6px"
                            borderTop="1px"
                            borderColor="border"
                          >
                            {/* <Box bg="red.400" px="8px" rounded="4px" mr="8px">
                            <Text fontSize="12px" fontWeight="700" color="white">Withdraw</Text>
                          </Box> */}
                            <Box w="160px" display="flex">
                              <Text
                                px="6px"
                                py="1px"
                                color="text-primary"
                                fontSize="12px"
                                fontWeight="500"
                                letterSpacing="tight"
                                rounded="4px"
                                bg="error"
                              >
                                Withdraw
                              </Text>
                            </Box>
                            <CollateralItem
                              token={token}
                              amount={change}
                              ratio={format(safetyRatios[address])}
                              yusdFromLever={yusdFromLever}
                              // pr={changedCollateral.length > 2 ? 1.5 : 0}
                              key={token}
                            />
                          </Box>
                          {/* <Box justifyContent="flex-end" display="flex">
                            <CoinAmount
                              token={token}
                              noGetNum={true}
                              // noCurrencyConvert={true}
                              // firstNumber={true}
                              amount={change}
                              // noCurrencyConvert={true}
                              fontSize="13px"
                              color="text-secondary"
                              fontWeight="500"
                            />
                          </Box> */}
                        </>
                      )
                    )}
                  </>
                )}

                <Box display="flex">
                  {disconnected ? (
                    <ConnectButton w="100%" />
                  ) : (
                    <>
                      {withdrawnCollateral.length === 0 &&
                      depositedCollateral.length === 0 &&
                      newBorrowAmount === 0 ? (
                        <></>
                      ) : (
                        <Box py="24px" display="flex" w="full" flexDir="column">
                          <Button
                            w="100%"
                            variant="newPrimary"
                            disabled={
                              format(vcValue) === currVcValue &&
                              true &&
                              newBorrowAmount === 0
                            }
                            onClick={() =>
                              validateFunc(
                                values,
                                ((currVcValue * 100) / troveBalance).toFixed(3),
                                troveBalance
                              )
                            }
                          >
                            Review Order
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            )}

            {/* <Box direction="row" justifyContent="space-between" pt="24px">
            <Text>
              Safety Rating{" "}
              <Tooltip>
                Score from 0 to 100 that helps trove owners to understand how
                safe from liquidation their trove is. Learn more from docs.
              </Tooltip>
            </Text>
            <Text color="text-primary" fontWeight="700">
              {troveHealth.toFixed(3)}
            </Text>
          </Box> */}

            {/* Supply Balance */}

            <ConfirmCloseTroveModal
              isOpen={isCloseTroveOpen}
              onClose={onCloseTroveClose}
            />
          </Box>
        )}
      </Box>

      {/* <Text textStyle="title3">Your Portfolio</Text>
      {format(vcValue) != currVcValue && (
        <Text textStyle="subtitle2" fontWeight="normal" color="#6079FF">
          (with changes)
        </Text>
      )} */}

      {/* <Box display="flex" alignItems="center" pb="16px">
        <Box display="flex" w="full">
          <Text textStyle="card_header">Borrow Summary</Text>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            fontSize="14px"
            rounded="14px"
            color="red.400"
            fontWeight="normal"
            disabled={trove.status !== "open" && true}
            variant="text"
            onClick={onCloseTroveOpen}
          >
            Close Trove
          </Button>
        </Box>
      </Box> */}
    </>
  );
};

export default AdjustBorrowAmount;
