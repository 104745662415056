import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import React from "react";
import tokenData from "../../../TokenData";
import { formatWithDecimals } from "../../../Utils/number";
import CollateralCalculator from "../CollateralCalculator";

const selector = ({
  trove,
  underlyingPrices,
  tokenBalances,
  borrowingRate,
  decimals,
}: LiquityStoreState) => ({
  borrowingRate,
  trove,
  underlyingPrices,
  tokenBalances,
  decimals,
});

const AdjustLiquidation: React.FC = () => {
  const { trove, tokenBalances, decimals } = useLiquitySelector(selector);

  // Shape collateral
  tokenData.map(
    (token) =>
      (token["troveBalance"] = formatWithDecimals(
        trove.collaterals[token.address],
        decimals[token.address].toNumber()
      ))
  );
  tokenData.map(
    (token) =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[
          token.underlying == "" || token.isGated
            ? token.address
            : token.underlying
        ],
        token.underlyingDecimals
      ))
  );
  return (
    <>
      <CollateralCalculator collateral={tokenData} />
    </>
  );
};

export default AdjustLiquidation;
