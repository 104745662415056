import React, { useEffect, useRef, useState } from "react";
import {
  tokenDataMappingT,
  descriptionMap,
  tokenDataMappingA,
} from "../../TokenData";
import {
  Text,
  Box,
  Spacer,
  Center,
  useDisclosure,
  useRadio,
  useRadioGroup,
  // Button,
  useToast,
  // Tabs,
  // Tab,
  // TabList,
  // TabPanel,
  // TabPanels,
  Spinner,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  LineController,
} from "chart.js";
import { format, formatWithDecimals, getNum } from "../../Utils/number";
import { Chart } from "react-chartjs-2";
import { AdjustInput, CoinAmount, Icon } from "../../Components";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client/core";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
// import { Form } from "react-final-form";
import { validateDeposit } from "../../Utils/validation";
// import ConfirmVaultModal from "./ConfirmVaultModal";
import { useParams, useHistory } from "react-router-dom";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Form } from "react-final-form";
import { BlockedCard } from "../../Components/BlockedCard/BlockedCard";
import ConfirmVaultModal from "./ConfirmVaultModal";
// import { getNum } from "../../Utils/number";
// import VaultsChart from "../Vaults/VaultsChart";
// import { BlockedCard } from "../../Components/BlockedCard/BlockedCard";

const selector = ({
  tokenBalances,
  vaultDepositInfo,
  prices,
  total,
  safetyRatios,
  interestRates,
  recoveryRatios,
  Oracles,
}: LiquityStoreState) => ({
  tokenBalances,
  vaultDepositInfo,
  prices,
  total,
  safetyRatios,
  interestRates,
  recoveryRatios,
  Oracles,
});

const TokenGraph: React.FC = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin,
    Filler
  );
  const {
    tokenBalances,
    vaultDepositInfo,
    prices,
    total,
    safetyRatios,
    interestRates,
    recoveryRatios,
    Oracles,
  } = useLiquitySelector(selector);

  const { isBlockedCountry, userCountry } = useProtocolDataContext();

  const token = useParams().token!;

  const tokenInfo = tokenDataMappingT[token];

  const tokenAddress = tokenInfo.address;

  const currentAmount = total.collaterals[tokenAddress];

  const currentPrice = prices[tokenAddress];

  const currentTVL = currentAmount.mul(currentPrice);

  const userBalance = tokenBalances[tokenInfo.underlying];

  let userDeposit = vaultDepositInfo.vaultDeposits[tokenAddress];

  const wrappedTokens = [
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
  ];

  if (wrappedTokens.includes(tokenInfo.address)) {
    userDeposit = Decimal.ZERO;
  }

  // const descriptionMap = new Map();
  // descriptionMap.set("USDC", "USDC description");
  // descriptionMap.set("USDT.e", "USDT description");
  // descriptionMap.set("DAI.e", "dai description");

  const {
    isOpen: isFeeGraphOpen,
    onOpen: onFeeGraphOpen,
    onClose: onFeeGraphClose,
  } = useDisclosure();

  const {
    isOpen: isAPYGraphOpen,
    onOpen: onAPYGraphOpen,
    onClose: onAPYGraphClose,
  } = useDisclosure();

  const onFeeGraphClick = async () => {
    onFeeGraphOpen();
  };

  const onAPYGraphClick = async () => {
    onAPYGraphOpen();
  };

  const getGraphOptions = () => {
    if (["WAVAX", "WETH", "WBTC", "USDC", "GLP"].includes(token)) {
      return ["TVL", "Price"];
    } else {
      return ["Rewards", "TVL", "Price"];
    }
  };

  const getDefaultUnit = () => {
    if (["WAVAX", "WETH", "WBTC", "USDC", "GLP"].includes(token)) {
      return "$";
    } else {
      return "%";
    }
  };

  const [loading, setLoading] = useState<boolean>(true);

  const [portfolio, setPortfolio] = useState<any>({});

  const [defaultValue, setDefaultValue] = useState<any>(0);

  const [dataType, setDataType] = useState<string>(getGraphOptions()[0]);

  const [dataUnit, setDataUnit] = useState<string>(getDefaultUnit());

  const [dataRange, setDataRange] = useState<string>("1W");

  const getData = async (dataType: string, dataRange: string) => {
    let newData: any[] = [];
    const dates = [];

    if (dataType == "Rewards") {
      const url = `https://yeti-api-stage.herokuapp.com/v1/Collaterals/history/${token}`;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
          });

          const data = await response.json();

          return data;
        } catch (error) {
          console.log("error", error);
        }
      };
      const apyList = await fetchData();

      // console.log(apyList);

      // let dateStrings: string[] = []
      // let minValues = []
      // let maxValues = []
      const meanValues = [];

      for (let i = 0; i < apyList.length; i++) {
        const dataPoint = apyList[i];
        const date = new Date(dataPoint["_id"]);
        const dateString = date.toDateString();
        const value = dataPoint["average"];
        dates.push(date);
        // if (!dateStrings.includes(dateString)) {
        //   dateStrings.push(dateString)
        //   dates.push(date)
        //   minValues.push(value)
        //   maxValues.push(value)
        //   meanValues.push(value)
        // } else {
        //   let index = dateStrings.indexOf(dateString)
        //   let oldMin : number = minValues[index]
        //   let oldMax : number = maxValues[index]
        //   if (value < oldMin) {
        //     minValues[index] = value
        //   } else if (value > oldMax) {
        //     maxValues[index] = value
        //   }
        //   let oldMean : number = meanValues[index]
        //   meanValues[index] = (oldMean + value) / 2
        // }
        meanValues.push(value * 100);
        // testPoints.push({ date: date, value: value * 100 });
      }
      newData = meanValues;
    } else if (dataType == "BackFill") {
      const url = `https://yeti-api-stage.herokuapp.com/v1/Collaterals/history/${token}`;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
          });

          const data = await response.json();

          return data;
        } catch (error) {
          console.log("error", error);
        }
      };
      const apyList = await fetchData();

      // let dateStrings: string[] = []
      // let minValues = []
      // let maxValues = []
      const meanValues = [];
      for (let i = 0; i < apyList.length; i++) {
        const dataPoint = apyList[i];
        const date = new Date(dataPoint["_id"]);
        const dateString = date.toDateString();
        const value = dataPoint["average"];
        dates.push(date);
        // if (!dateStrings.includes(dateString)) {
        //   dateStrings.push(dateString)
        //   dates.push(date)
        //   minValues.push(value)
        //   maxValues.push(value)
        //   meanValues.push(value)
        // } else {
        //   let index = dateStrings.indexOf(dateString)
        //   let oldMin : number = minValues[index]
        //   let oldMax : number = maxValues[index]
        //   if (value < oldMin) {
        //     minValues[index] = value
        //   } else if (value > oldMax) {
        //     maxValues[index] = value
        //   }
        //   let oldMean : number = meanValues[index]
        //   meanValues[index] = (oldMean + value) / 2
        // }
        meanValues.push(value * 100);
      }
      newData = meanValues;
    } else if (dataType == "TVL") {
      const tvlQuery = `
          query {
            tvls(where: {collateral: "${tokenAddress.toLowerCase()}"}) {
              amounts
              prices
              timestamps
              transactions
            }
          }
          `;

      // console.log("query", tvlQuery);

      const client = new ApolloClient({
        uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti_tokens",
        cache: new InMemoryCache(),
      });

      const result = await client
        .query({
          query: gql(tvlQuery),
        })
        .then((data: any) => data)
        .catch((err: any) => {
          console.log("Error fetching history data: ", err);
          return [];
        });

      if (result.length == 0) {
        console.log("no result");
        return [];
      }
      const amounts = result.data.tvls[0].amounts;
      const prices = result.data.tvls[0].prices;
      const timestamps = result.data.tvls[0].timestamps;
      const transactions = result.data.tvls[0].transactions;
      const dateStrings: string[] = [];
      const allTVLs: number[] = [];

      for (let i = 0; i < amounts.length; i++) {
        const index = timestamps.length - amounts.length + i;
        let tvl = amounts[i] * prices[i];
        if (tvl < 0) {
          // console.log("NEGATIVE TVL", tvl);
          tvl *= -1;
        }
        const timestamp = timestamps[index];
        const date = new Date(timestamp * 1000);
        const dateString = date.toDateString();
        dates.push(date);
        allTVLs.push(tvl / 10 ** 36);
        // if (!dateStrings.includes(dateString)) {
        //   dateStrings.push(dateString);
        //   dates.push(date);
        //   allTVLs.push([tvl / 10 ** 36]);
        // } else {
        //   const index = dateStrings.indexOf(dateString);
        //   allTVLs[index].push(tvl / 10 ** 36);
        // }
      }
      // const meanTVL: number[] = [];
      // allTVLs.map((values) => {
      //   const sum = values.reduce((a, b) => a + b, 0);
      //   meanTVL.push(sum / values.length);
      // });
      allTVLs.push(Number(currentTVL));
      newData = allTVLs;
    } else if (dataType == "Price") {
      const tvlQuery = `
          query {
            tvls(where: {collateral: "${tokenAddress.toLowerCase()}"}) {
              prices
              timestamps
            }
          }
          `;

      // console.log("query", tvlQuery);

      const client = new ApolloClient({
        uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti_tokens",
        cache: new InMemoryCache(),
      });

      const result = await client
        .query({
          query: gql(tvlQuery),
        })
        .then((data: any) => data)
        .catch((err: any) => {
          console.log("Error fetching history data: ", err);
          return [];
        });

      if (result.length == 0) {
        console.log("no result");
        return [];
      }
      const amounts = result.data.tvls[0].amounts;
      const prices = result.data.tvls[0].prices;
      const timestamps = result.data.tvls[0].timestamps;
      const dateStrings: string[] = [];
      const allPrices: number[] = [];
      for (let i = 0; i < prices.length; i++) {
        const price = prices[i];
        const timestamp = timestamps[i];
        const date = new Date(timestamp * 1000);
        const dateString = date.toDateString();
        // if (!dateStrings.includes(dateString)) {
        //   dateStrings.push(dateString);
        //   dates.push(date);
        //   allPrices.push([price / 10 ** 18]);
        // } else {
        //   const index = dateStrings.indexOf(dateString);
        //   allPrices[index].push(price / 10 ** 18);
        // }
        dates.push(date);
        allPrices.push(price / 10 ** 18);
      }
      // const meanPrice: number[] = [];
      // allPrices.map((prices) => {
      //   const sum = prices.reduce((a, b) => a + b, 0);
      //   meanPrice.push(sum / prices.length);
      // });

      newData = allPrices;
    }

    let length = 0;
    const now = new Date().getTime() / 1000;
    let startTime = 0;

    switch (dataRange) {
      case "1D":
        length = 1;
        startTime = now - 60 * 60 * 24 * 2;
        break;
      case "1W":
        length = 60 * 60;
        startTime = now - 60 * 60 * 24 * 7;
        break;
      case "1M":
        length = 60 * 60 * 6;
        startTime = now - 60 * 60 * 24 * 30;
        break;
      case "1Y":
        length = 60 * 60 * 24;
        startTime = now - 60 * 60 * 24 * 365;
    }

    let prev = 0;

    const finalData: any[] = [];
    const finalDates = [];
    for (let i = 0; i < dates.length; i++) {
      const cur = dates[i].getTime() / 1000;
      // console.log(cur, prev, length, startTime);
      if (cur > startTime && cur - prev > length) {
        finalDates.push(new Date(cur * 1000));
        finalData.push(newData[i]);
        prev = cur;
      }
    }

    // console.log("finalData", finalData);

    const chartData = {
      labels: finalDates,
      datasets: [
        // {
        //     label: 'min',
        //     data: minValues,
        //     color: 'red'
        // },
        // {
        //     label: 'max',
        //     data: maxValues,
        //     color: 'green'
        // },
        {
          data: finalData,
          fill: "origin",
          pointRadius: 0,
          borderWidth: 3,
          borderColor: "#5F81F9",
          backgroundColor: "rgba(95,129,249,0.05)",
          // backgroundColor: "rgba(95,129,249,0.0)",
          tension: 0.1,
        },
        // {
        //   label: 'ICR',
        //   data: icrs,
        // }
      ],
    };
    // setPortfolio(testPoints);

    setPortfolio(chartData);

    if (newData.length == 0) {
      setDefaultValue(0);
    } else {
      setDefaultValue(newData[newData.length - 1]);
    }

    setLoading(false);
  };

  function switchHoverNumber() {
    return (event: React.MouseEvent) => {
      // console.log("hover out detected");
      // sethoverNum(bal2);
      setHovered(false);
    };
  }

  const [hovered, setHovered] = useState(false);
  const [hoverNumb, sethoverNum] = useState<number | undefined>();

  const balanceHovered = Number(hoverNumb);

  // useEffect(() => {
  //   sethoverNum(undefined);
  // });

  // useEffect(() => {
  //   if (hoverNumb === undefined ) {
  //     sethoverNum("32");
  //   }
  // });

  // console.log('modal data', data)

  const customTooltip = {
    id: "customTooltip",
    afterDraw(
      chart: {
        tooltip: any;
        ctx: any;
      },
      args: any,
      options: any
    ) {
      if (chart.tooltip._active[0]) {
        const { ctx } = chart;

        ctx.save();
        // console.log(chart.tooltip._active[0]);
        const activePoint = chart.tooltip._active[0];
        const datapoint = activePoint.index;
        const datasetIndex = activePoint.datasetIndex;
        // console.log(datasetIndex);

        // console.log(chart.getDatasetMeta(datasetIndex));

        ctx.beginPath();
        (ctx.lineWidth = 5), (ctx.strokeStyle = "white");
        ctx.moveTo(100, 0);
        ctx.lineTo(100, 100);
        ctx.stroke();
        const a = 12.56;
        // console.log(a);
      }
    },
  };

  const options: any = {
    grid: {
      show: false,
    },
    responsive: true,
    // scaleShowVerticalLines: true,
    // showVerticalLines: true,
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      xAxes: {
        display: false,
        ticks: {
          color: "",
          display: false,
        },
        type: "time",
        title: {
          display: false,
          color: "",
          text: "",
        },
        gridLines: {
          display: false,
        },
      },
      yAxes: {
        grid: {
          display: false,
          z: -1,
          color: "#1C1D27",
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          color: "#434658",
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    // pointStyle: "line",
    plugins: {
      customTooltip,
      tooltip: {
        // enabled: false,
        // callbacks: {
        //   label: function (tooltipItem: any, data: any) {
        //     return "$" + getNum(format(Number(tooltipItem["raw"])), 2);
        //   },
        // },
        titleFont: {
          size: 15,
        },
        callbacks: {
          // footer: function () {
          //   return balanceHovered;
          //   // return null;
          // },
          // title: function () {
          //   return balanceHovered;
          // },
          title: (context: any) => {
            const date = context[0].label;
            // console.log(context[0].parsed.x);
            const options: any = {
              day: "numeric",
              month: "long",
              hour: "numeric",
              minute: "numeric",
            };
            const strings = date.toLocaleString("en-US", options).split(" ");
            // console.log("parsed", strings);
            // return context[0].label;
            return (
              strings[1] +
              " " +
              strings[2] +
              " " +
              strings[3] +
              " " +
              strings[4].slice(0, 5)
            );
            //format date
          },
          label: function () {
            return null;
          },
          afterFooter: function (chart: any) {
            // console.log(chart[0].dataIndex);
            // console.log(chart[0].datasetIndex);
            // console.log(chart[0]);
            sethoverNum(chart[0].parsed.y);
          },
        },
        backgroundColor: "#121724",
        padding: "8",
        bodyAlign: "center",
        titleAlign: "center",
        yAlign: "bottom",
      },
      legend: {
        display: false,
        labels: {
          color: "white",
        },
      },
    },
    onHover: function (ctx: any) {
      // console.log(ctx.chart._active[0]);
      if (ctx.chart._active[0] === undefined) {
        // console.log("hovered in detected");
        setHovered(true);
      }
    },
    // zoom: {
    //   zoom: {
    //     wheel: {
    //       enabled: true,
    //     },
    //     pinch: {
    //       enabled: true,
    //     },
    //     mode: 'xy',
    //     overScaleMode: 'y'
    //   },
    //   pan: {
    //     enabled: true,
    //     mode: 'xy',
    //   }
    // }
  };

  useEffect(() => {
    getData(dataType, dataRange);
  }, [dataType, dataRange]);

  const {
    isOpen: isConfirmDOpen,
    onOpen: onConfirmDOpen,
    onClose: onConfirmDClose,
  } = useDisclosure();

  const {
    isOpen: isConfirmWOpen,
    onOpen: onConfirmWOpen,
    onClose: onConfirmWClose,
  } = useDisclosure();

  const toast = useToast();

  const validateD = (valueChange: number) => {
    validateDeposit(
      toast,
      "deposit",
      formatWithDecimals(userBalance, tokenInfo.underlyingDecimals),
      formatWithDecimals(userDeposit, tokenInfo.underlyingDecimals),
      valueChange,
      onConfirmDOpen
    );
  };

  const validateW = (valueChange: number) => {
    validateDeposit(
      toast,
      "withdraw",
      formatWithDecimals(userBalance, tokenInfo.underlyingDecimals),
      formatWithDecimals(userDeposit, tokenInfo.underlyingDecimals),
      valueChange,
      onConfirmWOpen
    );
  };

  const chartRef = useRef<ChartJS>(null);
  // const chartRef = useRef<ChartJS>(null);

  function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          // borderWidth="1px"
          borderRadius="6px"
          // boxShadow="md"
          color="#8A93A6"
          fontWeight="600"
          fontSize="12px"
          _hover={{
            bg: "#343647",
          }}
          _checked={{
            bg: "#434658",
            color: "#DFE3E9",
            borderColor: "",
          }}
          // _focus={{
          //   boxShadow: "outline",
          // }}
          px="8px"
          py="3px"
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  const handleRadiochange = (value: any) => {
    setLoading(true);
    setDataType(value);
    switch (value) {
      case "Rewards": {
        setDataUnit("%");
        break;
      }
      default: {
        setDataUnit("$");
        break;
      }
    }
  };

  const handleRangeRadiochange = (value: any) => {
    setDataRange(value);
  };

  const rangeRadioGroup = useRadioGroup({
    name: "framework",
    defaultValue: "1W",
    onChange: handleRangeRadiochange,
  });

  const rangeRootProps = rangeRadioGroup.getRootProps();

  const getRangeRadioProps = rangeRadioGroup.getRadioProps;

  const rangeOptions = ["1D", "1W", "1M", "1Y"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: getGraphOptions()[0],
    onChange: handleRadiochange,
  });

  const isConstantAPY = () => {
    return ["av3CRV", "sAVAX", "GLP"].includes(token!) && dataType == "Rewards";
  };

  const getConstantAPY = () => {
    if (token == "av3CRV") {
      return 0.0169;
    } else if (token == "sAVAX") {
      return 0.0576;
    } else if (token == "GLP") {
      return 0.171;
    }
  };

  // const getFormattedValue = (value: string) => {
  //   if (/^[0-9.]*$/.test(value)) {
  //     if (max != undefined && value != "") {
  //       const decimalInput: Decimal = Decimal.from(value);
  //       const decimalMax: Decimal = Decimal.from(max);
  //       if (decimalInput.gte(decimalMax)) {
  //         return decimalMax.toString();
  //       }
  //     }

  //     return value;
  //   }
  //   return "";
  // };

  const { APYs } = useProtocolDataContext();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");

  const [updated, setUpdated] = useState<string>("");

  const tagsList = tokenInfo.tags;

  const underlyingList = tokenInfo.underlyingAssets;

  const testList = tokenInfo;

  const tokenList = tokenDataMappingT;

  useEffect(() => {
    setUpdated(updated + mode);
  }, [mode]);

  const history = useHistory();

  return (
    //TODO dynamic mode
    <>
      <Box
        w="full"
        h="619px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Box display="flex" flexDir="column">
        <Box display="flex">
          <Box
            bg="#272937"
            px="8px"
            py="6px"
            display="flex"
            alignItems="center"
            gap="6px"
            _hover={{ bg: "#343647" }}
            rounded="4px"
            cursor="pointer"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon color="#DFE3E9" />
            <Text fontSize="14px" fontWeight="500" color="#DFE3E9">
              Go back
            </Text>
          </Box>
        </Box>
        {/* <Box
          display="flex"
          gap="4px"
          color="#DFE3E9"
          fontSize="15px"
          fontWeight="600"
        >
          <Link to="/info">
            <Text _hover={{ color: "primary-default" }}>Collateral</Text>
          </Link>
          <Text>/</Text>
          <Text>{tokenInfo.token}</Text>
        </Box> */}
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap="8px" mt="16px">
            <Icon iconName={token} h="40px" w="40px" />
            <Text color="#DFE3E9" textStyle="display_sm">
              {tokenInfo.name}
            </Text>
            <a
              href={`https://snowtrace.io/address/${tokenInfo.address}`}
              target="_blank"
              rel="noreferrer"
            >
              <ExternalLinkIcon color="#DFE3E9" boxSize="18px" />
            </a>
          </Box>
          {/* <Box
            display="flex"
            flexDir="column"
            bg="#141724"
            alignItems="flex-end"
          >
            <Text fontSize="15px" color="#8A93A6">
              APY
            </Text>
            <Text textStyle="display_sm">{getNum(defaultValue, 2)}</Text>
          </Box> */}
          {/* <Text>Vault Contract</Text> */}
        </Box>
        {/*** Chart Container> ***/}
        <Box
          w="full"
          mt="16px"
          justifyContent="space-between"
          gap="24px"
          display="flex"
        >
          <Box display="flex" flexDir="column" w="full">
            <Box display="flex">
              <Box bg="#272937" display="flex" p="4px" rounded="8px" gap="4px">
                {getGraphOptions().map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio}>
                      {value}
                    </RadioCard>
                  );
                })}
              </Box>
              <Spacer />
              <Box bg="#272937" display="flex" p="4px" rounded="8px" gap="4px">
                {rangeOptions.map((value) => {
                  const radio = getRangeRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio}>
                      {value}
                    </RadioCard>
                  );
                })}
              </Box>
            </Box>
            <Box display="flex" flexDir="column" w="full" mt="16px" h="280px">
              {loading ? (
                <Box
                  h="240px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Loader /> */}
                  <Spinner color="white" />
                </Box>
              ) : // <Flex mt={3} flexDirection="column" alignItems="center" gap={4}>
              //   <Loader />
              //   <Text textStyle="title4"> Loading</Text>
              // </Flex>
              // ) : portfolio.labels.length == 0 ? (
              portfolio.length == 0 && !isConstantAPY() ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Text color="white" textStyle="number_base">
                    Data unavailable
                  </Text>
                </Box>
              ) : isConstantAPY() ? (
                <Center>
                  <Text color="#DFE3E9">
                    Rewards: {getConstantAPY()! * 100}%
                  </Text>
                </Center>
              ) : (
                <>
                  <Box>
                    {hovered ? (
                      <>
                        <Text color="#DFE3E9" fontSize="30px" fontWeight="700">
                          {dataUnit === "$" ? (
                            <Box>
                              {dataUnit}
                              {getNum(balanceHovered, 2)}
                            </Box>
                          ) : (
                            <Box>
                              {getNum(balanceHovered, 2)}
                              {dataUnit}
                            </Box>
                          )}
                        </Text>
                      </>
                    ) : (
                      <Text color="#DFE3E9" fontSize="30px" fontWeight="700">
                        {dataUnit === "$" ? (
                          <Box>
                            {dataUnit}
                            {getNum(defaultValue, 2)}
                            {/* {defaultValue.toFixed(4)} */}
                          </Box>
                        ) : (
                          <Box>
                            {getNum(defaultValue, 2)}
                            {dataUnit}
                          </Box>
                        )}
                      </Text>
                    )}
                    {/* <Text>{dataRange}</Text> */}
                  </Box>
                  <Box
                    mt="16px"
                    // h="180px"
                    w="full"
                    h="full"
                    onMouseOut={switchHoverNumber()}
                  >
                    <Chart
                      ref={chartRef}
                      type="line"
                      data={portfolio}
                      options={options}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box display={{ md: "flex" }} mt="24px" gap="24px">
              <Box display="flex" flexDir="column" bg="surface" rounded="12px">
                <Box px="24px" py="24px">
                  <Box display="flex" justifyContent="space-between">
                    <Text fontSize="24px" fontWeight="600" display="flex">
                      About
                    </Text>
                    <a
                      href={`https://snowtrace.io/address/${tokenInfo.underlying}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="8px"
                        bg="surface-highlight"
                        px="12px"
                        py="4px"
                        rounded="full"
                      >
                        <Text textStyle="number_base">Token contract</Text>
                        <ExternalLinkIcon color="text-primary" boxSize="14px" />
                      </Box>
                    </a>
                  </Box>
                  <Text color="text-secondary" fontSize="15px" mt="8px">
                    {tokenInfo.underlyingDescription}
                  </Text>
                  <Text fontSize="24px" fontWeight="600" mt="32px">
                    Vault
                  </Text>
                  <Text color="text-secondary" fontSize="15px" mt="8px">
                    {tokenInfo.strategy} {tokenInfo.protocol}.
                  </Text>

                  {/* <Text textStyle="text_md" mt="32px">
                  Deposit Instructions
                </Text>
                <Text color="text-secondary" textStyle="number_base">
                  {tokenInfo.instructions}
                </Text> */}
                  {tokenInfo.protocol === "" ? (
                    <></>
                  ) : (
                    <>
                      <Text fontSize="24px" fontWeight="600" mt="32px">
                        Utilized Protocols
                      </Text>
                      <Box display="flex">
                        <a
                          href={tokenInfo.protocolLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Box
                            px="12px"
                            rounded="full"
                            py="3px"
                            bg="surface-highlight"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            mt="8px"
                          >
                            {/* <Icon iconName={token} h="32px" w="24px" /> */}
                            <Text color="text-primary" fontSize="15px">
                              {tokenInfo.protocol}
                            </Text>
                            <ExternalLinkIcon
                              color="primary-default"
                              boxSize="14px"
                            />
                          </Box>
                        </a>
                      </Box>
                    </>
                  )}

                  <Box display="flex" flexDir="column">
                    <Text fontSize="24px" fontWeight="600" mt="32px">
                      Underlying Assets
                    </Text>
                    <Box display="flex" gap="8px" mt="8px" flexDir="column">
                      {underlyingList.map((asset, index) => {
                        return (
                          <Box mt="8px" key={index}>
                            <Box display="flex" flexDir="column">
                              <Box display="flex" alignItems="center" gap="6px">
                                <Icon iconName={asset} h="32px" w="32px" />
                                <Text fontSize="16px" fontWeight="600">
                                  {asset}
                                </Text>
                              </Box>
                              <Text
                                mt="4px"
                                color="text-secondary"
                                fontSize="15px"
                              >
                                {descriptionMap.get(asset)}
                              </Text>
                              {/* <Text>{contractMap.get(asset)}</Text> */}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  <Text fontSize="24px" fontWeight="600" mt="32px">
                    Category
                  </Text>
                  <Box display="flex" gap="8px" mt="8px">
                    {tagsList.map((tag) => {
                      return (
                        <Box
                          px="12px"
                          py="4px"
                          bg="surface-highlight"
                          textStyle="text_sm"
                          rounded="full"
                          key={tag}
                        >
                          <Text>{tag}</Text>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDir="column" gap="24px">
                {tokenInfo.token === "GLP" ? (
                  <Box
                    display="flex"
                    flexDir="column"
                    mt={{ base: "24px", lg: "0px" }}
                  >
                    <Box
                      flexDir="column"
                      display="flex"
                      // position="sticky"
                      // top="124px"
                    >
                      <Box layerStyle="card" w="full" px="16px" py="16px">
                        <Tabs variant="unstyled" isFitted>
                          <TabList
                            rounded="8px"
                            borderWidth="1px"
                            borderColor="border"
                            p="4px"
                          >
                            <Tab
                              rounded="6px"
                              color="text-secondary"
                              _selected={{
                                color: "text-primary",
                                bg: "surface-highlight",
                              }}
                              textStyle="display_xs"
                              onClick={() => setMode("deposit")}
                            >
                              Mint GLP
                            </Tab>
                            <Tab
                              rounded="6px"
                              color="text-secondary"
                              _selected={{
                                color: "text-primary",
                                bg: "surface-highlight",
                              }}
                              textStyle="display_xs"
                              onClick={() => setMode("withdraw")}
                            >
                              Redeem GLP
                            </Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <Form
                                onSubmit={() => undefined}
                                render={({ values, form }) => (
                                  <>
                                    {isConfirmDOpen && (
                                      <ConfirmVaultModal
                                        isOpen={isConfirmDOpen}
                                        onClose={onConfirmDClose}
                                        mode={"deposit"}
                                        token={token}
                                        amount={
                                          values[token + "vault" + "deposit"]
                                        }
                                        total={
                                          Number(
                                            values[token + "vault" + "deposit"]
                                          ) + Number(userDeposit.toString())
                                        }
                                        userBalance={userBalance}
                                        userDeposit={userDeposit}
                                      />
                                    )}
                                    <Text
                                      color="text-primary"
                                      pb="16px"
                                      pt="8px"
                                      fontSize="13px"
                                    >
                                      *You must first deposit into the Yeti GLP
                                      vault before you can deposit into your
                                      trove
                                    </Text>
                                    <Text
                                      color="text-secondary"
                                      pb="24px"
                                      fontSize="13px"
                                    >
                                      Note: After minting GLP on the GMX
                                      website, you must wait 15 minutes before
                                      you can deposit into the Yeti GLP Vault.
                                      You can read more{" "}
                                      <a
                                        href="https://gmxio.gitbook.io/gmx/glp#minting-and-redeeming"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <span style={{ color: "#5F81F9" }}>
                                          here.
                                        </span>
                                      </a>
                                    </Text>
                                    <Box>
                                      <AdjustInput
                                        mode={"deposit"}
                                        max={formatWithDecimals(
                                          userBalance,
                                          tokenInfo.underlyingDecimals
                                        )}
                                        name={token + "vault" + "deposit"}
                                        token={token}
                                        showToken
                                        transactionId={"vaultDeposit"}
                                        form={form}
                                        troveBalance={Number(
                                          userDeposit.toString()
                                        )}
                                        walletBalance={formatWithDecimals(
                                          userBalance,
                                          tokenInfo.underlyingDecimals
                                        )}
                                      />
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        gap="8px"
                                        color="text-secondary"
                                        textStyle="number_base"
                                        mt="24px"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Text>Rewards</Text>
                                          {(APYs[token] * 100).toFixed(3)}%
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Text>Your deposit</Text>
                                          <Text>
                                            {getNum(
                                              Number(userDeposit.toString())
                                            )}
                                          </Text>
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Text>Auto-compound Fee</Text>
                                          <Text>{tokenInfo.fee}%</Text>
                                        </Box>
                                      </Box>
                                      <Button
                                        mt="24px"
                                        variant="surface"
                                        w="full"
                                        disabled={isBlockedCountry}
                                        onClick={() =>
                                          validateD(
                                            values[token + "vault" + "deposit"]
                                          )
                                        }
                                      >
                                        {isBlockedCountry
                                          ? "Unavailable"
                                          : "Deposit"}
                                      </Button>
                                    </Box>
                                    {isBlockedCountry && (
                                      <Box mt="16px">
                                        <BlockedCard />
                                      </Box>
                                    )}
                                  </>
                                )}
                              />
                            </TabPanel>
                            <TabPanel>
                              <Form
                                onSubmit={() => undefined}
                                render={({ values, form }) => (
                                  <>
                                    {isConfirmWOpen && (
                                      <ConfirmVaultModal
                                        isOpen={isConfirmWOpen}
                                        onClose={onConfirmWClose}
                                        mode={"withdraw"}
                                        token={token}
                                        amount={
                                          values[token + "vault" + "withdraw"]
                                        }
                                        total={
                                          Number(userDeposit.toString()) -
                                          Number(
                                            values[token + "vault" + "withdraw"]
                                          )
                                        }
                                        userBalance={userBalance}
                                        userDeposit={userDeposit}
                                      />
                                    )}
                                    {/* <Text
                                      color="text-primary"
                                      pb="16px"
                                      pt="8px"
                                      fontSize="13px"
                                    >
                                      *Withdraw minted GLP to redeem fsGLP.
                                    </Text> */}
                                    <Box>
                                      <AdjustInput
                                        mode={"withdraw"}
                                        max={userDeposit.toString()}
                                        name={token + "vault" + "withdraw"}
                                        token={token}
                                        showToken
                                        transactionId={"vaultWithdraw"}
                                        form={form}
                                        troveBalance={Number(
                                          userDeposit.toString()
                                        )}
                                        walletBalance={formatWithDecimals(
                                          userBalance,
                                          tokenInfo.underlyingDecimals
                                        )}
                                      />
                                      <Box
                                        display="flex"
                                        flexDir="column"
                                        gap="8px"
                                        color="text-secondary"
                                        textStyle="number_base"
                                        mt="24px"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Text>Rewards</Text>
                                          {(APYs[token] * 100).toFixed(3)}%
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Text>Your deposit</Text>
                                          <Text>
                                            {getNum(
                                              Number(userDeposit.toString())
                                            )}
                                          </Text>
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                        >
                                          <Text>Auto-compound Fee</Text>
                                          <Text>20%</Text>
                                        </Box>
                                      </Box>
                                      <Button
                                        mt="24px"
                                        variant="surface"
                                        w="full"
                                        onClick={() =>
                                          validateW(
                                            values[token + "vault" + mode]
                                          )
                                        }
                                      >
                                        Withdraw
                                      </Button>
                                    </Box>
                                  </>
                                )}
                              />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                <Box
                  bg="surface"
                  rounded="12px"
                  minW={{ base: "full", md: "400px" }}
                  display="flex"
                  flexDir="column"
                  mt={{ base: "24px", md: "0px" }}
                >
                  <Box py="16px" px="24px">
                    <Text
                      textStyle="card_header"
                      display="flex"
                      fontFamily="Sora"
                    >
                      Collateral Stats
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    flexDir="column"
                    // gap="8px"
                    color="text-secondary"
                    textStyle="number_base"
                    px="24px"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      borderColor="border"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>
                        {tokenInfo.isGated ? "Vault deposit" : "Trove deposit"}
                      </Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            {getNum(Number(userDeposit.toString()))}
                          </Text>
                          {/* <Text>{tokenInfo.token}</Text> */}
                        </Box>
                        {/* {Number(vaultDepositInfo.vaultDeposits[tokenAddress])} */}

                        <Text fontSize="13px" color="text-secondary">
                          $
                          {getNum(
                            Number(userDeposit) *
                              Number(prices[tokenInfo.address])
                          )}
                        </Text>
                        {/* vaultDepositInfo.vaultDeposits[tokenAddress] */}
                        {/* <CoinAmount
                          token={tokenDataMappingA[tokenInfo.address].token}
                          noGetNum={true}
                          // noCurrencyConvert={true}
                          // firstNumber={true}
                          amount={Number(
                            userDeposit.toStringWithDecimals(
                              tokenDataMappingA[tokenInfo.address]
                                .underlyingDecimals
                            )
                          )}
                          // noCurrencyConvert={true}
                          fontSize="13px"
                          color="text-secondary"
                          fontWeight="500"
                        /> */}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>Wallet Balance</Text>
                      <Box
                        w="160px"
                        display="flex"
                        justifyContent="flex-end"
                        // pr="114px"
                      >
                        <Box
                          display="flex"
                          flexDir="column"
                          alignItems="flex-end"
                        >
                          <Box display="flex" gap="4px">
                            <Text
                              alignItems="flex-end"
                              whiteSpace="nowrap"
                              fontSize="15px"
                              color={
                                Number(
                                  tokenBalances[
                                    tokenDataMappingA[tokenInfo.address]
                                      .underlying
                                  ].toStringWithDecimals(
                                    tokenDataMappingA[tokenInfo.address]
                                      .underlyingDecimals
                                  )
                                ) > 0
                                  ? "text-primary"
                                  : "text-secondary"
                              }
                            >
                              {getNum(
                                Number(
                                  tokenBalances[
                                    tokenDataMappingA[tokenInfo.address]
                                      .underlying
                                  ].toStringWithDecimals(
                                    tokenDataMappingA[tokenInfo.address]
                                      .underlyingDecimals
                                  )
                                )
                              )}
                            </Text>
                            {/* <Text>{tokenInfo.token}</Text> */}
                          </Box>
                          <CoinAmount
                            token={tokenDataMappingA[tokenInfo.address].token}
                            noGetNum={true}
                            // noCurrencyConvert={true}
                            // firstNumber={true}
                            amount={Number(
                              tokenBalances[
                                tokenDataMappingA[tokenInfo.address].underlying
                              ].toStringWithDecimals(
                                tokenDataMappingA[tokenInfo.address]
                                  .underlyingDecimals
                              )
                            )}
                            // noCurrencyConvert={true}
                            fontSize="13px"
                            color="text-secondary"
                            fontWeight="500"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>Interest Rate</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            {getNum(
                              format(interestRates[tokenInfo.address]) *
                                365 *
                                100,
                              3
                            )}{" "}
                            %
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>TVL</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            $
                            {getNum(
                              Number(
                                total.collaterals[tokenInfo.address]
                                  .mul(prices[tokenInfo.address])
                                  .toString()
                              )
                            )}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>Rewards</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            {(
                              APYs[tokenDataMappingA[tokenInfo.address].token] *
                              100
                            ).toFixed(2)}
                            %{" "}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>Max LTV</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            {(
                              (Number(safetyRatios[tokenInfo.address]) / 1.1) *
                              100
                            ).toFixed(2)}
                            %
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      alignItems="center"
                    >
                      <Text>Safety Ratio</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            {format(safetyRatios[tokenInfo.address]).toFixed(3)}{" "}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="12px"
                      pb="24px"
                      alignItems="center"
                    >
                      <Text>Oracle Price</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <a
                            // href={Oracles[tokenInfo.address]}
                            href={`https://snowtrace.io/address/${
                              Oracles[tokenInfo.address]
                            }`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Text color="primary-default" fontSize="15px">
                              ${getNum(Number(prices[tokenInfo.address]))}
                            </Text>
                          </a>
                        </Box>
                      </Box>
                    </Box>
                    {/* <Box
                      display="flex"
                      justifyContent="space-between"
                      borderBottom="1px"
                      borderColor="border"
                      py="16px"
                      alignItems="center"
                    >
                      <Text>Adjusted Safety Ratio</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        <Box display="flex" gap="4px">
                          <Text fontSize="15px" color="text-primary">
                            {format(recoveryRatios[tokenInfo.address]).toFixed(3)}{" "}
                          </Text>
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TokenGraph;
