import { createIcon } from "@chakra-ui/react";

const qiBTCb = createIcon({
  displayName: "qiBTC.b",
  viewBox: "150 100 1800 1800",
  path: (
    <>
      <g>
        <image
          xmlns="http://www.w3.org/2000/svg"
          width="3563"
          height="3564"
          transform="translate(144.76 144.72) scale(0.48)"
          href="https://i.ibb.co/8YrtJFc/benqi-circle.png"
        />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="993.59"
          cy="999.85"
          r="642.06"
          fill="#fff"
        />
        <g
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_2-2"
          data-name="Layer 2"
        >
          <g id="Layer_1-2" data-name="Layer 1-2">
            <g id="Page-1">
              <g id="wbtc_colour" data-name="wbtc colour">
                <path
                  id="Shape"
                  d="M1256,764.51,1233.7,786.8c110.23,120.54,110.23,305.27,0,425.81l22.29,22.28c122.63-133,122.63-337.88,0-470.9Z"
                  fill="#00b2ec"
                  fillOpacity="0.6"
                />
                <path
                  id="Shape-2"
                  d="M787.32,766.44c120.54-110.22,305.27-110.22,425.81,0l22.28-22.28c-133-122.63-337.88-122.63-470.9,0Z"
                  fill="#00b2ec"
                  fillOpacity="0.6"
                />
                <path
                  id="Shape-3"
                  d="M766.44,1212.83c-110.08-120.5-110.08-305.08,0-425.59L744.16,765c-122.63,133-122.63,337.88,0,470.9Z"
                  fill="#00b2ec"
                  fillOpacity="0.6"
                />
                <path
                  id="Shape-4"
                  d="M1212.83,1233.41c-120.54,110.22-305.27,110.22-425.81,0l-22.28,22.28c133,122.63,337.88,122.63,470.9,0Z"
                  fill="#00b2ec"
                  fillOpacity="0.6"
                />
                <path
                  id="Shape-5"
                  d="M1140.7,925.64c-4.46-46.5-44.57-62.1-95.31-66.86v-64h-39.22v62.84H974.82V794.75H935.89v64.48H856.33v42s29-.52,28.53,0a20.28,20.28,0,0,1,22.28,17.23v176.51a14,14,0,0,1-14.85,13c.52.44-28.53,0-28.53,0l-7.43,46.87h78.82v65.52h39.22v-64.55h31.35V1220H1045v-64.78c66.26-4,112.47-20.36,118.26-82.39,4.68-49.92-18.79-72.2-56.31-81.19C1129.78,980.46,1143.89,959.66,1140.7,925.64Zm-55,139.51c0,48.73-83.5,43.16-110.1,43.16v-86.47C1002.23,1021.91,1085.73,1014.26,1085.73,1065.15Zm-18.2-121.83c0,44.57-69.68,39.15-91.82,39.15v-78.6C997.85,903.87,1067.53,896.89,1067.53,943.32Z"
                  fill="#00b2ec"
                />
                <path
                  id="Shape-6"
                  d="M999.93,1405.82c-224.14,0-405.8-181.76-405.75-405.89s181.76-405.8,405.89-405.75,405.71,181.67,405.75,405.75-181.61,405.85-405.75,405.89Zm0-780C793.4,626,626.1,793.54,626.27,1000.07S794,1373.9,1000.52,1373.73c206.36-.16,373.58-167.45,373.66-373.8.08-206.53-167.28-374-373.81-374.11Z"
                  fill="#00b2ec"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  ),
});

export default qiBTCb;
