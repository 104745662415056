import React, { useState, useEffect } from "react";

import ProgressBar from "../../../Components/ProgressBar";
import {
  useTransactionFunction,
  useMyTransactionState,
} from "../../../Components/Transaction";
import { Decimal, TroveMappings } from "@yeti/lib-base";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";

import { useLiquity } from "../../../Hooks/LiquityContext";

import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { Text, Button, HStack, useDisclosure, Box } from "@chakra-ui/react";
import TransactionModal from "../../../Components/TransactionModal";
import CurrencyConverter from "../../../Components/CurrencyConverter";
import { Icon } from "../../../Components";
import { format, formatWithDecimals } from "../../../Utils/number";

export type ConfirmDepositModalProps = {
  isOpen: boolean;
  onClose: () => void;
  amount: string;
  values: Record<string, any>;
  flipped: boolean;
  swapRate: number;
};
const selector = ({
  yusdBalance,
  underlyingPrices,
  tokenBalances,
}: LiquityStoreState) => ({
  yusdBalance,
  underlyingPrices,
  tokenBalances,
});

const ConfirmDepositModal: React.FC<ConfirmDepositModalProps> = ({
  isOpen,
  onClose,
  amount,
  values,
  flipped,
  swapRate,
}) => {
  const { sortedTrovesData } = useProtocolDataContext();

  const firstTenTroves = sortedTrovesData.slice(0, 11);

  const { liquity, account } = useLiquity();

  const {
    isOpen: isTxModalOpen,
    onOpen: onTxModalOpen,
    onClose: onTxModalClosed,
  } = useDisclosure();

  const { yusdBalance, underlyingPrices, tokenBalances } =
    useLiquitySelector(selector);

  // const [ collateralsToReceive, setCollateralsToReceive ] = useState<TroveMappings>({} as TroveMappings)

  const getFormattedValue = (value: string): number => {
    try {
      Decimal.from(value);
      return +value;
    } catch (err) {
      return 0;
    }
  };

  const USDCBalance =
    tokenBalances["0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"];

  let newAmount: Decimal;
  const formatedAmount = getFormattedValue(amount);

  if (flipped) {
    if (
      formatedAmount === format(yusdBalance) ||
      Decimal.from(formatedAmount).add(Decimal.from(".000009")).gte(yusdBalance)
    ) {
      newAmount = yusdBalance;
    } else {
      newAmount = Decimal.from(formatedAmount);
    }
  } else {
    if (
      formatedAmount === formatWithDecimals(USDCBalance, 6) ||
      Decimal.fromWithPrecision(formatedAmount, 6)
        .add(Decimal.from(".0000000000009"))
        .gte(USDCBalance)
    ) {
      newAmount = USDCBalance;
    } else {
      newAmount = Decimal.fromWithPrecision(formatedAmount, 6);
    }
  }

  const transactionId = "redeem";
  const myTransactionState = useMyTransactionState(transactionId);

  const checkAllowance = async (
    token: string,
    amount: Decimal
  ): Promise<boolean> => {
    const result = await liquity.getAllowanceOf(
      account,
      token,
      "0x3f97403218b71aa1ff2678e28398038a5c24641d",
      amount
    );
    return result;
  };

  const [hasAllowance, setHasAllowance] = useState<boolean>(false);

  useEffect(() => {
    // let tot:Decimal = Decimal.ZERO
    // if (!(getFormattedValue(amount) == 0)) {
    //   tot = Decimal.from(amount)
    // }
    const open = isOpen;
    let interval: any = undefined;
    if (open) {
      interval = setInterval(async () => {
        const allowance = await checkAllowance(
          flipped
            ? "0x111111111111ed1D73f860F57b2798b683f2d325" // yusd
            : "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E", //usdc
          Decimal.from(getFormattedValue(amount))
        );
        if (allowance) {
          setHasAllowance(true);
        } else {
          setHasAllowance(false);
        }
      }, 1500);
    }

    return () => clearInterval(interval);
  }, [amount, isOpen]);

  console.log("newAmount", newAmount);
  //usdc --> yusd
  const [depositUSDC] = useTransactionFunction(
    transactionId,
    liquity.send.PSMMintYUSD.bind(
      liquity.send,
      newAmount
      //   redeemRate.add(Decimal.from(0.0005))
    )
  );

  //yusd --> usdc
  const [depositYUSD] = useTransactionFunction(
    transactionId,
    liquity.send.PSMRedeemYUSD.bind(
      liquity.send,
      newAmount
      //   redeemRate.add(Decimal.from(0.0005))
    )
  );

  const [approveUSDC] = useTransactionFunction(
    "approve",
    liquity.send.approveToken.bind(
      liquity.send,
      "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E", //usdc
      "0x3f97403218b71aa1ff2678e28398038a5c24641d", //psm contract
      Decimal.from("10000000000000000000")
    )
  );
  const [approveYUSD] = useTransactionFunction(
    "approve",
    liquity.send.approveToken.bind(
      liquity.send,
      "0x111111111111ed1D73f860F57b2798b683f2d325", //yusd
      "0x3f97403218b71aa1ff2678e28398038a5c24641d", //psm contract
      Decimal.from("10000000000000000000")
    )
  );

  const onApprove = () => {
    flipped ? approveYUSD() : approveUSDC();
  };

  const [collateralsToReceive, setCollateralsToReceive] =
    useState<TroveMappings>({} as TroveMappings);

  const onDeposit = () => {
    // console.log(mode);
    {
      flipped ? depositYUSD() : depositUSDC();
    }

    onClose();
    console.log(newAmount);
    // delete values["yusdRedeemInput"];
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent bg="surface" color="text-primary">
          <ModalHeader textAlign="center" pb={1}>
            Confirm Swap
            <Box onClick={onClose}>
              <ModalCloseButton />
            </Box>
          </ModalHeader>

          <ModalBody>
            <Box>
              <Text>You Pay</Text>
              <Box display="flex" justifyContent="space-between" mt="16px">
                <Box w="full" mr="8px" cursor="not-allowed">
                  <Box>
                    <Text
                      fontSize="24px"
                      color="text-primary"
                      letterSpacing="tight"
                      fontWeight="400"
                    >
                      {flipped ? values.yusdPSMInput : values.usdcPSMInput}
                    </Text>
                    <CurrencyConverter
                      value={
                        flipped ? values.yusdPSMInput : values.usdcPSMInput
                      }
                      token={flipped ? "YUSD" : "USDC"}
                      currency={"USD"}
                      fontWeight="500"
                      fontSize="13px"
                      style={{
                        color: "text-secondary",
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="flex-end">
                  <Box display="flex" gap="4px" alignItems="center">
                    <Icon iconName={flipped ? "YUSD" : "USDC"} boxSize="24px" />
                    <Text textStyle="number_base">
                      {flipped ? "YUSD" : "USDC"}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* end of flip */}
            <Box mt="32px">
              <Text>You Receive</Text>
              <Box display="flex" justifyContent="space-between" mt="16px">
                <Box w="full" mr="8px" cursor="not-allowed">
                  <Box>
                    {values.yusdPSMInput > 0 || values.usdcPSMInput > 0 ? (
                      <Text
                        fontSize="24px"
                        color="text-primary"
                        letterSpacing="tight"
                        fontWeight="400"
                      >
                        {flipped
                          ? values.yusdPSMInput -
                            values.yusdPSMInput * Number(swapRate)
                          : values.usdcPSMInput -
                            values.usdcPSMInput * Number(swapRate)}
                      </Text>
                    ) : (
                      <Text fontSize="24px" color="text-secondary">
                        0
                      </Text>
                    )}
                    <CurrencyConverter
                      value={
                        flipped ? values.yusdPSMInput : values.usdcPSMInput
                      }
                      token={flipped ? "USDC" : "YUSD"}
                      currency={"USD"}
                      fontWeight="500"
                      fontSize="13px"
                      style={{
                        color: "text-secondary",
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex" flexDir="column" alignItems="flex-end">
                  <Box display="flex" gap="4px" alignItems="center">
                    <Icon iconName={flipped ? "USDC" : "YUSD"} boxSize="24px" />
                    <Text textStyle="number_base">
                      {flipped ? "USDC" : "YUSD"}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter flexDirection="column">
            <HStack spacing={6}>
              <Button
                variant={hasAllowance ? "surface" : "newPrimary"}
                onClick={onApprove}
              >
                Approve
              </Button>

              <Button
                variant={!hasAllowance ? "surface" : "newPrimary"}
                disabled={!hasAllowance}
                onClick={() => {
                  onDeposit(), console.log("Flipped:", flipped);
                }}
              >
                Confirm Swap
              </Button>
            </HStack>
            <ProgressBar step={hasAllowance ? 1 : 0} w="30%" mt={2} />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <TransactionModal
        status={myTransactionState.type}
        isOpen={isTxModalOpen}
        onClose={onTxModalClosed}
      />
    </>
  );
};

export default ConfirmDepositModal;
