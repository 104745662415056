import { TokenData, TokenDataMappingA, TokenDataMappingT } from "../Types";

export const vaultsData: TokenData[] = [
  {
    address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    troveBalance: 0,
    walletBalance: 0,
    token: "WAVAX",
    name: "Wrapped AVAX",
    isStable: false,
    isVault: false,
    underlying: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "",
    protocolLink: "",
    strategy: "This collateral asset is not interest-bearing.",
    instructions:
      "To deposit into this vault, obtain WAVAX, approve the deposit on this site, and deposit",
    underlyingDescription:
      "Wrapped AVAX is the ERC20 representation of AVAX, the gas token for the Avalanche ecosystem.",
    tags: ["AVAX"],
    underlyingAssets: ["WAVAX"],
    fee: 0,
  },
  {
    address: "0x0A548fcE885482Cd5f2696EC3247DA6A6aF3EE61",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiUSDC",
    name: "Benqi USDC",
    isStable: true,
    apr: 0,
    isVault: true,
    underlying: "0xBEb5d47A3f720Ec0a390d04b4d41ED7d9688bC7F",
    underlyingDecimals: 8,
    tokenTooltip: "qiUSDC balance is not 1:1 with USDC. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiUSDC. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiUSDC represents deposited USDC in the Benqi lending protocol. The ratio of qiUSDC to USDC increases over time.",
    tags: ["BENQI", "USDC", "STABLECOIN"],
    underlyingAssets: ["USDC.e"],
    fee: 20,
  },
  {
    address: "0xB775cb337Cf223708Ef053a110B56E4DAbb78132",
    troveBalance: 0,
    walletBalance: 0,
    token: "USDC",
    name: "USD Coin",
    isStable: true,
    isVault: true,
    underlying: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    underlyingDecimals: 6,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "",
    protocolLink: "",
    strategy: "This collateral asset is not interest-bearing",
    instructions:
      "To deposit into this vault, first obtain USDC from swaps or other means. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "USDC is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.",
    tags: ["USDC", "STABLECOIN"],
    underlyingAssets: ["USDC"],
    fee: 0,
  },
  {
    address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
    troveBalance: 0,
    walletBalance: 0,
    token: "WETH",
    name: "Wrapped Ether",
    isStable: false,
    isVault: false,
    underlying: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "",
    protocolLink: "",
    strategy: "This collateral asset is not interest-bearing",
    instructions:
      "To deposit into this vault, first obtain WETH from swaps or other means. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "ETH is the the native currency that flows within the Ethereum economy. Ether is typically used to pay for transaction fees called Gas, and it is the base currency of the network.",
    tags: ["WETH", "ETH"],
    underlyingAssets: ["WETH.e"],
    fee: 0,
  },
  {
    address: "0xEEa35EF62AdbC3BBD4EF137E7CFABA612248f784",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiAVAX",
    name: "Benqi AVAX",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0x5C0401e81Bc07Ca70fAD469b451682c0d747Ef1c",
    underlyingDecimals: 8,
    tokenTooltip: "qiAVAX balance is not 1:1 with AVAX. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiAVAX. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiAVAX represents deposited AVAX in the Benqi lending protocol. The ratio of qiAVAX to AVAX increases over time.",
    tags: ["BENQI", "AVAX"],
    underlyingAssets: ["WAVAX"],
    fee: 5,
  },
  {
    address: "0x72ccb37ede985311FECc3987bB0fC0572dA99B41",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiETH",
    name: "Benqi Ether",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0x334AD834Cd4481BB02d09615E7c11a00579A7909",
    underlyingDecimals: 8,
    tokenTooltip: "qiETH balance is not 1:1 with ETH. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiETH. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiETH represents deposited ETH in the Benqi lending protocol. The ratio of qiETH to ETH increases over time.",
    tags: ["BENQI", "ETH"],
    underlyingAssets: ["WETH.e"],
    fee: 5,
  },
  {
    address: "0x72298e47f648CA0215F2e17d5647048B2d42520C",
    troveBalance: 0,
    walletBalance: 0,
    token: "WETH-WAVAX JLP",
    name: "WETH-WAVAX JLP",
    isStable: false,
    apr: 5.87,
    isVault: true,
    underlying: "0xFE15c2695F1F920da45C30AAE47d11dE51007AF9",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Trader Joe",
    protocolLink:
      "https://traderjoexyz.com/pool/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab/AVAX#/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Trader Joe and obtain WETH-WAVAX JLP. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "WETH-WAVAX JLP is a Liquidity Provider token representing a 50/50 proportion of WETH and WAVAX. Swap fees accrue to get more LP tokens over time.",
    tags: ["TRADER JOE", "LP", "JLP", "ETH", "AVAX"],
    underlyingAssets: ["WETH.e", "WAVAX"],
    fee: 10,
  },
  {
    address: "0x9EF73EAcae3B4143cA8A637a16f178F4bDce65a0",
    troveBalance: 0,
    walletBalance: 0,
    token: "AVAX-USDC JLP",
    name: "AVAX-USDC JLP",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0xf4003F4efBE8691B60249E6afbD307aBE7758adb",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Trader Joe",
    protocolLink:
      "https://traderjoexyz.com/pool/AVAX/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e#/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Trader Joe and obtain AVAX-USDC JLP. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "AVAX-USDC JLP is a Liquidity Provider token representing a 50/50 proportion of AVAX and USDC. Swap fees accrue to get more LP tokens over time.",
    tags: ["TRADER JOE", "LP", "JLP", "AVAX", "USDC", "STABLECOIN"],
    underlyingAssets: ["WAVAX", "USDC"],
    fee: 10,
  },
  {
    address: "0x9dd17F32Fc8355aE37425F475A10Cc7BEC8CA36A",
    troveBalance: 0,
    walletBalance: 0,
    token: "av3CRV",
    name: "Aave3Curve",
    isStable: true,
    apr: 0,
    isVault: true,
    underlying: "0x1337BedC9D22ecbe766dF105c9623922A27963EC",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://avax.curve.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Curve and obtain av3CRV. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "av3CRV is a Liquidity Provider token representing a proportion of all the underlying tokens. Swap fees accrue to get more LP tokens over time. CRV token reward amounts are based on governance votes.",
    tags: ["CURVE", "USDC", "USDT", "DAI", "STABLECOIN"],
    underlyingAssets: ["USDC.e", "USDT.e", "DAI.e"],
    fee: 10,
  },
  {
    address: "0x71EbA7AB57a1A03c54878338aA5324056C930605",
    troveBalance: 0,
    walletBalance: 0,
    token: "WBTC",
    name: "Wrapped Bitcoin",
    isStable: false,
    isVault: true,
    underlying: "0x50b7545627a5162F82A992c33b87aDc75187B218",
    underlyingDecimals: 8,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Yeti",
    protocolLink: "",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first obtain WBTC from swaps or other means. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin. Completely transparent. 100% verifiable. Community led.",
    tags: ["BTC"],
    underlyingAssets: ["WBTC.e"],
    fee: 0,
  },
  {
    address: "0x0Ca7B267C3C882e74ba3a4B49AD0e82427dc6A74",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiBTC",
    name: "Benqi Bitcoin",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0xe194c4c5aC32a3C9ffDb358d9Bfd523a0B6d1568",
    underlyingDecimals: 8,
    tokenTooltip: "qiBTC balance is not 1:1 with BTC. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiBTC. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiBTC represents deposited BTC in the Benqi lending protocol. The ratio of qiBTC to BTC increases over time.",
    tags: ["BTC", "BENQI"],
    underlyingAssets: ["WBTC.e"],
    fee: 5,
  },
  {
    address: "0xCc3ee7CCb14aea851850f46CBBE4d82f5D74c20F",
    troveBalance: 0,
    walletBalance: 0,
    token: "sJOE",
    name: "Staked JOE",
    isStable: false,
    apr: 17.26,
    isVault: true,
    underlying: "0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd",
    underlyingDecimals: 18,
    tokenTooltip:
      "Your JOE balance is displayed (JOE deposits will convert to sJOE automatically). ",
    feeTooltip: "Deposit fee does not include a 1% Trader Joe fee",
    additionalFee: 0.01,
    protocol: "Trader Joe",
    protocolLink: "https://traderjoexyz.com/home#/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first obtain JOE from swaps or other means. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "sJOE represents a deposit amount of JOE tokens in the sJOE farm. Swap fees give USDC rewards over time which have to be harvested. ",
    tags: ["TRADERJOE", "JOE"],
    underlyingAssets: ["JOE"],
    fee: 20,
  },
  {
    address: "0x9A6BCecEEc3FBE2FAcC977E57fBe2508f70DBcd9",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiUSDCn",
    name: "Benqi USDCn",
    isStable: true,
    apr: 0,
    isVault: true,
    underlying: "0xB715808a78F6041E46d61Cb123C9B4A27056AE9C",
    underlyingDecimals: 8,
    tokenTooltip: "qiUSDCn balance is not 1:1 with USDC. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiUSDCn. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiUSDCn represents deposited USDCn in the Benqi lending protocol. The ratio of qiUSDCn to USDCn increases over time.",
    tags: ["BENQI", "USDC", "STABLECOIN"],
    underlyingAssets: ["USDC"],
    fee: 20,
  },
  {
    address: "0xCb4B0937904698D6EdDADb2FD3b5FcCE74130c37",
    troveBalance: 0,
    walletBalance: 0,
    token: "sAVAX",
    name: "Staked AVAX",
    isStable: false,
    apr: 5.93,
    isVault: true,
    underlying: "0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain sAVAX. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "sAVAX is a receipt token representing a certain amount of AVAX, in an increasing ratio as AVAX rewards are accrued from securing the network. There is a redemption period of 15 days to withdraw AVAX at the correct ratio, but AVAX and sAVAX are exchangeable on different decentralized exchange.",
    tags: ["AVAX", "BENQI"],
    underlyingAssets: ["WAVAX"],
    fee: 20,
  },
  {
    address: "0xAD69de0CE8aB50B729d3f798d7bC9ac7b4e79267",
    troveBalance: 0,
    walletBalance: 0,
    token: "aUSDC",
    name: "Aave USDC",
    isStable: true,
    apr: 3.37,
    isVault: true,
    underlying: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
    underlyingDecimals: 6,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain aUSDC. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One aUSDC represents one USDC deposited into the Aave lending protocol. The amount of aUSDC increases over time and is redeemable for USDC.",
    tags: ["USDC", "AAVE", "STABLECOIN"],
    underlyingAssets: ["USDC"],
    fee: 20,
  },
  {
    address: "0xF311ff3277d42c354Fe9D76D1e286736861844B5",
    troveBalance: 0,
    walletBalance: 0,
    token: "aWAVAX",
    name: "Aave Wrapped AVAX",
    isStable: false,
    apr: 6.6,
    isVault: true,
    underlying: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain aWAVAX. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One aWAVAX represents one WAVAX deposited into the Aave lending protocol. The amount of aWAVAX increases over time and is redeemable for WAVAX.",
    tags: ["AVAX", "AAVE"],
    underlyingAssets: ["WAVAX"],
    fee: 20,
  },
  {
    address: "0x0Ad0bC8AA6c76b558EE471b7aD70EE7B65704E5D",
    troveBalance: 0,
    walletBalance: 0,
    token: "aWETH",
    name: "Aave Wrapped Ether",
    isStable: false,
    apr: 1.65,
    isVault: true,
    underlying: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain aWETH. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One aWETH represents one WETH deposited into the Aave lending protocol. The amount of aWETH increases over time and is redeemable for WETH.",
    tags: ["AAVE", "ETH"],
    underlyingAssets: ["WETH.e"],
    fee: 20,
  },
  {
    address: "0x6946B0527421b72dF7a5f0C0c7a1474219684e8F",
    troveBalance: 0,
    walletBalance: 0,
    token: "aUSDT",
    name: "Aave Tether",
    isStable: true,
    apr: 5.78,
    isVault: true,
    underlying: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
    underlyingDecimals: 6,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain aUSDT. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One aUSDT represents one USDT deposited into the Aave lending protocol. The amount of aUSDT increases over time and is redeemable for USDT.",
    tags: ["AAVE", "USDT", "STABLE"],
    underlyingAssets: ["USDT"],
    fee: 20,
  },
  {
    address: "0xBa9fb5adBAf7Ad4Ea7B6913A91c7e3196933fC09",
    troveBalance: 0,
    walletBalance: 0,
    token: "aDAI",
    name: "Aave DAI",
    isStable: true,
    apr: 2.21,
    isVault: true,
    underlying: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain aDAI. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One aDAI represents one DAI deposited into the Aave lending protocol. The amount of aDAI increases over time and is redeemable for DAI.",
    tags: ["AAVE", "DAI", "STABLECOIN"],
    underlyingAssets: ["DAI.e"],
    fee: 20,
  },
  {
    address: "0x4353A56822949F534E80f0A595ce4eAb0B5cE23E",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiUSDTn",
    name: "Benqi USDTn",
    isStable: true,
    apr: 0,
    isVault: true,
    underlying: "0xd8fcDa6ec4Bdc547C0827B8804e89aCd817d56EF",
    underlyingDecimals: 8,
    tokenTooltip: "qiUSDTn balance is not 1:1 with USDT. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiUSDTn. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiUSDTn represents deposited USDTn in the Benqi lending protocol. The ratio of qiUSDTn to USDTn increases over time.",
    tags: ["AAVE", "USDT", "STABLECOIN"],
    underlyingAssets: ["USDT"],
    fee: 20,
  },
  {
    address: "0x273ae700E8e6ed0F4B002Bb2D482b192A254A73E",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiDAI",
    name: "Benqi DAI",
    isStable: true,
    apr: 0,
    isVault: true,
    underlying: "0x835866d37AFB8CB8F8334dCCdaf66cf01832Ff5D",
    underlyingDecimals: 8,
    tokenTooltip: "qiDAI balance is not 1:1 with DAI. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiDAI. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiDAI represents deposited DAI in the Benqi lending protocol. The ratio of qiDAI to DAI increases over time. The amount of qiDAI in your wallet stays constant and is redeemable for an increasing amount of DAI as time goes on.",
    tags: ["BENQI", "DAI", "STABLECOIN"],
    underlyingAssets: ["DAI.e"],
    fee: 20,
  },
  {
    address: "0xa1BE5F4e24D7a47c7601A89FA6504d6BaA43802E",
    troveBalance: 0,
    walletBalance: 0,
    token: "GLP",
    name: "GLP",
    isStable: false,
    apr: 0,
    isGated: true,
    isVault: true,
    underlying: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    underlyingDecimals: 18,
    tokenTooltip: "GLP Vault",
    feeTooltip: "",
    protocol: "GMX",
    protocolLink: "https://gmx.io/#/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit GMX and obtain GLP. Then approve the deposit on this site, and deposit. There is a mint period currently of 15 minutes, where after a wallet mints GLP, it must wait 15 minutes before transferring it.",
    underlyingDescription:
      "GLP is a Liquidity Provider token representing a basket of assets. There are target proportions between all the assets, which normally is around 50% stablecoins and 50% non-stablecoin assets. The current assets are USDC, USDC.e, WETH.e, AVAX, WBTC.e, and BTC.b.",
    tags: ["GMX", "GLP"],
    underlyingAssets: ["USDC", "USDC.e", "WETH.e", "WAVAX", "WBTC.e", "BTC.b"],
    fee: 20,
  },
  {
    address: "0xB349115A826a779693a6Fb7FD9Dbfd651A9887aa",
    troveBalance: 0,
    walletBalance: 0,
    token: "BTCb",
    name: "Bitcoin",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    underlyingDecimals: 8,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "",
    protocolLink: "",
    strategy: "This collateral asset is not interest-bearing.",
    instructions:
      "To deposit into this vault, obtain BTC.b, approve the deposit on this site, and deposit.",
    underlyingDescription:
      "BTC.b represents bridged Bitcoin via the Avalanche Bridge.",
    tags: ["BTC.b", "BTC"],
    underlyingAssets: ["BTC.b"],
    fee: 20,
  },
  {
    address: "0x7176FB01Cab1B85F8E2FF9726B4fc8B21d2Be2e9",
    troveBalance: 0,
    walletBalance: 0,
    token: "qiBTCb",
    name: "Benqi Bitcoin",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0x89a415b3D20098E6A6C8f7a59001C67BD3129821",
    underlyingDecimals: 8,
    tokenTooltip: "qiBTC.b balance is not 1:1 with BTC. ",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qiBTC.b. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qiBTC.b represents deposited BTC.b in the Benqi lending protocol. The ratio of qiBTC.b to BTC increases over time.",
    tags: ["BTC", "BTC.b", "qiBTC.b", "BENQI"],
    underlyingAssets: ["qiBTC.b"],
    fee: 20,
  },
  {
    address: "0xe6E15FdfeA6ae45097863E82c98664B161BaA3ed",
    troveBalance: 0,
    walletBalance: 0,
    token: "aBTCb",
    name: "Aave Bitcoin",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0x8ffDf2DE812095b1D19CB146E4c004587C0A0692",
    underlyingDecimals: 8,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain aBTC.b. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One aBTC.b represents one BTC.b deposited into the Aave lending protocol. The amount of aBTC.b increases over time and is redeemable for BTC.b",
    tags: ["aBTC", "aBTC.b", "BTC", "AAVE"],
    underlyingAssets: ["BTC.b"],
    fee: 20,
  },
  {
    address: "0x292d978E677C78E56BFC325AA2D3811f5Eb66F18",
    troveBalance: 0,
    walletBalance: 0,
    token: "qisAVAX",
    name: "Benqi Staked AVAX",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0xF362feA9659cf036792c9cb02f8ff8198E21B4cB",
    underlyingDecimals: 8,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Benqi",
    protocolLink: "https://benqi.fi/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Benqi and obtain qisAVAX. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "qisAVAX represents deposited sAVAX in the Benqi lending protocol. The ratio of qisAVAX.b to sAVAX increases over time.",
    tags: ["qisAVAX", "sAVAX", "AVAX", "Benqi"],
    underlyingAssets: ["qisAVAX"],
    fee: 20,
  },
  {
    address: "0x4A86e96874250683D1225BAf2be716c810e89Ef1",
    troveBalance: 0,
    walletBalance: 0,
    token: "asAVAX",
    name: "Aave Staked AVAX",
    isStable: false,
    apr: 0,
    isVault: true,
    underlying: "0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf",
    underlyingDecimals: 18,
    tokenTooltip: "",
    feeTooltip: "",
    protocol: "Aave",
    protocolLink: "https://aave.com/",
    strategy:
      "Rewards from this asset are compounded automatically. Collateral rewards do not come from the Yeti Finance protocol; all rewards come directly from",
    instructions:
      "To deposit into this vault, first visit Aave and obtain asAVAX. Then approve the deposit on this site, and deposit.",
    underlyingDescription:
      "One asAVAX represents one sAVAX deposited into the Aave lending protocol. The amount of asAVAX increases over time and is redeemable for sAVAX",
    tags: ["asAVAX", "sAVAX", "AVAX", "AAVE"],
    underlyingAssets: ["asAVAX"],
    fee: 20,
  },
];

export const tokenData: TokenData[] = [];
vaultsData.map((token) => {
  if (!token.isVaultOnly) {
    tokenData.push(token);
  }
});

export const tokenDataMappingA: TokenDataMappingA = {};
vaultsData.map((token) => (tokenDataMappingA[token.address] = token));

export const tokenDataMappingT: TokenDataMappingT = {};
vaultsData.map((token) => (tokenDataMappingT[token.token] = token));

export const platforms: string[] = ["TRADERJOE", "AAVE", "BENQI", "GMX"];

export const coinTypes: string[] = [
  "STABLECOIN",
  "USDC",
  "USDT",
  "DAI",
  "AVAX",
  "ETH",
  "BTC",
  "JOE",
];

// export const tags: string[] = platforms.concat(coinTypes);

export interface Item {
  label: string;
  value: string;
}
export const tags: Item[] = [
  { value: "STABLECOIN", label: "STABLECOIN" },
  { value: "USDC", label: "USDC" },
  { value: "USDT", label: "USDT" },
  { value: "DAI", label: "DAI" },
  { value: "AVAX", label: "AVAX" },
  { value: "ETH", label: "ETH" },
  { value: "BTC", label: "BTC" },
  // { value: "JOE", label: "JOE" },
  { value: "TRADERJOE", label: "TRADER JOE" },
  { value: "AAVE", label: "AAVE" },
  { value: "BENQI", label: "BENQI" },
];

export const descriptionMap = new Map();
descriptionMap.set(
  "USDC",
  "USDC is a fully collateralized stablecoin representing one US Dollar, redeemable on a 1:1 basis, provided by Circle. This token is minted directly to the Avalanche blockchain."
);
descriptionMap.set(
  "WETH.e",
  "Wrapped Ether is backed 1 to 1 by Ether, the gas token for the Ethereum blockchain. This token is bridged from Ethereum."
);
descriptionMap.set(
  "WBTC.e",
  "Wrapped Bitcoin is backed 1 to 1 by Bitcoin. This token is bridged from Ethereum."
);
descriptionMap.set(
  "USDC.e",
  "USDC is a fully collateralized stablecoin representing one US Dollar, redeemable on a 1:1 basis, provided by Circle. This token is bridged from Ethereum."
);
descriptionMap.set(
  "WAVAX",
  "Wrapped AVAX is the ERC20 representation of AVAX, the gas token for the Avalanche ecosystem."
);
descriptionMap.set(
  "DAI.e",
  "DAI is an Ethereum based, decentralized, overcollateralized stablecoin from the MakerDAO protocol. The value as one dollar is mostly secured by their Peg Stability Module (PSM) which trades one DAI for one USDC. This token is bridged from Ethereum."
);
descriptionMap.set(
  "JOE",
  "JOE is the governance token of the Trader Joe decentralized exchange protocol. Trader Joe is one of the cornerstone projects on Avalanche."
);
descriptionMap.set(
  "USDT.e",
  "USDT is a fully collateralized stablecoin representing one US Dollar, redeemable on a 1:1 basis, provided by Tether. This token is bridged from Ethereum."
);
descriptionMap.set(
  "USDT",
  "USDT is a fully collateralized stablecoin representing one US Dollar, redeemable on a 1:1 basis, provided by Tether. This token is minted directly to the Avalanche blockchain."
);
descriptionMap.set(
  "BTC.b",
  "BTC.b is backed 1 to 1 by Bitcoin. This token is bridged directly from the Bitcoin chain."
);

// export const contractMap = new Map();
// descriptionMap.set(
//   "USDC",
//   "https://snowtrace.io/token/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e"
// );
// descriptionMap.set(
//   "WETH.e",
//   "https://snowtrace.io/token/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab"
// );
// descriptionMap.set(
//   "WBTC.e",
//   "https://snowtrace.io/token/0x50b7545627a5162f82a992c33b87adc75187b218"
// );
// descriptionMap.set(
//   "USDC.e",
//   "https://snowtrace.io/token/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664"
// );
// descriptionMap.set(
//   "WAVAX",
//   "https://snowtrace.io/token/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7"
// );
// descriptionMap.set(
//   "DAI.e",
//   "https://snowtrace.io/token/0xd586e7f844cea2f87f50152665bcbc2c279d8d70"
// );
// descriptionMap.set(
//   "JOE",
//   "https://snowtrace.io/token/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd"
// );
// descriptionMap.set(
//   "USDT.e",
//   "https://snowtrace.io/token/0xc7198437980c041c805a1edcba50c1ce5db95118"
// );
// descriptionMap.set(
//   "USDT",
//   "https://snowtrace.io/token/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7"
// );
// descriptionMap.set(
//   "BTC.b",
//   "https://snowtrace.io/token/0x152b9d0FdC40C096757F570A51E494bd4b943E50"
// );

export default tokenData;
