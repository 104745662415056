import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AdjustInput, Icon } from "../../../Components";
import { Form } from "react-final-form";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format, formatWithDecimals } from "../../../Utils/number";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { ConnectButton } from "../../../Components/ConnectButton";
import CurrencyConverter from "../../../Components/CurrencyConverter";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { validateSwap } from "../../../Utils/validation";
import ConfirmDepositModal from "../ConfirmDepositModal";

export type PSMCardProps = Record<string, never>;

const selector = ({
  tokenBalances,
  yusdBalance,
  PSMYUSDContractDebt,
  PSMYUSDDebtLimit,
  PSMSwapFee,
}: LiquityStoreState) => ({
  tokenBalances,
  yusdBalance,
  PSMYUSDContractDebt,
  PSMYUSDDebtLimit,
  PSMSwapFee,
});

const PSMCard: React.FC<PSMCardProps> = () => {
  const {
    tokenBalances,
    yusdBalance,
    PSMYUSDContractDebt,
    PSMYUSDDebtLimit,
    PSMSwapFee,
  } = useLiquitySelector(selector);

  const { liquity, account } = useLiquity();

  const { connected } = useWeb3Context();

  const usdcBalance = formatWithDecimals(
    tokenBalances["0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"],
    6
  );

  // Sets max deposit to whichever is lower: USDC or YUSD
  const getMaxDeposit = () => {
    if (usdcBalance < format(yusdBalance)) {
      return usdcBalance;
    }
    return format(yusdBalance);
  };

  //Swap rate (.01%)
  const swapRate = Number(PSMSwapFee) * 1e14;

  // Calculate YUSD mint capacity
  const yusdMintCapacity =
    Number(PSMYUSDDebtLimit) - Number(PSMYUSDContractDebt);

  // Get max USDC deposit
  const getMaxUSDCDeposit = () => {
    if (format(yusdMintCapacity) < format(usdcBalance)) {
      return yusdMintCapacity;
    }
    return format(usdcBalance);
  };

  const maxUSDCdepsit = getMaxUSDCDeposit();

  // Get max YUSD deposit
  const getMaxYUSDDeposit = () => {
    if (Number(PSMYUSDContractDebt) < format(yusdBalance)) {
      return format(PSMYUSDContractDebt);
    }
    return format(yusdBalance);
  };

  // Toggles between depositing YUSD and USDC. Default option is USDC
  const [flipped, setFlipped] = useState(false);
  const toggleFlip = () => setFlipped((value) => !value);

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const toast = useToast();

  const validate = (value: number) => {
    flipped
      ? validateSwap(toast, format(yusdBalance), 0, value, onConfirmOpen)
      : validateSwap(toast, format(usdcBalance), 0, value, onConfirmOpen);
    // validateRedeem(toast, 100000000, 0, value, onConfirmOpen);
  };

  return (
    <>
      <Box fontSize="15px" color="text-primary" fontWeight="500">
        <Form
          onSubmit={() => undefined}
          render={({ values, form }) => (
            <>
              {isConfirmOpen && (
                <ConfirmDepositModal
                  isOpen={isConfirmOpen}
                  onClose={onConfirmClose}
                  amount={flipped ? values.yusdPSMInput : values.usdcPSMInput}
                  values={values}
                  flipped={flipped}
                  swapRate={swapRate}
                />
              )}
              <Box
                layerStyle="card"
                maxW={{ base: "full", md: "400px" }}
                p="24px"
                gap="16px"
                display="flex"
                flexDir="column"
                borderBottomRadius={
                  values.yusdPSMInput > 0 || values.usdcPSMInput > 0
                    ? "0px"
                    : "14px"
                }
              >
                <Box>
                  <Text>You Pay</Text>
                  {flipped ? (
                    <AdjustInput
                      max={getMaxYUSDDeposit()}
                      name="yusdPSMInput"
                      token="YUSD"
                      showToken
                      psm
                      transactionId="yusdPSMInput"
                      form={form}
                      walletBalance={format(yusdBalance)}
                    />
                  ) : (
                    <AdjustInput
                      max={getMaxUSDCDeposit()}
                      name="usdcPSMInput"
                      token="USDC"
                      showToken
                      psm
                      transactionId="usdcPSMInput"
                      form={form}
                      walletBalance={usdcBalance}
                    />
                  )}
                </Box>

                {/* Flip Button */}
                <Box>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    // animate={{ : flipped ? 180 : 0 }}
                    whileTap={{ scale: 0.94 }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      h="40px"
                      w="40px"
                      rounded="8px"
                      bg="surface"
                      borderWidth="1px"
                      borderColor="border"
                      position="absolute"
                      _hover={{
                        bg: "primary-default",
                        shadow: "sm",
                        color: "#DFE3E9",
                      }}
                      _active={{ bg: "primary-hover" }}
                      cursor="pointer"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      onClick={toggleFlip}
                      color="text-primary"
                    >
                      <ArrowDownIcon boxSize="20px" />
                    </Box>
                  </motion.div>
                  <Divider color="border" />
                </Box>

                <Box>
                  <Text>You Receive</Text>
                  <Box display="flex" justifyContent="space-between" mt="16px">
                    <Box w="full" mr="8px" cursor="not-allowed">
                      <Box>
                        {values.yusdPSMInput > 0 || values.usdcPSMInput > 0 ? (
                          <Text
                            fontSize="24px"
                            color="text-primary"
                            letterSpacing="tight"
                            fontWeight="400"
                          >
                            {flipped
                              ? values.yusdPSMInput -
                                values.yusdPSMInput * swapRate
                              : values.usdcPSMInput -
                                values.usdcPSMInput * swapRate}
                          </Text>
                        ) : (
                          <Text fontSize="24px" color="text-secondary">
                            0
                          </Text>
                        )}
                        <CurrencyConverter
                          value={
                            flipped ? values.yusdPSMInput : values.usdcPSMInput
                          }
                          token={flipped ? "USDC" : "YUSD"}
                          currency={"USD"}
                          fontWeight="500"
                          fontSize="13px"
                          style={{
                            color: "text-secondary",
                          }}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" flexDir="column" alignItems="flex-end">
                      <Box
                        display="flex"
                        fontSize="12px"
                        color="text-secondary"
                        mb="8px"
                        gap="6px"
                        cursor="not-allowed"
                      >
                        <Text>Available</Text>
                        <Text>
                          {flipped
                            ? Number(PSMYUSDContractDebt)
                            : Number(yusdMintCapacity) < 0
                            ? 0
                            : Number(yusdMintCapacity)}
                        </Text>
                      </Box>

                      <Box display="flex" gap="4px" alignItems="center">
                        <Icon
                          iconName={flipped ? "USDC" : "YUSD"}
                          boxSize="24px"
                        />
                        <Text textStyle="number_base">
                          {flipped ? "USDC" : "YUSD"}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Drop-down confirmation button when user enters any number */}
              {values.yusdPSMInput > 0 || values.usdcPSMInput > 0 ? (
                <motion.div
                  initial={{ y: -20 }}
                  animate={{ y: 0 }}
                  transition={{
                    duration: 0.3,
                    delay: 0,
                    ease: "easeOut",
                  }}
                >
                  <Box
                    layerStyle="card"
                    p="24px"
                    pt="8px"
                    borderTopRadius="0px"
                  >
                    {connected ? (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.7 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.26,
                          delay: 0.04,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontSize="12px"
                          color="text-secondary"
                          mb="16px"
                        >
                          <Text>Swap fee:</Text>
                          <CurrencyConverter
                            value={
                              flipped
                                ? values.yusdPSMInput * swapRate
                                : values.usdcPSMInput * swapRate
                            }
                            token={flipped ? "USDC" : "YUSD"}
                            currency={"USD"}
                            fontWeight="500"
                            fontSize="12px"
                            style={{
                              color: "text-secondary",
                            }}
                          />
                          {/* {flipped ? <Text>${(swapFee * values.yusdPSMInput).toFixed(4)}</Text>  : <Text>${(swapFee * values.usdcPSMInput).toFixed(4)}</Text>  } */}
                        </Box>
                        <Box display="flex">
                          <Button
                            w="full"
                            height="52px"
                            variant="newPrimary"
                            // disabled={!hasAllowance}
                            // onClick={() => {
                            //   flipped
                            //     ? validate(values.yusdPSMInput * swapRate)
                            //     : validate(values.usdcPSMInput * swapRate);
                            // }}
                            onClick={() => {
                              flipped
                                ? validate(values.yusdPSMInput)
                                : validate(values.usdcPSMInput);
                              console.log(
                                "YUSD deposit amount:",
                                values.yusdPSMInput,
                                "USDC deposit amount:",
                                values.usdcPSMInput,
                                "Flipped?",
                                flipped
                              );
                            }}
                          >
                            Swap
                          </Button>
                        </Box>
                      </motion.div>
                    ) : (
                      <ConnectButton w="full" />
                    )}
                  </Box>
                </motion.div>
              ) : (
                <></>
              )}
            </>
          )}
        />
      </Box>
    </>
  );
};

export default PSMCard;
