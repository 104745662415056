import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Input,
  // NumberInput,
  // NumberInputField,
  Box,
  Text,
  Spacer,
  useTheme,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Divider,
  Hide,
} from "@chakra-ui/react";
import { Icon, Loader } from "../../../Components";
import { Decimal, LiquityStoreState, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import tokenData, { tokenDataMappingA } from "../../../TokenData";
import { format, getNum, formatWithDecimals } from "../../../Utils/number";
import setupWeb3 from "./utils/setupWeb3";
import getTroveHistory from "./utils/getTroveHistory";
import getTokenName from "./utils/getTokenName";
import { getCalculatedTroveHistoryDay } from "./utils/getCalculatedTroveHistoryDay";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { ChevronRightIcon, SearchIcon } from "@chakra-ui/icons";
import TroveSummaryModal from "./TroveSummary/TroveSummaryModal";
// import TroveSummaryModal from "./TroveSummary/TroveSummaryModal";
import { motion } from "framer-motion";

const selector = ({
  trove,
  prices,
  tokenBalances,
  total,
  safetyRatios,
  recoveryRatios,
  YUSDPrice,
  icr,
  decimals,
  farm,
  yusdInStabilityPool,
  underlyingPerReceiptRatios,
}: LiquityStoreState) => ({
  trove,
  prices,
  tokenBalances,
  total,
  safetyRatios,
  recoveryRatios,
  YUSDPrice,
  icr,
  decimals,
  farm,
  yusdInStabilityPool,
  underlyingPerReceiptRatios,
});

export type TroveHistoryData = {
  tokens: string[];
  amounts: number[];
  realAmounts: number[];
  values: number[];
  collsIn: string[];
  amountsIn: number[];
  realAmountsIn: number[];
  valuesIn: number[];
  collsOut: string[];
  amountsOut: number[];
  realAmountsOut: number[];
  valuesOut: number[];
  currentICR: number;
  YUSDchange: number;
  blockNum: number;
  totalValue: number;
  totalValueChange: number;
  debt: number;
  isDebtIncrease: boolean;
  operation: string;
  transaction: string;
  timestamp: number;
  vaultData: any;
  interest: number;
};

export type DayHistory = {
  day: string;
  data: Array<TroveHistoryData>;
};

const TroveHistory: React.FC = () => {
  const [web3, setWeb3Provider] = useState<any>(null);

  useEffect(() => {
    const getWeb3Provider = async () => {
      // console.log('setting web3')
      setWeb3Provider((await setupWeb3()).avax_web3);
    };
    getWeb3Provider();
  }, []);

  const { account } = useLiquity();

  // const userID = "0x7cdb2a7107b49230197bb620ef23f99ac3574133"

  const [idx, setIdx] = useState<number>(0);

  const [maxIdx, setMaxIdx] = useState<number>(0);

  const [pageLength, setPageLength] = useState<number>(-1);

  const dataLengthPerPage = 10;

  const [loading, setLoading] = useState<boolean>(true);

  const [fetchedHistory, setFetchedHistory] = useState<Array<any>>([]);

  const [indexedHistory, setIndexedHistory] = useState<
    Map<number, Array<TroveHistoryData>>
  >(new Map<number, Array<TroveHistoryData>>());

  const [cachedHistory, setCachedHistory] = useState<Map<number, Array<any>>>(
    new Map<number, Array<any>>()
  );

  // Get Full Trove History

  const getIndexedTroveHistory = async (userID: string) => {
    cachedHistory.clear();

    const array: any = await getTroveHistory(userID);

    setFetchedHistory(array);

    // console.log('got array', array)

    return array;
  };

  const onClickLeft = () => {
    if (idx > 0) {
      setIdx(idx - 1);
      setIdxInput(idx);
    }
  };

  const onClickRight = () => {
    if (idx + 1 < pageLength) {
      setIdx(idx + 1);
      setIdxInput(idx + 2);
      if (idx + 1 > maxIdx) {
        setMaxIdx(idx + 1);
      }
    }
  };

  const [userID, setuserID] = useState<string>(account);

  useEffect(() => {
    const getNextHistoryBatch = async () => {
      setLoading(true);

      const getSetFullHistory = async () => {
        if (loading) {
          const fetchedHistoryResult = await getIndexedTroveHistory(userID);
          return fetchedHistoryResult;
        }
        return fetchedHistory;
      };

      const getIndexedHistory = async () => {
        if (indexedHistory.size > 0) {
          return indexedHistory;
        }
        // console.log('fetching new data')
        const rawHistory = new Map<number, Array<TroveHistoryData>>();
        const fullHistory = await getSetFullHistory();
        let index = 0;
        let dataCount = 0;
        let batch: Array<TroveHistoryData> = [];
        for (let i = 0; i < fullHistory.length; i++) {
          const dayHistory = fullHistory[i][1];
          for (let j = 0; j < dayHistory.length; j++) {
            if (dataCount >= dataLengthPerPage) {
              rawHistory.set(index, batch);
              index += 1;
              batch = [];
              dataCount = 0;
            }
            batch.push(dayHistory[j]);
            dataCount += 1;
          }
        }
        if (batch.length > 0) {
          rawHistory.set(index, batch);
        }
        // console.log('setting page length', rawHistory.size)
        setPageLength(rawHistory.size);
        setIndexedHistory(rawHistory);
        return rawHistory;
      };

      const rawHistory = await getIndexedHistory();

      const currentBatch: Array<TroveHistoryData> =
        new Array<TroveHistoryData>();

      if (rawHistory.size == 0) {
        console.log("No data");
        setLoading(false);
        return;
      }

      const rawBatch: any = rawHistory.get(idx);
      // console.log('rawBatch', rawBatch)
      for (let j = 0; j < rawBatch.length; j++) {
        const dayEntry = await getCalculatedTroveHistoryDay(
          web3,
          userID,
          rawBatch[j],
          safetyRatios
        );
        // console.log("day Entry", dayEntry);
        currentBatch.push(dayEntry);
      }

      // console.log('updating cachedHistory', cachedHistory.set(idx, currentBatch))

      // console.log('should display', cachedHistory.get(idx))

      setCachedHistory(cachedHistory.set(idx, currentBatch));

      setLoading(false);
    };

    // console.log(loading, cachedHistory.has(idx))

    if (!cachedHistory.has(idx) || loading) {
      // console.log('fetching data', cachedHistory)

      getNextHistoryBatch().then(() => setLoading(false));
    }
  }, [idx, userID]);

  const [idxInput, setIdxInput] = useState<number>(1);

  const handleChange = (idxInput: any) => {
    setIdxInput(Number(idxInput));
  };

  const onClickGo = () => {
    // console.log('click Go', idxInput - 1, pageLength)
    if (idxInput - 1 < pageLength) {
      setIdx(idxInput - 1);
      if (idxInput - 1 > maxIdx) {
        // console.log('click go max', idxInput - 1)
        setMaxIdx(idxInput - 1);
      }
    }
  };

  const {
    trove,
    prices,
    tokenBalances,
    total,
    safetyRatios,
    recoveryRatios,
    YUSDPrice,
    icr,
    decimals,
    farm,
    yusdInStabilityPool,
    underlyingPerReceiptRatios,
  } = useLiquitySelector(selector);
  const { liquity } = useLiquity();
  const { yeti } = useTheme();

  // console.log('YUSDPrice', +String(YUSDPrice))
  tokenData.map(
    (token) =>
      (token["troveBalance"] = formatWithDecimals(
        trove.collaterals[token.address],
        decimals[token.address].toNumber()
      ))
  );
  tokenData.map(
    (token) =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[
          token.underlying == "" || token.isGated
            ? token.address
            : token.underlying
        ],
        token.underlyingDecimals
      ))
  );

  const depositedCollateral = tokenData.filter(
    (token) => token.walletBalance !== 0 || token.troveBalance !== 0
  );

  const heldCollateral = tokenData.filter((token) => token.troveBalance !== 0);

  const collateralizationRatio = format(icr) * 100;

  const totalBorrowed = format(trove.debt["debt"]);

  const ratioMapping: TroveMappings = {};
  const [ratios, setRatios] = useState<TroveMappings>(ratioMapping);

  let vcValue = 0;
  let usdValue = 0;
  let stableVC = 0;
  let stableUSD = 0;
  tokenData.map((token) => {
    const curBal: Decimal = trove.collaterals[token.address];
    let vc: number;
    let usd: number;
    const safetyRatio = format(safetyRatios[token.address]);
    const dec = decimals[token.address].toNumber();
    if (curBal != undefined) {
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(curBal, dec);
      // console.log(token.token +'ddd', vc)
      vcValue += vc;
      usd = format(prices[token.address]) * formatWithDecimals(curBal, dec);
      usdValue += usd;
    } else {
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(trove.collaterals[token.address], dec);
      vcValue += vc;

      usd =
        format(prices[token.address]) *
        formatWithDecimals(trove.collaterals[token.address], dec);
      usdValue += usd;
    }
    if (token.isStable) {
      stableVC += vc;
      stableUSD += usd;
    }
  });
  // console.log('vcValue summary', vcValue)

  const troveHealth =
    stableVC * 1.1 > totalBorrowed && stableVC / vcValue > 0.99
      ? 200 - (200 - 110) * Math.exp((-1 / 9) * (collateralizationRatio - 110))
      : collateralizationRatio;

  const getCollateralRatioDisplay = (collateralRatio: number) => {
    if (collateralRatio < 125) {
      return ["RedThermometer", "red.500"];
    } else if (collateralRatio < 165) {
      return ["YellowThermometer", "yellow.500"];
    } else {
      return ["GreenThermometer", "green.500"];
    }
  };

  const getTroveRiskynessMsg = () => {
    const riskeyness =
      ((vcValue - stableVC) / (totalBorrowed - stableVC)) * 100;
    const precentStable = (stableVC / vcValue) * 100;
    let safteyLable: string;
    let amountRoom: string;
    if (collateralizationRatio === 0) {
      return "";
    }
    if (stableUSD > totalBorrowed) {
      if ((collateralizationRatio * precentStable) / 100 < 112) {
        safteyLable = "risky";
        amountRoom = "not much";
      } else if ((collateralizationRatio * precentStable) / 100 < 114) {
        safteyLable = "medium risk";
        amountRoom = "some";
      } else {
        safteyLable = "safe";
        amountRoom = "a lot of";
      }
    } else if (riskeyness < 130) {
      safteyLable = "risky";
      amountRoom = "not much";
    } else if (riskeyness < 170) {
      safteyLable = "low-medium risk";
      amountRoom = "some";
    } else {
      safteyLable = "safe";
      amountRoom = "a lot of";
    }
    return `Your trove is comprised of ${precentStable.toFixed(3)}% stables${
      riskeyness > 0
        ? `, with an ICR without stable coins of ${riskeyness.toFixed(3)}%.`
        : "."
    } We deem this as ${safteyLable} since there is ${amountRoom} room for your collateral prices to fall before reaching the liquidation threshold of 110%.`;
  };

  let totalSystemVC = 0;
  let totalSystemUSD = 0;
  let totalStableUSD = 0;

  Object.keys(total.collaterals).map((address) => {
    const amountUSD = format(
      total.collaterals[address]
        .mul(10 ** (18 - format(decimals[address])))
        .mul(prices[address])
        .div(1e18)
    );
    totalSystemVC += amountUSD * format(recoveryRatios[address]);
    totalSystemUSD += amountUSD;
    if (
      tokenDataMappingA[address] !== undefined &&
      tokenDataMappingA[address].isStable
    ) {
      totalStableUSD += amountUSD;
    }
  });
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  // console.log('cachedHistory', cachedHistory)

  const [summaryData, setSummaryData] = useState<any>(null);

  // const allTokens: string[] = Object.assign(
  //   [],
  //   summaryData["collsIn"].concat(summaryData["collsOut"])
  // );

  const {
    isOpen: isSummaryOpen,
    onOpen: onSummaryOpen,
    onClose: onSummaryClose,
  } = useDisclosure();

  const [activeIndex, setActiveIndex] = useState(-1);

  function trclick(history: any) {
    // console.log('setting summary', history)
    setSummaryData(history);
    onSummaryOpen();
    // console.log('clicked')
  }

  const getFormattedDate = (date: Date) => {
    // console.log('date', date.toLocaleString() )
    const options: any = {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    };
    const string = date.toLocaleString("en-US", options).split(" ");
    return (
      string[0].slice(0, 3) +
      " " +
      string[1] +
      ", " +
      string[3] +
      " " +
      string[4]
    );
  };

  // {Number(history["YUSDchange"]) / 10 ** 18 >
  //                                   0 ? (

  const getTransactionType = (
    operation: string,
    collsIn: string[],
    collsOut: string[],
    debt: number
  ) => {
    switch (operation) {
      case "liquidateInNormalMode":
        return (
          <Text px="8px" py="2px" bg="red.400" rounded="full">
            Liquidated
          </Text>
        );
      case "adjustTrove":
        if (collsIn.length == 0 && collsOut.length == 0) {
          return (
            <Text px="8px" py="2px" bg="green.400" rounded="full">
              Change Debt
            </Text>
          );
        }
        return (
          <Text px="8px" py="2px" bg="primary-default" rounded="full">
            Adjust Trove
          </Text>
        );
      case "redeemCollateral":
        if (debt == 0) {
          return (
            <Text px="8px" py="2px" bg="red.400" rounded="full">
              Closed by Redemption
            </Text>
          );
        }
        return (
          <Text px="8px" py="2px" bg="yellow.400" rounded="full">
            Partially Redeemed
          </Text>
        );
      case "openTrove":
        return (
          <Text px="8px" py="2px" bg="green.400" rounded="full">
            Open Trove
          </Text>
        );
      case "closeTrove":
        return (
          <Text px="8px" py="2px" bg="red.400" rounded="full">
            Close Trove
          </Text>
        );
      default:
        return (
          <Text px="8px" py="2px" bg="surface-highlight" rounded="full">
            Undefined
          </Text>
        );
    }
  };

  const [idInput, setIdInput] = useState<string>("");

  const handleIdChange = (event: any) => {
    // console.log('id change')
    setIdInput(event.target.value);
  };

  const onClickSearch = () => {
    // console.log('search')
    setLoading(true);
    if (idInput == "") {
      setuserID(account);
    } else {
      indexedHistory.clear();
      // console.log('searching!')
      setuserID(idInput);
    }
    setIdx(0);
    setIdxInput(1);
  };

  const getDebtChange = (change: number) => {
    if (change == 0) {
      return (
        <Box>
          <Text
            textStyle="number_base"
            color="text-primary"
            textAlign={["right", "left"]}
          >
            --
          </Text>
        </Box>
      );
    } else {
      return getNum(Number(change), 3);
    }
  };

  // {history["totalValueChange"] == 0 ? (
  //   <Flex> -- </Flex>
  // ) : (
  //   <Flex>
  //     {history["totalValueChange"] > 0 ? (
  //       <Text
  //         color="green.400"
  //         textAlign={["right", "left"]}
  //       >
  //         {" "}
  //         +$
  //         {Number(history["totalValueChange"])
  //           .toFixed(2)
  //           .replace(
  //             /\B(?=(\d{3})+(?!\d))/g,
  //             ","
  //           )}
  //       </Text>
  //     ) : (
  //       <Text
  //         textStyle="subtitle3"
  //         color="red.400"
  //         textAlign={["right", "left"]}
  //       >
  //         {" "}
  //         -$
  //         {(
  //           -1 *
  //           Number(
  //             history["totalValueChange"]
  //           )
  //         )
  //           .toFixed(2)
  //           .replace(
  //             /\B(?=(\d{3})+(?!\d))/g,
  //             ","
  //           )}
  //       </Text>
  //     )}
  //   </Flex>
  // )}

  // console.log('history', cachedHistory)

  const [isOpen, setIsOpen] = useState(false);

  const [clicked, setClicked] = useState("0");

  const [hover, setHover] = useState(null);

  const [history, setHistory] = useState(false);

  return (
    <>
      <Box w="full" layerStyle="card">
        <Box display="flex" justifyContent="space-between" p="24px">
          <Box display="flex" w="full">
            <Text textStyle="card_header">Transactions</Text>
          </Box>
          <Hide below="sm">
            <Box display="flex" w="600px">
              <InputGroup h="32px" rounded="8px">
                <InputLeftElement pointerEvents="none" h="full">
                  <SearchIcon color="text-tertiary" />
                </InputLeftElement>
                <Input
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onClickSearch();
                    }
                  }}
                  textStyle="text_sm"
                  w="full"
                  h="full"
                  // placeholder={"Account: ".concat(account)}
                  placeholder="Search an account"
                  value={idInput}
                  onChange={handleIdChange}
                />
              </InputGroup>
              {/* <Button
              variant="surface"
              onClick={onClickSearch}
              disabled={pageLength > 0 && !cachedHistory.has(idx)}
            >
              {" "}
              <Text textStyle="text_md"> {"Search"} </Text>{" "}
            </Button> */}
            </Box>
          </Hide>
        </Box>

        {pageLength == 0 ? (
          <Box
            py="64px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            borderTop="1px"
            borderColor="border"
            px="24px"
          >
            <Text textStyle="text_md">No borrow history yet.</Text>
          </Box>
        ) : (
          <>
            {!cachedHistory.has(idx) ? (
              <Flex flexDirection="column" alignItems="center" gap={4}>
                <Loader />
                <Text textStyle="text_sm" color="text-secondary">
                  Fetching Data...{" "}
                </Text>
              </Flex>
            ) : (
              <>
                {history ? (
                  <Box
                    onClick={() => {
                      setHistory(false);
                    }}
                  >
                    {summaryData != null && (
                      <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ ease: "easeOut", duration: 0.2 }}
                      >
                        <TroveSummaryModal
                          // isOpen={isSummaryOpen}
                          // onClose={onSummaryClose}
                          data={summaryData}
                        />
                      </motion.div>
                    )}
                  </Box>
                ) : (
                  <>
                    <Box
                      display="flex"
                      pb="16px"
                      px="32px"
                      textStyle="subheading"
                      color="text-secondary"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex">
                        <Text>Assets</Text>
                      </Box>
                      <Box display="flex" justifyContent="flex-end">
                        <Hide below="md">
                          <Box display="flex">
                            <Text>Transaction</Text>
                          </Box>
                          <Box
                            w="180px"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Text>Final Collateral</Text>
                          </Box>
                          <Box
                            w="180px"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Text>Final Debt</Text>
                          </Box>
                          <Box
                            w="160px"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Text>ICR</Text>
                          </Box>
                        </Hide>
                        <Box w="200px" display="flex" justifyContent="flex-end">
                          <Text>Date</Text>
                        </Box>
                        <Box w="80px" />
                      </Box>
                      {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
                    </Box>
                    {cachedHistory.get(idx)!.map((history, index) => {
                      // const isActive = index === activeIndex;
                      // const { id } = history;
                      return (
                        // <Accordion key={index} allowToggle p="0px" m="0px">
                        <Box
                          p="0px"
                          mx="16px"
                          border="none"
                          borderWidth="0px"
                          key={index}
                        >
                          <Box
                            px="16px"
                            m="0px"
                            border="none"
                            borderWidth="0px"
                            _focus={{ border: "none" }}
                            // _expanded={{ bg: "surface-highlight" }}
                            display="flex"
                            // onMouseEnter={() => setActiveIndex(index)}
                            // onMouseLeave={() => setActiveIndex(-1)}
                            // w="full"
                            // px="24px"
                            // my="4px"
                            // rounded="6px"
                            _expanded={{
                              bg: "surface-hover",
                              borderBottomRadius: "0px",
                            }}
                            // onClick={() => trclick(history)}
                            onClick={() => {
                              setActiveIndex(index);
                              trclick(history);
                              setHistory(true);
                            }}
                            rounded="8px"
                            _hover={{ bg: "surface-hover" }}
                            // fontSize="15px"
                            alignItems="center"
                            h="52px"
                            // borderTop="1px"
                            // textStyle="number_base"
                            fontSize="14px"
                            letterSpacing="tight"
                            fontWeight="500"
                            borderColor="border"
                            // justifyContent="space-between"
                            cursor="pointer"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center">
                              {history["collsIn"].map((token: string) => {
                                return (
                                  <Icon
                                    key={token}
                                    iconName={getTokenName(token)}
                                    h="24px"
                                    w="24px"
                                    // mr="-10px"
                                    borderColor="surface"
                                    borderWidth="1px"
                                    rounded="full"
                                    bg="surface"
                                    ml="-10px"
                                  />
                                );
                              })}
                              {history["collsOut"].map((token: string) => {
                                return (
                                  <Icon
                                    key={token}
                                    iconName={getTokenName(token)}
                                    h="24px"
                                    w="24px"
                                    // mr="-10px"
                                    ml="-10px"
                                    borderColor="surface"
                                    borderWidth="1px"
                                    rounded="full"
                                    bg="surface"
                                  />
                                );
                              })}
                              {Number(history["YUSDchange"]) / 10 ** 18 > 0 ? (
                                <>
                                  <Icon
                                    iconName={"YUSD"}
                                    h="24px"
                                    w="24px"
                                    ml="-10px"
                                    borderColor="surface"
                                    borderWidth="1px"
                                    rounded="full"
                                    bg="surface"
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              {/* <Text>{history['totalValueChange']} YUSD</Text> */}
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              {" "}
                              <Text
                                noOfLines={1}
                                fontSize="12px"
                                color="#DFE3E9"
                              >
                                {" "}
                                {getTransactionType(
                                  history["operation"],
                                  history["collsIn"],
                                  history["collsOut"],
                                  Number(history["debt"])
                                )}{" "}
                              </Text>
                              <Hide below="md">
                                <Box
                                  display="flex"
                                  flexDir="column"
                                  w="180px"
                                  alignItems="flex-end"
                                  justifyContent="center"
                                >
                                  $
                                  {Number(history["totalValue"])
                                    .toFixed()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    fontSize="13px"
                                  >
                                    {history["totalValueChange"] == 0 ? (
                                      <Flex> -- </Flex>
                                    ) : (
                                      <Flex>
                                        {history["totalValueChange"] > 0 ? (
                                          <Text
                                            color="green.400"
                                            textAlign={["right", "left"]}
                                          >
                                            {" "}
                                            +$
                                            {Number(history["totalValueChange"])
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </Text>
                                        ) : (
                                          <Text
                                            textStyle="subtitle3"
                                            color="red.400"
                                            textAlign={["right", "left"]}
                                          >
                                            {" "}
                                            -$
                                            {(
                                              -1 *
                                              Number(
                                                history["totalValueChange"]
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </Text>
                                        )}
                                      </Flex>
                                    )}
                                  </Box>
                                </Box>
                              </Hide>
                              {/* <Hide below="sm"> */}
                              <Hide below="md">
                                <Box
                                  display="flex"
                                  flexDir="column"
                                  justifyContent="center"
                                  w="180px"
                                  alignItems="flex-end"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="2px"
                                  >
                                    <Icon
                                      iconName={"YUSD"}
                                      boxSize="14px"
                                      pt="1px"
                                    />
                                    {Number(history["debt"] * 10 ** -18)
                                      .toFixed()
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}{" "}
                                  </Box>
                                  <Box display="flex" gap="2px" fontSize="13px">
                                    {Number(history["YUSDchange"]) / 10 ** 18 >
                                    0 ? (
                                      Number(history["isDebtIncrease"]) ? (
                                        <Text color="green.400">
                                          +
                                          {getDebtChange(
                                            Number(history["YUSDchange"]) /
                                              10 ** 18
                                          )}
                                        </Text>
                                      ) : (
                                        <Text color="red.400">
                                          -
                                          {getDebtChange(
                                            Number(history["YUSDchange"]) /
                                              10 ** 18
                                          )}
                                        </Text>
                                      )
                                    ) : (
                                      <Text color="text-secondary">--</Text>
                                    )}
                                  </Box>
                                </Box>
                              </Hide>
                              {/* </Hide> */}
                              <Hide below="lg">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-end"
                                  w="160px"
                                >
                                  {getNum(history["currentICR"] * 10 ** -16, 2)}
                                </Box>
                              </Hide>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                w={{ base: "120px", md: "200px" }}
                              >
                                {getFormattedDate(
                                  new Date(history["timestamp"] * 1000)
                                )}
                              </Box>
                              <Hide below="sm">
                                <Box
                                  display="flex"
                                  w="80px"
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  {/* <AccordionIcon /> */}
                                  <ChevronRightIcon />
                                </Box>
                              </Hide>
                              {/* <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="40px"
0                        >
                          <Link
                            color="text-secondary"
                            pl="4px"
                            textDecorationLine="underline"
                            _hover={{ color: "primary-default" }}
                            textStyle="subtitle3"
                            href={
                              "https://snowtrace.io/tx/" +
                              history["transaction"]
                            }
                            isExternal
                          >
                            <ExternalLinkIcon mb={1}> </ExternalLinkIcon>
                          </Link>
                        </Box> */}
                            </Box>
                          </Box>
                          {/* <AccordionPanel p="0px" m="0px">
                            <Box bg="surface-hover" borderBottomRadius="6px">
                              {summaryData != null && (
                                <TroveSummaryModal
                                  // isOpen={isSummaryOpen}
                                  // onClose={onSummaryClose}
                                  data={summaryData}
                                />
                              )}
                            </Box> */}
                          {/* <Box display="flex" flexDir="column">
                            <Text>tx: {history.transaction}</Text>
                            <Text>Collateral Deposits</Text>
                            {history["collsIn"].map((token: string) => {
                              return (
                                <>
                                  <Box
                                    key={token}
                                    display="flex"
                                    h="42px"
                                    borderTop="1px"
                                    borderColor="border"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Box display="flex">
                                      <Icon
                                        iconName={getTokenName(token)}
                                        h={5}
                                        w={5}
                                        mr={3}
                                      />
                                      <Text>{getTokenName(token)}</Text>
                                      {getNum(
                                Number(history["totalValueChange"]),
                                2
                              )}
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-end"
                                      w="200px"
                                    >
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                            <Text>Collateral Withdraws</Text>
                            {history["collsOut"].map((token: string) => {
                              return (
                                <Box
                                  key={token}
                                  display="flex"
                                  h="80px"
                                  borderTop="1px"
                                  borderColor="border"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Text>Withdraw</Text>

                                  <Icon
                                    iconName={getTokenName(token)}
                                    h={5}
                                    w={5}
                                    mr={3}
                                  />
                                </Box>
                              );
                            })}
                            <Text>Final debt:</Text>
                            {Number(history["debt"] * 10 ** -18)
                              .toFixed()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            YUSD
                          </Box> */}
                          {/* </AccordionPanel> */}
                          <Box mx="16px">
                            <Divider color="border" />
                          </Box>
                        </Box>
                        // </Accordion>
                      );
                    })}
                    {pageLength > 0 && (
                      <Box
                        display="flex"
                        borderTop="1px"
                        borderColor="border"
                        px="24px"
                        justifyContent="space-between"
                        py="8px"
                        fontSize="14px"
                        fontWeight="500"
                      >
                        <Box display="flex" alignItems="center">
                          <Text>Page {idxInput}</Text>

                          {/* <NumberInput
                            size="s"
                            maxW="40px"
                            mr="2"
                            value={idxInput}
                            min={1}
                            max={pageLength}
                            onChange={handleChange}
                          >
                            {" "}
                            <NumberInputField
                              textAlign="center"
                              textStyle="text_md"
                            ></NumberInputField>
                          </NumberInput> */}
                          <Text> of {pageLength} </Text>
                        </Box>
                        {/* <Button
                          mr={2}
                          variant="surface"
                          onClick={onClickGo}
                          disabled={!loading && !cachedHistory.has(idx)}
                        >
                          {" "}
                          <Text textStyle="text_md"> {"Go"} </Text>{" "}
                        </Button> */}

                        <Spacer />
                        <Button
                          mr={2}
                          variant="unstyled"
                          width="80px"
                          onClick={onClickLeft}
                          disabled={idx == 0 || !cachedHistory.has(idx)}
                        >
                          {" "}
                          <Text
                            _hover={{ color: "primary-default" }}
                            cursor="pointer"
                            bg="surface-hover"
                            py="3px"
                            px="6px"
                            borderRadius="6px"
                            borderColor="gray.600"
                            shadow="sm"
                            fontSize="14px"
                            fontWeight="500"
                          >
                            {" "}
                            Previous{" "}
                          </Text>{" "}
                        </Button>
                        <Button
                          variant="unstyled"
                          onClick={onClickRight}
                          disabled={
                            !cachedHistory.has(idx) || idx + 1 >= pageLength
                          }
                        >
                          <Box
                            _hover={{ color: "primary-default" }}
                            cursor="pointer"
                            bg="surface"
                            py="2px"
                            px="8px"
                            borderRadius="6px"
                            borderColor="border"
                            shadow="sm"
                            fontSize="15px"
                            fontWeight="500"
                            borderWidth="1px"
                          >
                            Next
                            <ChevronRightIcon />
                          </Box>
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default TroveHistory;
