import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";

export const BlockedCard = () => {
  const { userCountry, isBlockedCountry } = useProtocolDataContext();
  return (
    <Box bg="surface-hover" display="flex" p="16px" rounded="14px">
      <Text fontSize="12.5px" color="text-secondary">
        <span>
          Yeti Finance is not avaliable to people or companies who are residents
          of, or are located, incorporated or have a registered agent in,{" "}
          {userCountry} or a sanctioned territory. More details can be found in
          our
        </span>
        <Link to="/terms">
          <span
            style={{
              color: "#5F81F9",
              marginLeft: "4px",
              fontWeight: "600",
              marginRight: "4px",
            }}
          >
            Terms of Use
          </span>
        </Link>
      </Text>
    </Box>
  );
};
