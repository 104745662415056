import React, { useState, useEffect } from "react";
import { Box, Text, useDisclosure, useToast, Button } from "@chakra-ui/react";
import { TokenTable, Icon, AdjustInput } from "../../../Components";
import ConfirmDepositModal from "../ConfirmDepositModal";
import { LiquityStoreState, Decimal, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { validateDeposit } from "../../../Utils/validation";
import {
  adjustValue,
  format,
  addString,
  subtractString,
  getNum,
} from "../../../Utils/number";
import { Form } from "react-final-form";
import PoolRewardsModal from "../PoolRewardsModal";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { ConnectButton } from "../../../Components/ConnectButton";
import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";
import { BlockedCard } from "../../../Components/BlockedCard/BlockedCard";
import Tooltip from "../../../Components/Tooltip";

export type PoolCardProps = {
  disconnected?: boolean;
};

const selector = ({
  stabilityDeposit,
  yusdBalance,
  yusdInStabilityPool,
  YETIPrice,
  poolRewardRate,
  YUSDPrice,
}: LiquityStoreState) => ({
  stabilityDeposit,
  yusdBalance,
  yusdInStabilityPool,
  YETIPrice,
  poolRewardRate,
  YUSDPrice,
});

const dataSelector = useLiquitySelector;

const PoolCard: React.FC<PoolCardProps> = ({ disconnected = false }) => {
  const { connected } = useWeb3Context();
  const { liquity } = useLiquity();
  const { stabilityDeposit, YUSDPrice } = dataSelector(selector);
  const { isBlockedCountry, userCountry } = useProtocolDataContext();
  const [userYUSDBalance, setUserYUSDBalance] = useState<number>(0);
  const { yusdBalance, poolRewardRate } = dataSelector(selector);
  const [value, setValue] = useState<any>({});
  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");
  const adjustAmount =
    value["input"] !== undefined && mode === "withdraw"
      ? -Number(value["input"])
      : value["input"] !== undefined && mode === "deposit"
      ? +value["input"]
      : 0;

  const { yusdInStabilityPool, YETIPrice } = dataSelector(selector);
  const userBalance = userYUSDBalance;
  const poolDeposit = format(stabilityDeposit.currentYUSD);
  const yetiPrice = format(YETIPrice);
  const totalYUSDDeposits = format(yusdInStabilityPool);
  const poolShare = format(
    stabilityDeposit.currentYUSD.mulDiv(100, yusdInStabilityPool)
  );
  const totalDeposited = +stabilityDeposit.currentYUSD;
  const rewardsEarned = +stabilityDeposit.yetiReward;
  const rewards: TroveMappings = stabilityDeposit.collateralGain;

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isPoolRewardsOpen,
    onOpen: onPoolRewardsOpen,
    onClose: onPoolRewardsClose,
  } = useDisclosure();
  const toast = useToast();
  const validate = (valueChange: number) => {
    validateDeposit(
      toast,
      mode,
      userBalance,
      poolDeposit,
      valueChange,
      onConfirmOpen
    );
  };

  const notNegative = (
    mode: "deposit" | "withdraw",
    initialValue: number,
    valueChange: string
  ) => {
    if (mode === "deposit") {
      return addString(initialValue, valueChange);
    }
    const ans = subtractString(initialValue, valueChange);
    if (ans > 0) {
      return ans;
    }
    return 0;
  };
  const getFormattedValue = (value: string) => {
    if (/^[0-9.]*$/.test(value)) {
      return value;
    }
    return "";
  };
  // console.log(value);
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));
  const [showInput, setShowInput] = useState<boolean>(false);
  const [weeklyReward, setWeeklyReward] = useState<Decimal>(Decimal.from(0));
  const [weeklyRewardWithdraw, setWeeklyRewardWithdraw] = useState<Decimal>(
    Decimal.from(0)
  );
  useEffect(() => {
    liquity
      .getEstimatedYETIPoolRewards(totalYUSDDeposits, 604800)
      .then((num) => setReward(num));
    if (yusdBalance) {
      setUserYUSDBalance(format(yusdBalance));
    }
  }, [value.input, yusdBalance]);
  const smallPercent = "< 0.01";
  const estimatedWeekly: number = +(
    (format(poolRewardRate) * 604800 * format(totalDeposited + adjustAmount)) /
    format(yusdInStabilityPool)
  ).toFixed(3);
  // useEffect(() => {
  //   if (!isNaN(value.input)) {
  //     liquity
  //     .getEstimatedYETIPoolRewards(value.input + poolDeposit, 604800)
  //     .then(num => setWeeklyReward(num));
  //   } else if (!isNaN(value.input) && +value.input <= poolDeposit && mode == "withdraw") {
  //     liquity
  //       .getEstimatedFarmRewards(poolDeposit - +value.stakeInput, 604800)
  //       .then(num => setWeeklyRewardWithdraw(num));
  //   }
  //   if (yusdBalance) {
  //     setUserYUSDBalance(format(yusdBalance));
  //   }
  // }, [value.input, yusdBalance, mode]);
  return (
    <>
      <Form
        onSubmit={() => undefined}
        format={getFormattedValue}
        render={({ values, form }) => (
          <>
            {setValue(values)}
            {!disconnected && (
              <PoolRewardsModal
                isOpen={isPoolRewardsOpen}
                onClose={onPoolRewardsClose}
                rewards={rewards}
                notStability={false}
              />
            )}
            {!disconnected && (
              <ConfirmDepositModal
                isOpen={isConfirmOpen}
                onClose={onConfirmClose}
                mode={mode}
                amount={values.input || 0}
                total={adjustValue(mode, poolDeposit, values.input)}
                values={values}
                name="input"
              />
            )}
            <Box layerStyle="card" p="24px" w={{ sm: "full", md: "340px" }}>
              <Box
                display="flex"
                flexDir="column"
                justifyContent="space-between"
                borderBottom="1px"
                borderColor="border"
                pb="24px"
              >
                <Box display="flex" alignItems="center">
                  <Icon iconName={"YUSD"} h="32px" w="32px" />
                  <Text pl="8px" textStyle="card_header">
                    YUSD Stability Pool
                  </Text>
                </Box>
                <Text textStyle="asset" color="green.300" pt="8px">
                  Earn liquidation fees & YETI rewards
                </Text>
                <Tooltip>
                  <Text>
                    Rewards only include Yeti Rewards. Liquidation fees are
                    based off the amount of liquidations that occur when
                    staking.
                  </Text>
                </Tooltip>
              </Box>

              {/* <Box
               display="flex" flexDir="column"
              >
                <Box display="flex">
                  <Box display="flex">
                    <Text>Asset</Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box w="120px" display="flex" justifyContent="flex-end">
                    <Text>TVL</Text>
                  </Box>
                  <Box w="140px" display="flex" justifyContent="flex-end">
                    <Text>APR</Text>
                  </Box>
                  <Box w="210px" display="flex" justifyContent="flex-end">
                    <Text>Your stake</Text>
                  </Box>
                  <Box w="180px" display="flex" justifyContent="flex-end">
                    <Text>Est. yield / wk</Text>
                  </Box>
                  <Box w="190px" display="flex" justifyContent="flex-end">
                    <Text>Rewards accrued</Text>
                  </Box>
                  <Box
                    w="130px"
                    display="flex"
                    justifyContent="flex-end"
                    mr="100px"
                  >
                    <Text>Action</Text>
                  </Box>
                </Box>
                {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
              {/* </Box> */}
              <TokenTable
              // headers={[
              //   "Asset",
              //   "Total Staked",
              //   "Rewards APR",
              //   "Your Total Stake",
              //   "Your Pool Share",
              //   "Est. Weekly YETI Rewards",
              //   "YETI Earned",
              //   "Actions",
              // ]}
              // width={8}
              // display={["none", "block"]}
              >
                <>
                  <Box key="stabilityPool" display="flex" flexDir="column">
                    {/* YUSD */}
                    {/* <Box display="flex">
                      <Box display="flex">
                        <Box display="flex" gap="8px" alignItems="center">
                          <Icon iconName={"YUSD"} h="32px" w="32px" />
                          <Box
                            display="flex"
                            flexDir="column"
                            ml="8px"
                            gap="2px"
                          >
                            <Text>YUSD</Text>
                            <Text
                              textStyle="text_xs"
                              color="text-secondary"
                              fontWeight="700"
                            >
                              Stability Pool
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box> */}
                    {/* Total Deposits */}
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>TVL</Text>$
                      {getNum(totalYUSDDeposits * format(YUSDPrice), 2)}
                    </Box>
                    {/* Rewards APR */}
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>Rewards</Text>
                      <Text>
                        {(
                          ((+String(reward) * 52 * yetiPrice) /
                            totalYUSDDeposits) *
                          100
                        ).toFixed(3)}
                        %
                      </Text>
                    </Box>
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>Your stake</Text>
                      <Box
                        alignItems="flex-end"
                        display="flex"
                        flexDir="column"
                      >
                        <Text>
                          {getNum(
                            adjustValue(mode, poolDeposit, values.input),
                            3
                          )}
                        </Text>
                        <Text
                          textStyle="text_sm"
                          color="text-secondary"
                          letterSpacing="tight"
                          alignItems="flex-end"
                        >
                          {poolShare < 0.01 && poolShare !== 0
                            ? smallPercent
                            : (
                                poolShare +
                                format(
                                  Decimal.from(
                                    adjustValue(mode, poolDeposit, values.input)
                                  ).mulDiv(100, yusdInStabilityPool)
                                )
                              ).toFixed(3)}
                          % of pool
                        </Text>
                      </Box>
                    </Box>
                    {/* <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    w="80px"
                    >
                      {poolShare < 0.01 && poolShare !== 0
                        ? smallPercent
                        : (
                            poolShare +
                            format(
                              Decimal.from(
                                adjustValue(mode, poolDeposit, values.input)
                              ).mulDiv(100, yusdInStabilityPool)
                            )
                          ).toFixed(3)}
                      %
                    </Box> */}
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>Est. weekly return</Text>
                      {!stabilityDeposit.currentYUSD.eq(Decimal.from(0))
                        ? `${estimatedWeekly} YETI`
                        : +(
                            (format(poolRewardRate) *
                              604800 *
                              format(
                                totalDeposited +
                                  adjustValue(mode, poolDeposit, values.input)
                              )) /
                            format(yusdInStabilityPool)
                          ).toFixed(3)}
                    </Box>
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>YETI Earned</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        gap="4px"
                        alignItems="flex-end"
                      >
                        {getNum(rewardsEarned, 3)}
                        <Button
                          variant="text"
                          fontSize="14px"
                          fontWeight="semibold"
                          display="flex"
                          height="28px"
                          px="8px"
                          onClick={onPoolRewardsOpen}
                        >
                          View
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" mt="24px" flexDir="column">
                    {showInput && (
                      <>
                        <Text
                          fontSize={14}
                          color={mode === "withdraw" ? "#DC444D" : "#34CE85"}
                          mr={2}
                        >
                          {mode === "withdraw" ? "Unstake" : "Stake"}
                        </Text>
                        <AdjustInput
                          w="full"
                          display="flex"
                          max={mode === "deposit" ? userBalance : poolDeposit}
                          name="input"
                          // showToken={true}
                          token="YUSD"
                          showToken
                          showCancel
                          form={form}
                          walletBalance={
                            mode === "deposit" ? userBalance : poolDeposit
                          }
                          setFarm={setShowInput}
                          noCurrencyConvert={true}
                        />
                        <Button
                          mt="16px"
                          fontSize={14}
                          variant="newPrimary"
                          display="flex"
                          w="full"
                          onClick={() => validate(values.input)}
                        >
                          Confirm
                        </Button>
                      </>
                    )}
                    {connected
                      ? !showInput && (
                          <Box gap="16px" display="flex" w="full">
                            <Button
                              variant="surface"
                              fontSize="15px"
                              fontWeight="bold"
                              w="full"
                              display="flex"
                              // TODO: block
                              disabled={isBlockedCountry}
                              onClick={() => {
                                setMode("deposit");
                                setShowInput(true);
                              }}
                            >
                              Stake
                            </Button>
                            <Button
                              variant="surface"
                              fontSize="15px"
                              fontWeight="bold"
                              display="flex"
                              w="full"
                              onClick={() => {
                                setMode("withdraw");
                                setShowInput(true);
                              }}
                            >
                              Unstake
                            </Button>
                          </Box>
                        )
                      : !showInput &&
                        !isBlockedCountry && <ConnectButton width="full" />}
                  </Box>
                  {isBlockedCountry && (
                    <Box mt="16px">
                      <BlockedCard />
                    </Box>
                  )}
                </>
              </TokenTable>
            </Box>
          </>
        )}
      />
    </>
  );
};

export default PoolCard;
