import React from "react";
// import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { LiquityStoreState } from "@yeti/lib-base";
import tokenData from "../../../../TokenData";
import { Icon } from "../../../../Components";
import CurrencyConverter from "../../../../Components/CurrencyConverter";
import getTokenName from ".././utils/getTokenName";
import { getNum } from "../../../../Utils/number";
import { Box, Hide, Link, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ExternalLinkIcon } from "@chakra-ui/icons";
// import { ExternalLinkIcon } from "@chakra-ui/icons";

export type PortfolioModalProps = {
  // isOpen: boolean;
  // onClose: () => void;
  data: any;
};

const selector = ({ prices, total, safetyRatios }: LiquityStoreState) => ({
  prices,
  total,
  safetyRatios,
});

const getFormattedDate = (date: Date) => {
  // console.log('date', date.toLocaleString() )
  const options: any = {
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
  };
  const string = date.toLocaleString("en-US", options).split(" ");
  return (
    string[0].slice(0, 3) + " " + string[1] + ", " + string[3] + " " + string[4]
  );
};

const TroveSummaryModal: React.FC<PortfolioModalProps> = ({
  // isOpen,
  // onClose,
  data,
}) => {
  // console.log('summary modal history data', data)
  // const summaryData =
  //     {
  //       "operation": "adjustTrove",
  //       "blockNum": "14800706",
  //       "collsIn": [
  //         "0x72298e47f648ca0215f2e17d5647048b2d42520c",
  //         "0xcc3ee7ccb14aea851850f46cbbe4d82f5d74c20f"
  //       ],
  //       "amountsIn": [
  //         500000000000000000,
  //         49999999996627379438
  //       ],
  //       "tokens": [
  //         "0x72298e47f648ca0215f2e17d5647048b2d42520c",
  //         "0xcc3ee7ccb14aea851850f46cbbe4d82f5d74c20f"
  //       ],
  //       "amounts": [
  //         15705269840987655557,
  //         1053636163089280431124
  //       ],
  //       "debt": 7099996846927131974487,
  //       "YUSDchange": 27020000000000000000,
  //       "isDebtIncrease": true,
  //       "timestamp": 1652764225,
  //       "currentICR": 1476463009993319806
  //     }
  const summaryData = data;

  // console.log("summaryData", summaryData);

  // console.log('summary', summaryData)

  const allTokens: string[] = Object.assign(
    [],
    summaryData["collsIn"].concat(summaryData["collsOut"])
  );

  for (const i in summaryData["tokens"]) {
    if (!allTokens.includes(summaryData["tokens"][i])) {
      allTokens.push(summaryData["tokens"][i]);
    }
  }

  const getTransaction = (token: string) => {
    // console.log('all', allTokens)
    if (summaryData["collsIn"].includes(token)) {
      // if (summaryData["collsIn"].indexOf(token) == 0) {
      //   return <Center>Deposit</Center>;
      // }
      // return <Center> | </Center>;
      return (
        <Text px="8px" py="2px" bg="green.400" rounded="full">
          Deposit
        </Text>
      );
    } else if (summaryData["collsOut"].includes(token)) {
      // if (summaryData["collsOut"].indexOf(token) == 0) {
      //   return <Box bg="red.400">Withdraw</Box>;
      // }
      // return <Center> | </Center>;
      return (
        <Text px="8px" py="2px" bg="red.400" rounded="full">
          Withdraw
        </Text>
      );
    } else {
      // if (
      //   allTokens[
      //     summaryData["collsIn"].length + summaryData["collsOut"].length
      //   ] == token
      // ) {
      //   return <Box
      //   py="2px"
      //   px="6px"
      //   rounded="4px"
      //   fontSize="13px"
      //   bg="surface-highlight"
      //   color="text-secondary"
      // >
      //   No change
      // </Box>;
      // }
      // return (
      //   <Box display="flex" justifyContent="center" w="90px">
      //     {" "}
      //     |{" "}
      //   </Box>
      // );
      return (
        <Text
          px="8px"
          py="2px"
          bg="surface-highlight"
          color="text-primary"
          rounded="full"
        >
          No change
        </Text>
      );
    }
  };

  const getAmountChange = (token: string) => {
    // console.log("amountChange", summaryData);
    const tokenInfo = tokenData.find(
      (t) => t["address"].toLowerCase() == token.toLowerCase()
    );
    const tokenName = tokenInfo ? tokenInfo["token"] : "undefined";
    if (summaryData["collsIn"].includes(token)) {
      const index = summaryData["collsIn"].indexOf(token);
      const realAmount = summaryData["realAmountsIn"][index];
      const realValue = summaryData["valuesIn"][index];
      if (realValue != 0) {
        return (
          <Box display="flex" flexDir="column" alignItems="flex-end">
            <Text fontSize="13px" color="green.400" letterSpacing="tight">
              +{getNum(Number(realAmount), 3)}
            </Text>
            {tokenInfo && (
              <CurrencyConverter
                token={tokenName}
                value={realValue}
                currency={"USD"}
                price={1}
                color="text-secondary"
              />
            )}
          </Box>
        );
      }
    } else if (summaryData["collsOut"].includes(token)) {
      const index = summaryData["collsOut"].indexOf(token);
      const realAmount = summaryData["realAmountsOut"][index];
      const realValue = summaryData["valuesOut"][index];
      // console.log("realValue", realValue);
      if (realValue != 0) {
        return (
          <Box display="flex" flexDir="column" alignItems="flex-end">
            <Text fontSize="13px" color="red.400" letterSpacing="tight">
              -{getNum(Number(realAmount), 3)}
            </Text>
            {tokenInfo && (
              <CurrencyConverter
                token={tokenName}
                value={realValue}
                currency={"USD"}
                price={1}
                color="text-secondary"
              />
            )}
          </Box>
        );
      }
    }
    return (
      <Box>
        <Text
          textStyle="subtitle3"
          color="text-primary"
          textAlign={["right", "left"]}
        >
          {" "}
          --
        </Text>
      </Box>
    );
  };

  const isAToken = (tokenName: string) => {
    return ["aUSDC", "aWAVAX", "aWETH", "aUSDT", "aDAI"].includes(tokenName);
  };

  const getFinalAmount = (token: string) => {
    const tokenInfo = tokenData.find(
      (t) => t["address"].toLowerCase() == token.toLowerCase()
    );
    const tokenName = tokenInfo ? tokenInfo["token"] : "undefined";
    let finalValue = 0;
    let realAmount = 0;
    if (summaryData["tokens"].includes(token)) {
      const index = summaryData["tokens"].indexOf(token);
      finalValue = summaryData["values"][index];
      realAmount = summaryData["realAmounts"][index];
    }
    return (
      <Box display="flex" flexDir="column" alignItems="flex-end">
        <Text
          textStyle="subtitle3"
          color="text-primary"
          textAlign={["right", "left"]}
        ></Text>
        {getNum(Number(realAmount), 3) + " "}
        <Text color="text-secondary">
          {tokenInfo && (
            <CurrencyConverter
              token={tokenName}
              value={finalValue}
              currency={"USD"}
              price={1}
              color="text-secondary"
            />
          )}
        </Text>
      </Box>
    );
  };

  const getDebtChange = (change: number) => {
    if (change == 0) {
      return (
        <Box>
          <Text
            textStyle="number_base"
            color="text-primary"
            textAlign={["right", "left"]}
          >
            --
          </Text>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text
            textStyle="number_base"
            color="text-primary"
            textAlign={["right", "left"]}
          >
            {getNum(Number(change), 3)}
          </Text>
        </Box>
      );
    }
  };

  const getTransactionType = (
    operation: string,
    collsIn: string[],
    collsOut: string[],
    debt: number
  ) => {
    switch (operation) {
      case "liquidateInNormalMode":
        return "Liquidated";
      case "adjustTrove":
        if (collsIn.length == 0 && collsOut.length == 0) {
          return (
            <Text px="8px" py="2px" bg="green.400" rounded="full">
              Change Debt
            </Text>
          );
        }
        return (
          <Text px="8px" py="2px" bg="primary-default" rounded="full">
            Adjust Trove
          </Text>
        );
      case "redeemCollateral":
        if (debt == 0) {
          return (
            <Text px="8px" py="2px" bg="red.400" rounded="full">
              Closed by Redemption
            </Text>
          );
        }
        return (
          <Text px="8px" py="2px" bg="yellow.400" rounded="full">
            Partially Redeemed
          </Text>
        );
      case "openTrove":
        return (
          <Text px="8px" py="2px" bg="green.400" rounded="full">
            Open Trove
          </Text>
        );
      case "closeTrove":
        return (
          <Text px="8px" py="2px" bg="red.400" rounded="full">
            Close Trove
          </Text>
        );
      default:
        return (
          <Text px="8px" py="2px" bg="surface-highlight" rounded="full">
            Undefined
          </Text>
        );
    }
  };

  return summaryData == {} ? (
    <Text color="text-primary"> Loading </Text>
  ) : (
    <>
      {/* <Tr onClick={() => trclick(history)} _hover={{bg: "#272C39"}}> */}
      <Box
        display="flex"
        flexDir="column"
        // bg="surface-hover"
        // px="24px"
        px="24px"
        // py="16px"
        textStyle="number_base"
      >
        <Box display="flex">
          <Box
            display="flex"
            gap="8px"
            alignItems="center"
            bg="surface-highlight"
            _hover={{ bg: "primary-default" }}
            px="12px"
            py="4px"
            cursor="pointer"
          >
            <ChevronLeftIcon />
            All transactions
          </Box>
        </Box>
        <Box mt="16px" display="flex" gap="8px" fontSize="16px">
          Transaction Summary:
          <Text noOfLines={1} fontSize="12px" color="surface">
            {" "}
            {getTransactionType(
              summaryData["operation"],
              summaryData["collsIn"],
              summaryData["collsOut"],
              Number(summaryData["debt"])
            )}{" "}
          </Text>
        </Box>
        <Box
          mt="24px"
          display="flex"
          pb="16px"
          textStyle="subheading"
          color="text-secondary"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Text>Asset</Text>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Hide below="md">
              <Box display="flex">
                <Text>Transaction</Text>
              </Box>
              <Box w="180px" display="flex" justifyContent="flex-end">
                <Text>Amount</Text>
              </Box>
            </Hide>
            <Box w="200px" display="flex" justifyContent="flex-end">
              <Text>Final Balance</Text>
            </Box>
            {/* <Box w="446px" /> */}
          </Box>
          {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
        </Box>
        {/* <Box
            display="flex"
            h="80px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex">
              <Box display="flex" alignItems="center">
                {summaryData["collsIn"].map((token: string) => {
                  return (
                    <Icon
                      key={token}
                      iconName={getTokenName(token)}
                      h="24px"
                      w="24px"
                    />
                  );
                })}
                {summaryData["collsOut"].map((token: string) => {
                  return (
                    <Icon
                      key={token}
                      iconName={getTokenName(token)}
                      h={5}
                      w={5}
                      mr={3}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box display="flex">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                color="text-primary"
              >
                {summaryData["totalValueChange"] == 0 ? (
                  <Flex>-- </Flex>
                ) : (
                  <Flex>
                    {summaryData["totalValueChange"] > 0 ? (
                      <Text
                        textStyle="subtitle3"
                        color="green.400"
                        textAlign={["right", "left"]}
                      >
                        {" "}
                        +$
                        {getNum(Number(summaryData["totalValueChange"]), 2)}
                      </Text>
                    ) : (
                      <Text
                        textStyle="subtitle3"
                        color="red.400"
                        textAlign={["right", "left"]}
                      >
                        {" "}
                        -$
                        {getNum(
                          -1 * Number(summaryData["totalValueChange"]),
                          2
                        )}
                      </Text>
                    )}
                  </Flex>
                )}
              </Box>

              <Box
                w="160px"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                color="text-primary"
              >
                <Text color="text-primary">
                  $
                  {Number(summaryData["totalValue"])
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </Box>
              <Box
                w="190px"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {Number(summaryData["debt"] * 10 ** -18)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                YUSD
              </Box>
              <Box
                w="140px"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {getNum(summaryData["currentICR"] * 10 ** -16, 2) + "%"}
              </Box>
              <Box
                w="210px"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {getFormattedDate(new Date(summaryData["timestamp"] * 1000))}
              </Box>
            </Box>
          </Box> */}

        {/* <Text fontSize="16px">Transaction Summary</Text> */}

        {/* <Box
          mt="16px"
          display="flex"
          fontSize="13px"
          color="text-secondary"
          alignItems="center"
        >
          <Text w="160px">Asset</Text>
          <Text w="120px">Action</Text>
          <Text w="120px">Amount</Text>
          <Text>Final Balance</Text>
        </Box> */}

        {/* <Text textStyle="subheading">Deposits</Text> */}

        {/* {summaryData["collsIn"] > 0 ? (
            <Text fontSize="14px">Collateral Deposited</Text>
          ) : (
            <></>
          )}

          {summaryData["operation"] == "openTrove" ? (
            <Text fontSize="14px">Collateral Deposited</Text>
          ) : (
            <></>
          )} */}

        {/* {summaryData["collsIn"].map((token: string) => (
          <Box
            key={token}
            display="flex"
            alignItems="center"
            h="48px"
            borderTop="1px"
            borderColor="border"
          >
            <Box display="flex" alignItems="center" w="160px">
              <Icon iconName={getTokenName(token)} h="24px" w="24px" />
              <Text ml="6px">{getTokenName(token)}</Text>
            </Box>
            <Box display="flex" alignItems="center" w="120px">
              <Box display="flex">{getTransaction(token)}</Box>
            </Box>
            <Box display="flex" alignItems="center" w="120px">
              {getAmountChange(token)}
            </Box>
            <Box display="flex" flexDir="column">
              {getFinalAmount(token)}
            </Box>
          </Box>
        ))} */}

        {/* {summaryData["collsOut"] > 0 ? (
          <Text mt="16px">Collateral Withdrawn</Text>
        ) : (
          <></>
        )} */}

        {/* {summaryData["collsOut"].map((token: string) => (
          <Box
            key={token}
            display="flex"
            alignItems="center"
            h="48px"
            borderTop="1px"
            borderColor="border"
          >
            <Box display="flex" alignItems="center" w="160px">
              <Icon iconName={getTokenName(token)} h="24px" w="24px" />
              <Text ml="6px">{getTokenName(token)}</Text>
            </Box>
            <Box display="flex" alignItems="center" w="120px">
              <Box display="flex">{getTransaction(token)}</Box>
            </Box>
            <Box display="flex" alignItems="center" w="120px">
              {getAmountChange(token)}
            </Box>
            <Box display="flex" flexDir="column">
              {getFinalAmount(token)}
            </Box>
          </Box>
        ))} */}

        {allTokens.map((token: string) => (
          <Box
            key={token}
            display="flex"
            h="56px"
            borderTop="1px"
            borderColor="border"
            alignItems="center"
            fontSize="14px"
            fontWeight="500"
            justifyContent="space-between"
            // bg="red.100"
          >
            <Box display="flex" gap="8px" w="160px">
              <Icon iconName={getTokenName(token)} h="20px" w="20px" />
              <Text>{getTokenName(token)}</Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Hide below="md">
                <Text noOfLines={1} fontSize="12px" color="surface">
                  {getTransaction(token)}
                </Text>
              </Hide>
              <Box
                display="flex"
                alignItems="center"
                w={{ base: "100px", md: "180px" }}
                justifyContent="flex-end"
              >
                {getAmountChange(token)}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                w={{ base: "140px", md: "200px" }}
              >
                {getFinalAmount(token)}
              </Box>
            </Box>
          </Box>
        ))}
        {/* YUSD */}
        <Box
          display="flex"
          h="56px"
          borderTop="1px"
          borderColor="border"
          alignItems="center"
          fontSize="14px"
          fontWeight="500"
          justifyContent="space-between"
        >
          <Box display="flex" w="100px">
            <Box display="flex">
              <Icon iconName={"YUSD"} h="24px" w="24px" />
              <Text ml="6px">YUSD</Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Hide below="md">
              {summaryData["YUSDchange"] > 0 ? (
                <Box>
                  {summaryData["isDebtIncrease"] ||
                  summaryData["operation"] == "openTrove" ? (
                    <Text
                      px="8px"
                      py="2px"
                      bg="green.400"
                      color="surface"
                      rounded="full"
                      noOfLines={1}
                      fontSize="12px"
                    >
                      Borrow
                    </Text>
                  ) : (
                    <Text
                      px="8px"
                      py="2px"
                      bg="red.400"
                      color="surface"
                      rounded="full"
                      noOfLines={1}
                      fontSize="12px"
                    >
                      Repay
                    </Text>
                  )}
                </Box>
              ) : (
                <>
                  <Text
                    px="8px"
                    py="2px"
                    bg="surface-highlight"
                    color="text-primary"
                    rounded="full"
                    noOfLines={1}
                    fontSize="12px"
                  >
                    No change
                  </Text>
                </>
              )}
            </Hide>
            <Box
              display="flex"
              alignItems="center"
              w={{ base: "100px", md: "180px" }}
              justifyContent="flex-end"
            >
              {getDebtChange(Number(summaryData["YUSDchange"]) / 10 ** 18)}
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              flexDir="column"
              justifyContent="flex-end"
              w={{ base: "140px", md: "200px" }}
            >
              <Text textStyle="number_base" color="text-primary">
                {getNum(summaryData["debt"] / 10 ** 18, 3) + " "}
              </Text>
              <Text fontSize="12px" color="text-secondary">
                Interest: {getNum(summaryData["interest"] / 10 ** 18, 3) + " "}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          w="full"
          justifyContent="space-between"
          alignItems="center"
          pb="24px"
          borderTop="1px"
          borderColor="border"
          pt="24px"
          gap="8px"
          flexDir={{ base: "column", md: "row" }}
        >
          <Box display="flex" w="full">
            <Box display="flex" alignItems="center" gap="64px">
              <Box display="flex" alignItems="flex-start" flexDir="column">
                <Text textStyle="subheading" color="text-secondary">
                  Final ICR:
                </Text>
                {getNum(summaryData["currentICR"] * 10 ** -16, 2) + "%"}
              </Box>
              <Box display="flex" alignItems="flex-start" flexDir="column">
                <Text textStyle="subheading" color="text-secondary">
                  Date
                </Text>
                {getFormattedDate(new Date(summaryData["timestamp"] * 1000))}
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap="8px" w="full">
            <Link
              color="text-secondary"
              textDecorationLine="underline"
              _hover={{ color: "primary-default" }}
              textStyle="subtitle3"
              href={"https://snowtrace.io/tx/" + summaryData["transaction"]}
              isExternal
              display="flex"
              alignItems="center"
              gap="8px"
            >
              View transaction on explorer
              <ExternalLinkIcon mb={1}> </ExternalLinkIcon>
            </Link>
          </Box>
        </Box>
      </Box>
      {/* <Td py={3} borderColor="#313545" fontSize={14}>
                            {summaryData['operation']}
                        </Td>
                        
                        <VStack
                            divider={<StackDivider borderColor='gray.200' />}
                            spacing={2}
                            align='stretch'
                            >
                            {summaryData['collsIn'].map((token: string) => {
                                const tokenName = tokenData.find(t=> (t['address'].toLowerCase() == token.toLowerCase()))!['token']
                                return(
                                        <Box height={10}>
                                            <Flex>
                                            <Icon iconName={tokenName} h={5} w={5} mr={3} />
                                        <Text textStyle="subtitle3" fontWeight="normal" maxWidth={500}>{tokenName }</Text>
                                            </Flex>
                                        
                                        </Box>
                                    
                                )
                            })}
                            {summaryData['collsOut'].map((token: string) => {
                                const tokenName = tokenData.find(t=> (t['address'].toLowerCase() == token.toLowerCase()))!['token']
                                return(                     
                                        <Box width={50} height={10}>
                                            <Flex>
                                            <Icon iconName={tokenName} h={5} w={5} mr={3} />
                                        <Text textStyle="subtitle3" fontWeight="normal" maxWidth={500}>{tokenName }</Text>
                                            </Flex>
                                        
                                        </Box>   
                                )
                            })}
                        </VStack>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                            {summaryData['amountsIn'].map((amount: number) => {
                                const tokenAddress = summaryData['collsIn'][summaryData['amountsIn'].indexOf(amount)]
                                const tokenInfo = tokenData.find(t=> (t['address'].toLowerCase() == tokenAddress.toLowerCase()))
                                const tokenName = tokenInfo!['token']
                                const priceMap = vaultData['price']
                                const price = priceMap[tokenName.replace('-', '').replace(' ', '')]
                                const underlyingDecimals = tokenInfo!['underlyingDecimals'];
                                let realAmount = Number(amount) / 10 ** underlyingDecimals
                                let displayAmount = realAmount
                                return(
                                    <Box height={10}>
                                    <Text textStyle="subtitle3" color="green.400" textAlign={["right", "left"]}> +
                                        {(displayAmount !== 0 && displayAmount < 0.001 ? "< 0.001" : getNum(displayAmount, 3)) + " "}
                                        {<CurrencyConverter token={tokenName} value={realAmount} currency={"USD"} price={price}/>}
                                    </Text>
                                    </Box>
                                )
                            })}
                            {summaryData['amountsOut'].map((amount: number) => {
                                const tokenAddress = summaryData['collsOut'][summaryData['amountsOut'].indexOf(amount)]
                                const tokenInfo = tokenData.find(t=> (t['address'].toLowerCase() == tokenAddress.toLowerCase()))
                                const tokenName = tokenInfo!['token']
                                const priceMap = vaultData['price']
                                const price = priceMap[tokenName.replace('-', '').replace(' ', '')]
                                const underlyingDecimals = tokenInfo!['underlyingDecimals'];
                                let realAmount = Number(amount) / 10 ** 18
                                let displayAmount = realAmount
                                if (tokenInfo!['isVault']) { 
                                    displayAmount = Number(amount) / 10 ** underlyingDecimals * vaultData['underlyingPerReceipt'][tokenName.replace('-', '').replace(' ', '')] 
                                }
                                return(
                                    <Box height={10}>
                                    <Text textStyle="subtitle3" color="red.400" textAlign={["right", "left"]}> -
                                        {(displayAmount !== 0 && displayAmount < 0.001 ? "< 0.001" : getNum(displayAmount, 3)) + " "}
                                        {<CurrencyConverter token={tokenName} value={realAmount} currency={"USD"} price={price}/>}
                                    </Text>
                                    </Box>
                                )
                            })}
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{summaryData['totalValueChange']} </Text>
                        </Td>   
                        <Td py={3} borderColor="#313545" fontSize={14}>
                            {summaryData['isDebtIncrease']
                            ? <Text textStyle="subtitle3" color="green.400" textAlign={["right", "left"]}> +{getNum(Number(summaryData['YUSDchange']) / 10 ** 18, 2)} YUSD</Text>
                            : <Text textStyle="subtitle3" color="red.400" textAlign={["right", "left"]}> -{getNum(Number(summaryData['YUSDchange']) / 10 ** 18, 2)} YUSD</Text>}
                        </Td>
                        
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(Number(summaryData['debt']) * (10 ** -18), 2) + ' YUSD'} </Text>
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(totalValue * (10 ** -18), 2) + ' USD'} </Text>
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(summaryData['currentICR'] * (10 ** -16), 2) + '%'} </Text>
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text>{new Date(summaryData['timestamp'] * 1000).toLocaleString()}</Text>
                        </Td> */}
    </>
  );
};

export default TroveSummaryModal;
