import { Box, Text } from "@chakra-ui/react";
import React from "react";

export type TermsProps = {
  disconnected?: boolean;
};

const Terms: React.FC<TermsProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        w="full"
        h="90px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text
        textAlign="center"
        color="text-primary"
        textStyle="display_md"
        borderColor="#31354F"
        pb="8px"
      >
        Yeti Finance Terms of Use
      </Text>
      <Text textAlign="center" color="text-secondary">
        Updated December 15th, 2022
      </Text>
      <Box mx="auto" mt="48px" display="flex" flexDir="column" maxW="3xl">
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="32px">
          Yeti Finance is an open-source, decentralized borrowing protocol.
          Sapiens Research Foundation (“we”, “our”, or “us”) offer access to the
          Yeti Finance protocol on the Avalanche blockchain to deposit assets
          and borrow the protocol’s native stablecoin, YUSD, as well as stake
          various assets (”Protocol”), through its website.
        </Text>

        <Text mt="32px" fontSize="20px">
          Disclaimer: Risks of Using Protocol
        </Text>
        <Text fontSize="15px" color="text-primary" mt="16px">
          Yeti Finance is a novel, decentralized borrowing protocol that allows
          users to deposit assets and borrow the protocol’s native stablecoin,
          YUSD, against them. The Yeti Finance protocol is made up of both
          proprietary and free, public, and open-source software. Your use of
          Yeti Finance involves various risks, including, but not limited, to
          losses while digital assets are deposited into Yeti Finance via smart
          contract or economic exploits, and losses due to liquidations and
          redemptions. Before borrowing, staking, or liquidity providing you
          should fully review our technical documentation to understand how the
          Yeti Finance protocol works.
        </Text>
        <Text fontSize="15px" color="text-primary" mt="16px">
          While the Yeti Finance Decentralized Finance Protocol has been
          thoroughly audited by multiple independent software security firms and
          undergone third-party economic analysis, there remains a risk that
          assets deposited into the protocol as well as the YUSD and YETI tokens
          may suffer complete and permanent economic loss should the protocol’s
          technical or economic mechanisms suffer catastrophic failure.
        </Text>

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          1. Use of the Services
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          To use the Services, you must legally be able to enter into the
          Agreement. By using the Services, you represent and warrant that you
          meet the eligibility requirement. If you do not meet the requirement,
          you must not access or use the Site or the Services.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          YETI FINANCE AND THE YETI AND YUSD TOKEN IS NOT OFFERED TO PERSON OR
          ENTITIES WHO RESIDE IN, ARE CITIZENS OF, ARE LOCATED IN, ARE
          INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN THE UNITED STATES OF
          AMERICA (COLLECTIVELY, “US PERSONS”). MOREOVER, NO SERVICES (AS
          DEFINED BELOW) ARE OFFERED TO PERSON OR ENTITIES WHO RESIDE IN ARE
          CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED
          OFFICE IN ANY SANCTIONED TERRITORY (AS DEFINED BELOW, AND ANY SUCH
          PERSON OR ENTITY FROM A SANCTIONED TERRITORY, A “SANCTIONED PERSON”).
          WE DO NOT MAKE EXCEPTIONS; THEREFORE, IF YOU ARE A U.S. PERSON, THEN
          DO NOT ATTEMPT TO USE THE APP OR PROTOCOL, AND IF YOU ARE A SANCTIONED
          PERSON, THEN DO NOT ATTEMPT TO USE ANY OF THE SERVICES, USE OF A
          VIRTUAL PRIVATE NETWORK (”VPN”) TO CIRCUMVENT THE RESTRICTIONS SET
          FORTH HEREIN IS PROHIBITED.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          ARBITRATION NOTICE: THESE TERMS (“TERMS”) CONTAIN AN ARBITRATION
          CLAUSE BELOW. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT
          ARBITRATION CLAUSE, YOU AND WE AGREE THAT ANY DISPUTES RELATING TO THE
          SERVICES (AS DEFINED BELOW) WILL BE RESOLVED BY MANDATORY BINDING
          ARBITRATION, AND YOU WAIVE ANY RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. You
          are entering into a binding Agreement.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="32px">
          BY ACCESSING OR USING OUR SERVICES, WHICH INCLUDE OUR VARIOUS
          WEBSITES, INCLUDING, WITHOUT LIMITATION, YETI.FINANCE (AND ANY
          RESPECTIVE OR RELATED SUBDOMAINS); APPLICATIONS (COLLECTIVELY WITH ANY
          MATERIALS AND SERVICES AVAILABLE THEREIN, AND SUCCESSOR WEBSITE(S) OR
          APPLICATION(S) THERETO, THE “SITE”), AND OTHER SERVICES THAT LINK TO
          THESE TERMS, AS WELL AS ANY INFORMATION, TEXT, LINKS, GRAPHICS,
          PHOTOS, AUDIO, VIDEO, OR OTHER MATERIALS STORED, RETRIEVED OR
          APPEARING THEREON, WHETHER ACCESSED THROUGH THE SITE OR OTHERWISE
          (COLLECTIVELY, THE “SERVICES”), YOU ARE ENTERING INTO A BINDING
          AGREEMENT WITH US THAT INCLUDES THESE TERMS, YETI.FINANCE - PRIVACY
          POLICY (FOUND HERE), AND OTHER POLICIES REFERENCED HEREIN
          (COLLECTIVELY, THE “AGREEMENT”).
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="32px">
          To the extent that there is a conflict between these Terms and any
          applicable additional terms, these Terms will control unless expressly
          stated otherwise. If you do not agree with these Terms, you may not
          use the Services and should not visit the Site or otherwise engage
          with the Services.
        </Text>

        <Text fontSize="18px" fontWeight="600" color="text-primary" mt="32px">
          We may update the Services and the Terms.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          We may update the Services, the Agreement, and any part of the Terms
          at any time, for any reason, at our sole discretion. Once any part of
          the Agreement is updated and in effect, you will be bound by the Terms
          if you continue to use the Services, including by accessing the Site.
          We may, at any time, and without liability to you, modify or
          discontinue all or part of the Services (including access to the
          Services via any third-party links). You may contact us with questions
          about your use of the Services at team@yetifinance.co. When you
          communicate with us electronically, you consent to receive
          communications from us electronically. You should review the Terms
          from time to time to ensure that you understand the terms and
          conditions that apply to you when you access or use the Site.
        </Text>
        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          2. Assumption of Risk
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          You assume the risks of engaging in transactions that rely on smart
          contracts and other experimental technology.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          Transactions on the Yeti Finance Protocol rely on smart contracts
          stored on the Avalanche blockchain blockchains, cryptographic tokens
          generated by the smart contracts, and other nascent software,
          applications and systems that interact with blockchain-based networks.
          These technologies are experimental, speculative, inherently risky,
          and subject to change. Among other risks, bugs, malfunctions,
          cyberattacks, or changes to the applicable blockchain (e.g., forks)
          could disrupt these technologies and even result in a total loss of
          cryptoassets, their market value, or digital funds. You are solely
          responsible for the safekeeping of the private key associated with the
          blockchain address used to interact with the Protocol. We assume no
          liability or responsibility for any such risks. If you are not
          comfortable assuming these risks, you should not access or engage in
          transactions using blockchain-based technology.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          One of the other defining features of blockchain technology is that
          its entries are immutable, which means, as a technical matter, they
          generally cannot be deleted or modified by anyone. This includes smart
          contracts and cryptoassets generated and programmed by smart
          contracts. THUS, TRANSACTIONS RECORDED ON THE BLOCKCHAIN, INCLUDING
          TRANSFERS OF CRYPTOASSETS AND DATA PROGRAMMED INTO THESE ASSETS MUST
          BE TREATED AS PERMANENT AND CANNOT BE UNDONE BY US OR BY ANYONE. YOU
          MUST BE VERY CAREFUL WHEN YOU FINALIZE ANY TRANSACTION THAT WILL BE
          RECORDED ON THE BLOCKCHAIN.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          We are not liable for any third-party services or links.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          We are not responsible for the content or services of any third-party,
          including, without limitation, any network, or apps like Discord, or
          MetaMask, and we make no representations regarding the content or
          accuracy of any third-party services or materials. The use and access
          of any third-party products or services, including through the
          Services, is at your own risk.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You agree to the automated collection and disbursement of assets by
          smart contracts. You acknowledge and agree that all transactions
          accessed through the Services will be automatically processed using
          one or more blockchain-based smart contracts. By engaging in
          transactions using the Services, you acknowledge and consent to the
          automatic processing of all transactions in connection with using the
          Services. You further acknowledge and agree that the applicable smart
          contract will dictate how the funds of a transaction and ownership of
          cryptoassets are distributed.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You acknowledge the risks of using the Services. You bear sole
          responsibility for evaluating the Services before using them, and all
          transactions accessed through the Services are irreversible, final,
          and without refunds. The Services may be disabled, disrupted or
          adversely impacted as a result of sophisticated cyber-attacks, surges
          in activity, computer viruses, and/or other operational or technical
          challenges, among other things. We disclaim any ongoing obligation to
          notify you of all of the potential risks of using and accessing our
          Services.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You acknowledge there is a real risk that assets deposited into the
          protocol and Protocol related tokens: YUSD, YETI, and vault tokens may
          suffer complete and permanent economic loss should the protocol’s
          technical or economic mechanisms suffer catastrophic failure.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You acknowledge that the terms of the Protocol may change at any time
          for any reason included but not limited to protocol fees, rewards, and
          accessibility.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You agree to (defined below) accept these risks and agree that you
          will not seek to hold any Sapiens Research Indemnified Party
          responsible for any consequent losses.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You are solely responsible for the security of your wallet.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="8px">
          You understand and agree that you are solely responsible for
          maintaining the security of your wallet. Any unauthorized access to
          your wallet by third parties could result in the loss or theft of any
          cryptoasset, or any funds held in your account and any associated
          accounts. You understand and agree that we have no involvement in, and
          you will not hold us responsible for managing and maintaining the
          security of your wallet. You further understand and agree that we are
          not responsible, and you will not hold us accountable, for any
          unauthorized access to your wallet. It is your responsibility to
          monitor your wallet.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          We reserve the right to restrict your access from engaging with the
          Services.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="8px">
          You agree that we have the right to restrict your access to the
          Services via any technically available methods if we suspect, in our
          sole discretion, that (a) you are using the Services for money
          laundering or any illegal activity; (b) you have engaged in fraudulent
          activity; (c) you have acquired cryptoassets using inappropriate
          methods, including the use of stolen funds to purchase such assets;
          (d) you are the target of any sanctions administered or enforced by
          the U.S. Department of the Treasury’s Office of Foreign Assets Control
          (“OFAC”), the United Nations Security Council, the European Union, Her
          Majesty’s Treasury, or any other legal or regulatory authority in any
          applicable jurisdiction; (e) either you, as an individual or an
          entity, or your wallet address is listed on the Specially Designated
          Nationals and Blocked Persons List (“SDN List”), Consolidated
          Sanctions List (“Non-SDN Lists), or any other sanctions lists
          administered by OFAC; (f) you are located, organized, or resident in a
          country or territory that is, or whose government is, the subject of
          sanctions, including but not limited to Côte d’Ivoire, Cuba, Belarus,
          Iran, Iraq, Liberia, North Korea, Sudan, and Syria; or (g) you have
          otherwise acted in violation of these Terms. If we have a reasonable
          suspicion that you are utilizing the Site for illegal purposes, we
          reserve the right to take whatever action we deem appropriate.
        </Text>
        <Text fontSize="18px" fontWeight="600" color="text-primary" mt="32px">
          We do not guarantee the quality or accessibility of the Services.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          As a condition to accessing or using the Services or the Site, you
          acknowledge, understand, and agree that from time to time, the Site
          and the Services may be inaccessible or inoperable for any reason,
          including, but not limited to equipment malfunctions, periodic
          maintenance procedures or repairs, causes beyond our control or that
          we could not reasonably foresee, disruptions and temporary or
          permanent unavailability of underlying blockchain infrastructure or
          unavailability of third-party service providers or external partners
          for any reason. You acknowledge and agree that you will access and use
          the Services, including, without limitation, the Site at your own
          risk. You should not engage in blockchain-based transactions unless it
          is suitable given your circumstances and financial resources. By using
          the Services, you represent that you have been, are and will be solely
          responsible for conducting your own due diligence into the risks of a
          transaction and the underlying smart contracts and cryptoassets.
        </Text>

        {/* *** PRIVACY *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          3. Privacy
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          We care about your privacy. We use safeguards to preserve the
          integrity and security of your and aggregate data. However, we cannot
          guarantee that unauthorized third parties will never be able to obtain
          or use your PII or aggregate data for improper purposes. You
          acknowledge that you provide your PII and aggregate data at your own
          risk, and that we will comply with all valid subpoena requests. By
          accessing and using the Interface, you understand and consent to our
          collection, use, and disclosure of your PII and aggregate data.
        </Text>

        {/* *** TAXES *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          4. Taxes
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          You are responsible for your taxes and duties.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          Users bear sole responsibility for paying any and all taxes, duties,
          and assessments now or hereafter claimed or imposed by any
          governmental authority associated with their use of the Services,
          and/or payable as the result of using and/or exploiting any
          cryptoassets and interacting with smart contracts. Blockchain-based
          transactions are novel, and their tax treatment is uncertain.
        </Text>

        {/* *** PROHIBITED CONTENT *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          5. Prohibited Content
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          You may only use the Services if you comply with this Agreement
          (including, without limitation, these Terms), applicable third-party
          policies, and all applicable laws, rules, regulations and related
          guidance. The following conduct is prohibited:
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • using the Services for, or to promote or facilitate, illegal
          activity (including, without limitation, money laundering, financing
          terrorism, tax evasion, buying or selling illegal drugs, contraband,
          counterfeit goods, or illegal weapons);
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • exploiting the Services for any unauthorized commercial purpose;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • uploading or transmitting viruses, worms, Trojan horses, time bombs,
          cancel bots, spiders, malware or any other type of malicious code that
          will or may be used in any way that will affect the functionality or
          operation of the Services;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • attempting to or actually copying or making unauthorized use of all
          or any portion of the Services, including by attempting to reverse
          compile, reformatting or framing, disassemble, reverse engineer any
          part of the Services;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • harvesting or otherwise collecting information from the Services for
          any unauthorized purpose;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • using the Services under false or fraudulent pretenses or otherwise
          being deceitful;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • interfering with other users’ access to or use of the Services;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • interfering with or circumventing of the security features of the
          Services or any third party’s systems, networks or resources used in
          the provision of Services;
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • engaging in any attack, hack, denial-of-service attack,
          interference, or exploit of any smart contract in connection with use
          of the Service (and operations performed by a user that are
          technically permitted by a smart contract may nevertheless be a
          violation of our Agreement, including these Terms, and the law); or
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • engaging in any anticompetitive behavior or other misconduct.
        </Text>

        <Text fontSize="18px" fontWeight="600" color="text-primary" mt="32px">
          Violating our rules may result in our intervention.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          You agree and acknowledge that if you use the Services to engage in
          conduct prohibited by applicable law, permanently reserve the right to
          completely or partially restrict or revoke your access to the
          Services, either completely or for a period of time, at our sole
          discretion. We reserve the right to amend, rectify, edit, or otherwise
          alter transaction data to remediate or mitigate any damage caused
          either to us or to any other person as a result of a user’s violation
          of this Agreement or applicable law
        </Text>

        <Text fontSize="18px" fontWeight="600" color="text-primary" mt="32px">
          We reserve the right to investigate violations.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          We reserve the right to investigate and prosecute any suspected
          breaches of this Agreement, including the Terms. We may disclose any
          information as necessary to satisfy any law, regulation, legal
          process, or governmental request.
        </Text>

        {/* *** Disclaimers and Limitations of Liability *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          6. Disclaimers and Limitations of Liability
        </Text>
        <Text fontSize="18px" fontWeight="600" color="text-primary" mt="16px">
          We make no representations or warranties.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE
          AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, RELATED COMPANIES,
          OFFICERS, DIRECTORS, CONTRACTORS, EMPLOYEES, AGENTS, REPRESENTATIVES,
          PARTNERS, AND LICENSORS (COLLECTIVELY, THE “SAPIENS RESEARCH
          INDEMNIFIED PARTIES”) MAKE NO GUARANTEES OF ANY KIND IN CONNECTION
          WITH THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
          LAW, THE SAPIENS RESEARCH INDEMNIFIED PARTIES DISCLAIM ALL WARRANTIES
          AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT AND DISCLAIM ALL
          RESPONSIBILITY AND LIABILITY FOR:
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • THE SERVICES BEING ACCURATE, COMPLETE, CURRENT, RELIABLE,
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. INFORMATION (INCLUDING,
          WITHOUT LIMITATION, THE VALUE OR OUTCOME OF ANY TRANSACTION) AVAILABLE
          THROUGH THE SERVICE IS PROVIDED FOR GENERAL INFORMATION ONLY AND
          SHOULD NOT BE RELIED UPON OR USED AS THE SOLE BASIS FOR MAKING
          DECISIONS. ANY RELIANCE ON THE SERVICES IS AT YOUR OWN RISK.
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • INJURY OR DAMAGE RESULTING FROM THE SERVICES. FOR EXAMPLE, YOU
          EXPRESSLY ACKNOWLEDGE, UNDERSTAND, AND AGREE there is a real risk that
          assets deposited into the protocol and Protocol related tokens: YUSD,
          YETI, and vault tokens may suffer complete and permanent economic loss
          should the Protocol’s technical or economic mechanisms suffer
          catastrophic failure. YOU EXPRESSLY ACKNOWLEDGE THAT THE SAPIENS
          RESEARCH INDEMNIFIED PARTIES ARE NOT RESPONSIBLE FOR LOSS OR DAMAGE
          CAUSED BY ANOTHER USER’S CONDUCT, UNAUTHORIZED ACTORS, OR ANY
          UNAUTHORIZED ACCESS TO OR USE OF THE SERVICES.
        </Text>
        <Text
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="16px"
          pl="24px"
        >
          • VIRUSES, WORMS, TROJAN HORSES, TIME BOMBS, CANCEL BOTS, SPIDERS,
          MALWARE OR OTHER TYPE OF MALICIOUS CODE THAT MAY BE USED IN ANY WAY TO
          AFFECT THE FUNCTIONALITY OR OPERATION OF THE SERVICES.
        </Text>
        <Text fontSize="18px" fontWeight="600" color="text-primary" mt="16px">
          Limitation of Liability.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL ANY SAPIENS
          RESEARCH LABS INDEMNIFIED PARTY BE LIABLE TO YOU FOR ANY LOSS, DAMAGE,
          OR INJURY OF ANY KIND INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE LOSSES OR DAMAGES,
          OR DAMAGES FOR SYSTEM FAILURE OR MALFUNCTION OR LOSS OF PROFITS, DATA,
          USE, BUSINESS OR GOOD-WILL OR OTHER INTANGIBLE LOSSES, ARISING OUT OF
          OR IN CONNECTION WITH: (A) THE SERVICES OR YOUR INABILITY TO USE OR
          ACCESS THE SERVICES; (B) MISUSE OF THE SERVICES (INCLUDING WITHOUT
          LIMITATION, UNAUTHORIZED ACCESS OF THE SERVICES); (C) ANY USER CONDUCT
          ON THE SERVICES; OR (D) TERMINATION, SUSPENSION OR RESTRICTION OF
          ACCESS TO ANY THE SERVICES.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          IN ADDITION TO THE FOREGOING, NO SAPIENS RESEARCH INDEMNIFIED PARTY
          SHALL BE LIABLE FOR ANY DAMAGES CAUSED IN WHOLE OR IN PART BY: (A)
          USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUCTED
          SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA
          LOSS; (C) THE MALFUNCTION, UNEXPECTED FUNCTION OR UNINTENDED FUNCTION
          OF THE BLOCKCHAIN, ANY COMPUTER OR CRYPTOASSET NETWORK (INCLUDING ANY
          WALLET PROVIDER), INCLUDING WITHOUT LIMITATION LOSSES ASSOCIATED WITH
          NETWORK FORKS, REPLAY ATTACKS, DOUBLE-SPEND ATTACKS, SYBIL ATTACKS,
          51% ATTACKS, GOVERNANCE DISPUTES, MINING DIFFICULTY, CHANGES IN
          CRYPTOGRAPHY OR CONSENSUS RULES, HACKING, OR CYBERSECURITY BREACHES;
          (D) ANY CHANGE IN VALUE OF ANY CRYPTOASSET; (E) ANY CHANGE IN LAW,
          REGULATION, OR POLICY; (VI) EVENTS OF FORCE MAJEURE; OR (F) ANY THIRD
          PARTY.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO
          WHETHER OTHER PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR HAVE
          PROVEN INEFFECTIVE. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL
          APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER THE ASSERTED LIABILITY
          OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION, TORT, STRICT
          LIABILITY, STATUTE, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND
          WHETHER OR NOT THE SAPIENS RESEARCH INDEMNIFIED PARTIES HAVE BEEN
          INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          IN NO EVENT WILL THE SAPIENS RESEARCH INDEMNIFIED PARTIES’ CUMULATIVE
          LIABILITY TO YOU OR ANY OTHER USER, FROM ALL CAUSES OF ACTION AND ALL
          THEORIES OF LIABILITY EXCEED ONE THOUSAND U.S. DOLLARS (U.S.
          $1,000.00).
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          UNDER NO CIRCUMSTANCES SHALL ANY SAPIENS RESEARCH INDEMNIFIED PARTY BE
          REQUIRED TO DELIVER TO YOU ANY VIRTUAL CURRENCY AS DAMAGES, MAKE
          SPECIFIC PERFORMANCE, OR ANY OTHER REMEDY. IF YOU WOULD BASE YOUR
          CALCULATIONS OF DAMAGES IN ANY WAY ON THE VALUE OF VIRTUAL CURRENCY,
          YOU AND WE AGREE THAT THE CALCULATION SHALL BE BASED ON THE LOWEST
          VALUE OF THE VIRTUAL CURRENCY DURING THE PERIOD BETWEEN THE ACCRUAL OF
          THE CLAIM AND THE AWARD OF DAMAGES.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          Some jurisdictions do not allow the exclusion or limitation of certain
          warranties and liabilities provided in this section; accordingly, some
          of the above limitations and disclaimers may not apply to you. To the
          extent applicable law does not permit Sapiens Research. Indemnified
          Parties to disclaim certain warranties or limit certain liabilities,
          the extent of Sapiens Research Indemnified Parties’ liability and the
          scope of any such warranties will be as permitted under applicable
          law.
        </Text>

        {/* *** Indemnification *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          7. Indemnification
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          You agree to indemnify, defend, and hold harmless the Sapiens Research
          Indemnified Parties from any claim or demand, including reasonable
          attorneys’ fees, made by any third party due to or arising out of: (a)
          your breach or alleged breach of the Agreement (including, without
          limitation, these Terms); (b) anything you contribute to the Services;
          (c) your misuse of the Services, or any smart contract and/or script
          related thereto; (d) your violation of any laws, rules, regulations,
          codes, statutes, ordinances, or orders of any governmental or
          quasi-governmental authorities; (e) your violation of the rights of
          any third party, including any intellectual property right, publicity,
          confidentiality, property, or privacy right; (f) your use of a
          third-party product, service, and/or website; or (g) any
          misrepresentation made by you. We reserve the right to assume, at your
          expense, the exclusive defense and control of any matter subject to
          indemnification by you. You agree to cooperate with our defense of any
          claim. You will not in any event settle any claim without our prior
          written consent.
        </Text>

        {/* *** Arbitration Agreement and Waiver of Rights, Including Class Actions *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          7. Arbitration Agreement and Waiver of Rights, Including Class Actions
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          PLEASE READ THIS SECTION CAREFULLY: IT MAY SIGNIFICANTLY AFFECT YOUR
          LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO
          HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS PROCEDURES FOR MANDATORY
          BINDING ARBITRATION AND A CLASS ACTION WAIVER.
        </Text>
        <Text
          textDecoration="underline"
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="24px"
        >
          Agreement to Attempt to Resolve Disputes Through Good Faith
          Negotiations
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          Prior to commencing any legal proceeding against us of any kind,
          including an arbitration as set forth below, you and we agree that we
          will attempt to resolve any dispute, claim, or controversy between us
          arising out of or relating to the agreement or the Services (each, a
          “Dispute” and, collectively, “Disputes”) by engaging in good faith
          negotiations. Such good faith negotiations require, at a minimum, that
          the aggrieved party provide a written notice to the other party
          specifying the nature and details of the Dispute. The party receiving
          such notice shall have thirty (30) days to respond to the notice.
          Within sixty (60) days after the aggrieved party sent the initial
          notice, the parties shall meet and confer in good faith by
          videoconference, or by telephone, to try to resolve the Dispute. If
          the parties are unable to resolve the Dispute within ninety (90) days
          after the aggrieved party sent the initial notice, the parties may
          agree to mediate their Dispute, or either party may submit the Dispute
          to arbitration as set forth below.
        </Text>
        <Text
          textDecoration="underline"
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="24px"
        >
          Agreement to Arbitrate
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          You and we agree that any Dispute that cannot be resolved through the
          procedures set forth above will be resolved through binding
          arbitration in accordance with the International Arbitration Rules of
          the International Centre for Dispute Resolution. The place of
          arbitration shall be the British Virgin Islands. The language of the
          arbitration shall be English. The arbitrator(s) shall have experience
          adjudicating matters involving Internet technology, software
          applications, financial transactions and, ideally, blockchain
          technology. The arbitrator’s award of damages must be consistent with
          the terms of the “Limitation of Liability” subsection of these Terms
          as to the types and amounts of damages for which a party may be held
          liable. The prevailing party will be entitled to an award of their
          reasonable attorney’s fees and costs. Except as may be required by
          law, neither a party nor its representatives may disclose the
          existence, content, or results of any arbitration hereunder without
          the prior written consent of (all/both) parties.
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          UNLESS YOU TIMELY PROVIDE US WITH AN ARBITRATION OPT-OUT NOTICE (AS
          DEFINED BELOW IN THE SUBSECTION TITLED “YOUR CHOICES”), YOU
          ACKNOWLEDGE AND AGREE THAT YOU AND WE ARE EACH WAIVING THE RIGHT TO A
          TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS
          BOTH YOU AND WE OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT
          CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS AND MAY NOT OTHERWISE
          PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING.
        </Text>
        <Text
          textDecoration="underline"
          fontSize="14px"
          fontWeight="500"
          color="text-primary"
          mt="24px"
        >
          Agreement to Attempt to Resolve Disputes Through Good Faith
          Negotiations
        </Text>
        <Text fontSize="14px" fontWeight="500" color="text-primary" mt="16px">
          By rejecting any changes to these Terms, you agree that you will
          arbitrate any Dispute between you and us in accordance with the
          provisions of this section as of the date you first accepted these
          Terms (or accepted any subsequent changes to these Terms).
        </Text>

        {/* *** Waiver of Injunctive or Other Equitable Relief *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          9. Waiver of Injunctive or Other Equitable Relief.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE THAT YOU WILL NOT BE
          PERMITTED TO OBTAIN AN INJUNCTION OR OTHER EQUITABLE RELIEF OF ANY
          KIND, SUCH AS ANY COURT OR OTHER ACTION THAT MAY INTERFERE WITH OR
          PREVENT THE DEVELOPMENT OR EXPLOITATION OF THE SERVICES, OR ANY OTHER
          WEBSITE, APPLICATION, CONTENT, SUBMISSION, PRODUCT, SERVICE, OR
          INTELLECTUAL PROPERTY OWNED, LICENSED, USED OR CONTROLLED BY ANY
          SAPIENS RESEARCH INDEMNIFIED PARTY.
        </Text>

        {/* *** Termination; Cancellation *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          10. Termination; Cancellation
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          This Agreement is effective unless and until terminated by either you
          or us. You may terminate your Agreement with us at any time by ceasing
          all access to the Site, Protocol and/or the Services. If, in our sole
          judgment, you fail, or we suspect that you have failed, to comply with
          any term or provision of the Agreement (including without limitation
          any provision of these Terms), we reserve the right to terminate our
          Agreement with you and deny you access to the Services. We further
          reserve the right to restrict your access to the Site or to stop
          providing you with all or a part of the Services at any time and for
          no reason, including, without limitation, if we reasonably believe:
          (a) your use of the Services exposes us to risk or liability; (b) you
          are using the Services for unlawful purposes; or (c) it is not
          commercially viable to continue providing you with our Services. All
          of these are in addition to any other rights and remedies that may be
          available to us, whether in equity or at law, all of which we
          expressly reserve.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          WE RESERVE THE RIGHT TO MODIFY THE SERVICES AT ANY TIME, BUT WE HAVE
          NO OBLIGATION TO UPDATE THE SERVICES. YOU AGREE THAT IT IS YOUR
          RESPONSIBILITY TO MONITOR CHANGES TO THE SERVICES THAT MAY AFFECT YOU.
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          YOU AGREE THAT WE MAY REMOVE THE SERVICES AND/OR ANY CONTENT THEREON
          FOR INDEFINITE PERIODS OF TIME OR CANCEL THE SERVICES AT ANY TIME,
          WITHOUT NOTICE TO YOU.
        </Text>

        {/* *** Termination; Cancellation *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          11. Severability
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          If any provision of the Agreement (including, without limitation,
          these Terms) is determined to be unlawful, void, or unenforceable,
          such provision shall nonetheless be enforceable to the fullest extent
          permitted by applicable law, and the unenforceable portion shall be
          deemed to be severed from the Agreement. Such determination shall not
          affect the validity and enforceability of any other remaining
          provisions.
        </Text>

        {/* *** Assignment *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          12. Assignment
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          The Agreement (including, without limitation, these Terms) may be
          assigned without your prior consent to any Sapiens Research
          Indemnified Party, or to its successors in the interest of any
          business associated with the Services provided by us. You may not
          assign or transfer any rights or obligations under the Agreement
          without our prior written consent.
        </Text>

        {/* *** Entire Agreement *** */}

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          13. Entire Agreement
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          The Agreement (including, without limitation, these Terms, and the
          Sapiens Research Privacy Policy) and any policies or operating rules
          posted by us on the Services constitute the entire agreement and
          understanding between you and us and govern your use of the Services,
          superseding any prior or contemporaneous agreements, communications,
          and proposals, whether oral or written, between you and us (including,
          but not limited to, any prior versions of these Terms). Any failure by
          us to exercise or enforce any right or provision of the Agreement
          (including, without limitation, these Terms) shall not constitute a
          waiver of such right or provision.
        </Text>

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          14. Governing Law
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          These Terms and any separate agreements whereby we provide you
          Services shall be governed by and construed in accordance with the
          laws of the British Virgin Islands.
        </Text>

        <Text fontSize="20px" fontWeight="700" color="text-primary" mt="32px">
          15. No Fiduciary Duties
        </Text>
        <Text fontSize="14px" fontWeight="600" color="text-primary" mt="16px">
          This Agreement is not intended to, and does not, create or impose any
          fiduciary duties on us. To the fullest extent permitted by law, you
          acknowledge and agree that we owe no fiduciary duties or liabilities
          to you or any other party, and that to the extent any such duties or
          liabilities may exist at law or in equity, those duties and
          liabilities are hereby irrevocably disclaimed, waived, and eliminated.
          You further agree that the only duties and obligations that we owe you
          are those set out expressly in this Agreement.
        </Text>
      </Box>
    </>
  );
};

export default Terms;
